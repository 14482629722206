import { ajax } from "../utils/ajax";
import { AD_CLIENT_ENDPOINT } from "../Common Components/service.urls";

export const getAdClients = ({clientName, os,enabled}) => {
  return ajax({
    method:"get",
    url:`${AD_CLIENT_ENDPOINT}/list?${os}&${enabled}`
  })
}

export const getAdClientById = (id) => {
  return ajax({
    method:"get",
    params:{
      id
    },
    url:`${AD_CLIENT_ENDPOINT}`
  })
}

export const deleteAdClientById = (id) => {
  return ajax({
    method:"delete",
    url:`${AD_CLIENT_ENDPOINT}/${id}`
  })
}

export const saveAdClient = (adClient ,method) => {
  return ajax({
    method : method,
    data : adClient ,
    url : `${AD_CLIENT_ENDPOINT}`
  })
}

export const getAdClientsAuditsById = (id) => {
  return ajax({
    method : "get",
    params :{
      adClientId: id
    },
    url : `${AD_CLIENT_ENDPOINT}/audits`

  })
}