export const SidebarData = [
  {
    title : "Ad Servers",
    icon : "",
    path : "/ad-server",
    cName : "nav-text",
    subNav: [
      {
        title: 'Add New Server',
        path: '/editserver',
        icon: "",
        key: "createServer"
      },
      {
        title: 'View/Edit Server Configs',
        path: '/ad-server',
        icon: "",
        key: "viewServer"
      }
    ]
  },
  {
    title : "Ad Client",
    icon : "",
    path : "/ad-client",
    cName : "nav-text",
    subNav: [
      {
        title: 'Add New',
        path: '/edit-adclient',
        icon: "",
        key: "createAdClient"
      },
      {
        title: 'View/Edit',
        path: '/ad-client',
        icon: "",
        key: "viewAdClient"
      } 
    ]
  },
  {
    title : "Airtel Publisher",
    icon : "",
    path : "/newAccount",
    cName : "nav-text",
    subNav: [
      {
        title: 'Onboard Publisher',
        path: '/createAccount',
        icon: "",
        key:"createAccount"
      },
      {
        title: 'View/Edit Publisher',
        path: '/newAccount',
        icon: "",
        key:"viewAccount"
      }
    ]
  },
  {
    title : "Airtel Impression Config",
    icon : "",
    path : "/newImp",
    cName : "nav-text",
    subNav: [
      {
        title: 'Create Impression Config',
        path: '/createImp',
        icon: "",
        key:"createImp"
      },
      {
        title: 'View/Edit Configs',
        path: '/newImp',
        icon: "",
        key:"viewImp"
      }
    ]
  },
  {
    title : "Airtel AdUnit Config",
    icon : "",
    path : "/newAdUnit",
    cName : "nav-text",
    subNav: [
      {
        title: 'Create AdUnit Config',
        path: '/createAdUnit',
        icon: "",
        key:"createAdUnit"
      },
      {
        title: 'View/Edit AdUnit',
        path: '/newAdUnit',
        icon: "",
        key:"viewAdUnit"
      }
    ]
  },
  {
    title : "New Slot Config",
    icon : "",
    path : "/newAdconfig",
    cName : "nav-text",
    subNav: [
      {
        title: 'Create New Config',
        path: '/createNewAdconfig',
        icon: "",
        key:"newcreateAdConfig"
      },
      {
        title: 'View/Edit Configs',
        path: '/newAdconfig',
        icon: "",
        key:"newviewAdConfig"
      }
    ]
  },
  {
    title : "AdConfig",
    icon : "",
    path : "/adconfig",
    cName : "nav-text",
    subNav: [
      {
        title: 'Create New Config',
        path: '/createConfig',
        icon: "",
        key:"createAdConfig"
      },
      {
        title: 'View/Edit Configs',
        path: '/adconfig',
        icon: "",
        key:"viewAdConfig"
      }
    ]
  },
  {
    title : "Recommendations",
    icon : "",
    path : "/ad-recommendation",
    cName : "nav-text",
    subNav: [
      {
        title: 'Add New Recomm.',
        path: '/editrecommendation',
        icon: "",
        key: "createrecommendation"
      },
      {
        title: 'View/Edit Recomm.',
        path: '/ad-recommendation',
        icon: "",
        key:"viewAdRecommendation"
      }
    ]
  },
  {
    title : "Slot Priorities",
    icon : "",
    path : "/slot-priority",
    cName : "nav-text",
    subNav: [
      {
        title: 'Add New Priority',
        path: '/editpriority',
        icon: "",
        key: "createPriority"
      },
      {
        title: 'View/Edit Priorities',
        path: '/slot-priority',
        icon: "",
        key: "viewPriority"
      }
    ]
  },
  {
    title : "Ad Properties",
    icon : "",
    path : "/ad-properties",
    cName : "nav-text",
    subNav: [
      {
        title: 'Add New Properties',
        path: '/editproperties',
        icon: "",
        key: "createAdProperty"
      },
      {
        title: 'View/Edit Properties',
        path: '/ad-properties',
        icon: "",
        key: "viewAdProperty"
      } 
    ]
  },
  {
    title : "Ad Offers BlackListed",
    icon : "",
    path : "/blacklistedOfferIds",
    cName : "nav-text",
    subNav: [
      {
        title: 'Blacklist New Offer Id',
        path: '/editBlacklistedOfferIds',
        icon: "",
        key: "addOfferId"
      },
      {
        title: 'View/Edit Offer Ids',
        path: '/blacklistedOfferIds',
        icon: "",
        key: "viewOfferIds"
      }
    ]
  },
 
 
  {
    title : "Feature Controls",
    icon : "",
    path : "/featurecontrol",
    cName : "nav-text",
    subNav: [
      {
        title: 'Add New Feature Control',
        path: '/editfeaturecontrol',
        icon: "",
        key: "editFeatureControl"
      },
      {
        title: 'View/Edit Feature Contorls',
        path: '/featurecontrol',
        icon: "",
        key: "viewFeatureControl"
      }
    ]
  },
  {
    title : "Feature Control Rule",
    icon : "",
    path : "/featurecontrol-rule",
    cName : "nav-text",
    subNav: [
      {
        title: 'Add New Rule',
        path: '/editfeaturecontrol-rule',
        icon: "",
        key: "editFeatureControlRule"
      },
      {
        title: 'View/Edit Rules',
        path: '/featurecontrol-rule',
        icon: "",
        key: "viewFeatureControlRule"
      }
    ]
  },
  {
    title : "Authorization",
    icon : "",
    path : "/access-request",
    cName : "nav-text",
    subNav: [
      {
        title: 'View/Edit User Acess',
        path: '/view-auth-access',
        icon: "",
        key: "editFeatureControlRule"
      },
      {
        title: 'Create Access Request',
        path: '/access-request',
        icon: "",
        key: "viewFeatureControlRule"
      }
    ]
  },
  {
    title : "User Management",
    icon : "",
    path : "/user-register",
    cName : "nav-text",
    subNav: [
      {
        title: 'Update User',
        path: '/updateUser',
        icon: "",
        key: "updateUser"
      },
      {
        title: 'Update Credentials',
        path: '/updateCredential',
        icon: "",
        key: "updateCredentials"
      },
      {
        title: 'Logout',
        path: '/logout',
        icon: "",
        key: "logout"
      }
    ]
  },{
    title : "File Processing Status",
    icon : "",
    path : "/view-file-status",
    cName : "nav-text",
    subNav: [
      {
        title: 'View Status',
        path: '/view-file-status',
        icon: "",
        key: "viewFileStatus"
      }
    ]
  }
]