import React from "react";
import CreatableForm from "../Common Components/CreatableForm";

const VideoConfig = (props) => {
    
    const { register, control, errors, errorRequiredField } = props;
     
    return (
        <>

<div className="row m-0 mt-30 mr-20 field-btn">
                        <div className="col-md-6">
                            <fieldset className="pl-20">
                                <label className="fs-14 " htmlFor="videoWidth">
                                    Video Width
                                </label>
                                <input
                                    className="fs-14"
                                    type="text"
                                    autoComplete="off"
                                    {...register('videoWidth', { required: false })}
                                    placeholder="Enter Companion Video Width"
                                    pattern="[0-9]+"
                                    title="Allowed values are numbers"

                                />
                                {errors.videoWidth && errorRequiredField()}
                            </fieldset>
                        </div>
                        <div className="col-md-6">
                            <fieldset className="pl-20">
                                <label className="fs-14 " htmlFor="videoHeight">
                                    Video Height
                                </label>
                                <input
                                    className="fs-14"
                                    type="text"
                                    autoComplete="off"
                                    {...register('videoHeight', { required: false })}
                                    placeholder="Enter Companion Video Height"
                                    pattern="[0-9]+"
                                    title="Allowed values are numbers"

                                />
                                {!!errors.videoHeight && errorRequiredField()}
                            </fieldset>
                        </div>
                    </div>
                    <div className="row m-0 mt-30 mr-20">
                    <div className="col-md-6">
                    <fieldset className="pl-20">
                        <label className="fs-14 required-field" htmlFor="videoMimes">
                           Mimes
                        </label>
                        <CreatableForm
                            control={control}
                            register={register}
                            name="videoMimes"
                            placeholder="Mimes"
                        />
                        {errors.videoMimes}
                    </fieldset>
                </div>
                        <div className="col-md-6">
                        <fieldset className="pl-20">
                        <label className="fs-14" htmlFor="videoApi">
                           Api
                        </label>
                        <CreatableForm
                            control={control}
                            register={register}
                            pattern="^\d*$"
                            name="videoApi"
                            placeholder="Api"
                          
                        />
                        {errors.videoApi }
                    </fieldset>
                    </div>
            </div>
        </>
    )
}

export default VideoConfig;