import React from 'react';
import ReactDOM from 'react-dom';
import {withRouter} from 'react-router-dom';
import App from './App';
import {  BrowserRouter as Router,  Route, Switch } from 'react-router-dom';
import Login from './modules/User/Login/Login';
import RegisterUser from './modules/User/RegisterUser/RegisterUser';
import UpdateCredential from './modules/User/User Credentials/UpdateCredential';

ReactDOM.render(<Router>
    <Switch>
        <Route path='/login'        component={Login} />
        <Route path="/register" component={RegisterUser} />
        <Route path="/updateCredential" component={UpdateCredential} />
        <Route path='/'             component={withRouter(props => <App {...props} />)} />
    </Switch>
</Router>, document.getElementById('root'));

