import axios from 'axios';
// import {correlation}  from './Correlation.key';
import { ACCESS_TOKEN, CLIENT_NAME } from '../constants/AdtechConstants';

// Use 'ajax' instead of axios for light and non-blocking API calls
// Works well when response is in JSON. 
// Not tested for non-JSON response.

let ajax = axios.create({
    headers : {
        // 'correlationId': correlation(),
        'Content-Type': 'application/json'
    }
});

function addLoader() { 
    const loader = document.getElementById('js-loader');
    const loaderCount = parseInt(loader.getAttribute('data-loader'));
    loader.setAttribute('data-loader', isNaN(loaderCount) ? 1 : loaderCount + 1);
}

function removeLoader() {
    const loader = document.getElementById('js-loader');
    const loaderCount = parseInt(loader.getAttribute('data-loader'));
    if(loaderCount > 1)
        loader.setAttribute('data-loader', loaderCount - 1);
    else
        loader.removeAttribute('data-loader');

}

ajax.interceptors.request.use(function (config) {
    //check for Bearer part in token
    config.headers = {...config.headers, 'auth-token':  localStorage.getItem(ACCESS_TOKEN)}
    let clientName = localStorage.getItem(CLIENT_NAME);
    config.params = {...config.params}
    config.params.clientName=clientName
    addLoader();
    return config;
}, function (error) {
    removeLoader();
    return Promise.reject(error);
});

// Add a response interceptor
ajax.interceptors.response.use(function (response) {
    removeLoader();
    return response;
}, function (error) {
    removeLoader();
    return Promise.reject(error);
});

export {ajax};
