import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const modalRoot = document.getElementById('modal-root');

class Modal extends Component {
    constructor(props){
        super(props);
        this.state={
            content : props.content
        };

        this.el = document.createElement('div');

        this.btnAction = this.btnAction.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.generateList = this.generateList.bind(this);
        this.flattenObj = this.flattenObj.bind(this);
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);

        window.addEventListener('keyup', this.closeModal);

        if(this.props.didMount){
            this.props.didMount()
            .then(response => {
                this.setState({content: response.data.body})
            }).catch(error => {
                this.setState({content: null})
                console.log(error.response.data.message)
            });
        }
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);

        window.removeEventListener('keyup', this.closeModal)
    }

    btnAction(){
        this.props.btnHandler();
    }

    closeModal(ev){
        if(ev.key === "Escape"){
            this.props.closeTrigger();
        }
    }

    generateList(obj){
        let flatObj = this.flattenObj(obj, {});
        return (
            <ul style={{overflowX : 'auto'}}>
                {Object.keys(flatObj).map((label) => {
                    return (typeof flatObj[label] !== 'undefined' ?
                        <li key={label}>
                            {`${label} : ${flatObj[label]}`}
                        </li> : null)
                        }
                )}
            </ul>
        )
    }

    flattenObj(nestedObj, targetObj){
        let flatObj = {...targetObj};
        // eslint-disable-next-line no-unused-vars
        for(let key in nestedObj){
            if(typeof nestedObj[key] === 'object'){
                if(Array.isArray(nestedObj[key]))
                    flatObj[key] = JSON.stringify(nestedObj[key]).slice(1, -1);     //Parse array as string, clip the square brackets
                else
                    flatObj = {...flatObj, ...this.flattenObj(nestedObj[key], {})}
            }
            else {
                flatObj[key] = nestedObj[key];
            }
        }
        return flatObj;
    }

    render() {
        return ReactDOM.createPortal(
            <div className="modal-open">
                <div className="modal show" onClick={this.props.closeTrigger}>
                    <div className="modal-content modal-lg" onClick={e => e.stopPropagation()}>
                        <div className="modal-header">
                            <div className="modal-title">
                                <h4 className="h4">{this.props.title}</h4>
                            </div>
                        </div>
                        <div className="modal-body">
                            {this.props.flatten ? this.generateList(this.state.content) : this.state.content}
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary btn-sm" onClick={this.btnAction}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>,
            this.el
        );
    }
}


Modal.propTypes = {
    title : PropTypes.string,
    body : PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
      ]),
};


export default Modal;
