import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import AuditGroup from '../Audits/AuditGroup';
import Modal from '../Common Components/Modal';
import { PlatformList } from '../constants/AdConstants';
import { TENANTS } from '../constants/AdtechConstants';
import { storeClientNameInMemory } from '../utils/adtech.utils';
import { getAdClients, getAdClientsAuditsById } from './ad.client.service';

function ViewAdClients() {
  const [adClientsList, setAdClientsList] = useState([]);
  const [clientName,setClientName] = useState("")
  const [os, setOs] = useState("");
  const [enabled, setEnabled] = useState("");
  const [clientNameList, setClientNameList] = useState([]);
  const [response, setResponse] = useState({ msg: "", isError: false });


  const [modal, setModal] = useState({
    isOpen : false,
    content : null,
    title : '',
    didMount : null,
    flatten : false
  })


  const cellStyle = {
    maxWidth: "280px",
    lineBreak: "anywhere",
  };

  const nameStyle = {
    margin: "0",
    fontFamily: "Tondo, Helvetica Neue, Helvetica, Arial, sans-serif",
  };

  function handleChange(func, value){
    func(value);
  }

  useEffect(() => {
    let tenants = localStorage.getItem(TENANTS)
    
    if(typeof tenants === "undefined" || tenants.length === 0){
      setResponse({msg:"Seems Like you don't have sufficient access", isError:true})
      return;
    }

    if(typeof tenants != String)
      tenants = tenants.split(',')

    if(clientNameList.length === 0 && tenants.length !==0){
      setClientNameList(tenants)
      setClientName(`clientName=${tenants[0]}`)
      // setOs(`os=${PlatformList[0]}`)
    }

    if(typeof clientName === "undefined" || clientName === ""){ 
      return;
    }

    storeClientNameInMemory(clientName.split('=')[1]);
    getAdClients({clientName,enabled,os})
    .then(res=>{
      setAdClientsList(res.data);
      setResponse({isError:false, msg:""})
    })
    .catch(err=>{
      setAdClientsList([])
      let msg = err && err.response && err.response.data ? err.response.data.error : "Error Fetching Ad Clients";
      setResponse({msg:msg, isError:true})
    })
    return () => {
      
    }
  }, [clientName,enabled,os]);

  function toggleModal(title = '', content = '', callback, flatten = false){
    setModal({
        isOpen : !modal.isOpen,
        title : title,
        content : content,
        flatten,
        didMount : callback
    })
  }


  // function initDeleteAdClient(id) {
  //   const confirmation = window.confirm('Are you sure you want to delete?');

  //   if(confirmation){
  //       deleteAdClientById(id)
  //       .then( response => {
  //         let existingAdClients = adClientsList.filter(client => (client.id !== id));
  //           setAdClientsList(existingAdClients)
  //       })
  //       .catch( err => {
  //           if(!err.response.data.success){
  //               const message = err.response.data.error;
  //               setResponse({msg:message, isError:true})
  //           }
  //       })
  //   }
  // }


  function renderAdClientRow(content,index ){
    return (
      <div className={`divTableRow`} key={content.clientId+index}>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{content.clientName}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{content.os}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{String(content.enabled)}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <span 
          className="text-info underline cursor-pointer"
          onClick={() => toggleModal("Ad Client Details", content, null, true)}>View</span> 
          &emsp;
          <Link to={'/edit-adclient/' + content.id} className="text-warning underline">Edit</Link>
          {/* &emsp; */}
          <span
            className="text-info underline cursor-pointer"
            onClick={() => toggleModal("Ad Clients Audits",
              <AuditGroup id={content.id} getAudits={getAdClientsAuditsById} />
            )}
          >Audits</span>
          {/* <span 
          className="text-danger underline cursor-pointer"
          isDisable
          // onClick={()=>initDeleteAdClient(content.id)}
          >Delete</span>  */}
          &emsp;
        </div>
      </div>
    );
  }
 
  return (
    <>
      {modal.isOpen && 
      <Modal 
          flatten={modal.flatten}
          title={modal.title} 
          btnHandler={toggleModal}
          closeTrigger={toggleModal}
          content={modal.content}
          didMount={modal.didMount}
      >
      </Modal>}
     
      <div className="row m-0 mt-0 mr-20">
          <div className="row">
            <div className="col-sm-4">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray">Client Name</label>
                <form>
                  <select 
                    name = 'clientName' 
                    id='clientName' 
                    value={clientName} 
                    onChange={e=> handleChange(setClientName, e.target.value)} 
                    className="fs-16" >
                    {clientNameList.map(clientName => 
                    <option 
                    key={clientName} 
                    value={`clientName=${clientName}`}>
                        {clientName}
                    </option>)}
                  </select>  
                </form>
              </fieldset>
            </div>
            <div className="col-sm-4">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray"> OS</label>
                <form>
                  <select 
                  name = 'os' 
                  id='os' 
                  value={os}
                  onChange={e=> handleChange(setOs, e.target.value)} 
                  className="fs-16" >
                     <option key="all" value=''>All</option>
                      {PlatformList.map(os => 
                      <option key={os} value={`os=${os}`}>
                          {os}
                      </option>)}
                  </select>  
                </form>
              </fieldset>
            </div>
            <div className="col-sm-4">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray">Ads Enabled</label>
                <form>
                  <select 
                    name = 'enabled' 
                    id='enabled' 
                    value={enabled} 
                    onChange={e=> handleChange(setEnabled, e.target.value)} 
                    className="fs-16" >
                    <option key="all" value=''>All</option>
                    <option key="true" value='enabled=true'>True</option>
                    <option key="false" value='enabled=false'>False</option>
                  </select>  
                </form>
              </fieldset>
            </div>
          </div>
        <div className="row m-0 mt-0 mb-20">
          <div className="col-md-12">
          <h5 className="h5 text-center" style={{"textAlign":"center"}}>Ad Clients</h5>
            <div className="divTable divTableHover mt-20">
              <div className="divTableHeading">
                <div className="divTableRow">
                  <div className="divTableHead">Client Id</div>
                  <div className="divTableHead">Platform</div>
                  <div className="divTableHead">Ads Enabled</div>
                  <div className="divTableHead">Actions</div>
                </div>
              </div>
              <div className="divTableBody">
                {adClientsList && 
                adClientsList.map((client, index)=>renderAdClientRow(client, index))}
              </div>
            </div>
          </div>
        </div>  
      </div>
      <div className="row m-0 mt-30 mr-20 vertical-text-center">
        <div className="col-md-9 text-center">
          <div className={response.isError ? "text-danger" : "text-success"}>
            <b>
              <h4>{response.msg}</h4>
            </b>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewAdClients
