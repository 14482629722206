import React from 'react'
import {SidebarData} from './SidebarData';
import './Navbar.css'
import SubMenu from './SubMenu';

function Navbar() {
  // const[sidebar, setSidebar] = useState(false) ;
  // const showSideBar = () => setSidebar(!sidebar);
  return (
    <>
      <div className="Navbar">
       
        <nav className='Nav-Menu'>
          <ul className="Nav-Menu-Items">
            <div>
              <span className="Menu-Options">AdTech Targeting<br/>Portal</span>
            </div>
            <div style={{"width":"100%"}} className="m-0 mt-20"> 
              {SidebarData.map((item, index) =>{
                return (
                  <SubMenu item={item} index={index} key={index}/>   
                )
              })}
            </div>
           </ul>
        </nav>
      </div>
    </ >
  )
}

export default Navbar
