import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { getFormattedDateFromEpoch } from "../utils/adtech.utils";
import AuditPreview from "./AuditPreview";

function AuditGroup(props) {
  const id = typeof props.id === "undefined" ? null : props.id;
  const {getAudits, tenantId} = props;
  const [auditData, setAuditData] = useState([]);
  const status = useRef("");

  useEffect(() => {
    getAudits(id, tenantId)
      .then((res) => {
        const result = res.data;
        let Data = [];
        status.current = res.status;
        for (let i = 0; i < result.length; i++) {
          let tempData = {};

          tempData["document"] = result[i].auditDocument;
          tempData["updateTime"] = getFormattedDateFromEpoch(
            result[i].updateTime
          );
          tempData["updatedBy"] = result[i].updatedBy;
          Data.push(tempData);
        }

        setAuditData(Data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, id);

  return (
    <div>
      {auditData.length ? (
        auditData.map((data, i) => (
          <>
            <p>Log : {i + 1}</p>

            <AuditPreview
              document={data["document"]}
              updateTime={data["updateTime"]}
              updatedBy={data["updatedBy"]}
            />
          </>
        ))
      ) : status.current !== "" ? (
        <>No Logs Available</>
      ) : (
        ""
      )}
    </div>
  );
}

AuditGroup.propTypes = {
  id: PropTypes.string.isRequired,
};

export default AuditGroup;
