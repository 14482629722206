
import { ajax } from "../utils/ajax";
import { USER_ACCESS_ROLE_ENDPOINT, TENANT_ROLE_ENDPOINT } from "../Common Components/service.urls";

export const getTenantRolesList =() => {

    return ajax({
      method:"get",
      url:`${TENANT_ROLE_ENDPOINT}/allTenants`
    })
}

export const getApprovedUserAccessList = (tenantId) => {

  return ajax({
    method:"get",
    params:{
      tenantId
    },
    url:`${USER_ACCESS_ROLE_ENDPOINT}/approved`
  })
}


export const getPendingUserAccessRequestList = (tenantId) => {

  return ajax({
    method:"get",
    params:{
      tenantId
    },
    url:`${USER_ACCESS_ROLE_ENDPOINT}/pendingRequests`
  })
}

export const deleteApprovedTenantAccessForUserId = (userId, tenantId) => {
  return ajax({
    method:"delete",
    params:{
      userId,
      tenantId
    },
    url:`${USER_ACCESS_ROLE_ENDPOINT}/delete`
  })
}

export const rejectRequestedTenantAccessForUserId = (userId, tenantId) => {
  return ajax({
    method:"delete",
    params:{
      userId,
      tenantId
    },
    url:`${USER_ACCESS_ROLE_ENDPOINT}/rejectRequest`
  })
}

export const approveTenantAccessRequestForUserId = (userId, tenantId) => {
  return ajax({
    method:"post",
    params:{
      userId,
      tenantId
    },
    url:`${USER_ACCESS_ROLE_ENDPOINT}/approveRequest`
  })
}


export const createTenanatRoleAccessRequest = (data) => {
  return ajax({
    method : 'post',
    data : data ,
    url : `${USER_ACCESS_ROLE_ENDPOINT}/raiseRequest`
  })
}