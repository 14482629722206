import {useForm} from 'react-hook-form';
import React , {useRef, useEffect, useState} from 'react';
import { getFeatureControlRuleById, saveFeatureControlRule } from './feature.control.rule.service';
import { NON_EMPTY_STRING_PATTERN, SEMANTIC_VERSION_PATTERN } from '../constants/RegularExpressions';
import {useLocation} from 'react-router-dom';

import {PlatformList} from '../constants/AdConstants'
import { CLIENT_NAME, TENANTS } from '../constants/AdtechConstants';

function CreateFeatureControlRule(props){
  const { register, handleSubmit, formState:{errors}, reset, control, watch , setValue} = useForm({mode:"onChange"
   
  });
  const location = useLocation()
  const id = useRef(props.match.params.id);
  const [response, setResponse] = useState({ msg: "", isError: false });
  const [clientIdList, setClientIdList] = useState([])
  const [tenantId, setTenantId] = useState("")
  const resetObj = {
    ruleId: "",
    description:"",
    clientId:[],
    maxSdkBuildNo:"",
    minSdkBuildNo:"",
    maxClientBuildNo:"",
    minClientBuildNo:"",
    osPlatform:"",
    minAppVersion:"",
    maxAppVersion:"",
    minSdkVersion:"",
    maxSdkVersion:""
  };
  // const prepareOptionsListFromValues = (values) => {
  //   if(typeof values === "undefined")
  //     return []

  //   return values.reduce((optionsList, value) =>{
  //     optionsList = [...optionsList, {label:value, value:String(value)}]
  //     return optionsList
  //   },[])
  // }

  // const handleClientChange = (value) => {
  //   if(value.length > 1){
  //     setValue('minSdkBuildNo',"");
  //     setValue('maxSdkBuildNo' ,"")
  //   }

  //   setValue('clientId', value)
     
  // }

  const getFirstElementOfList = (values) => {
    if(typeof values === "undefined")
      return "";

    if(values.length < 1)
    return "";

    return values[0]
  }

  const prepareEditForm = async() => {
    let tenantId = localStorage.getItem(CLIENT_NAME);
    if(typeof tenantId ==="undefined" || tenantId === null)
      return;
    if(typeof id.current !=="undefined" && id.current !== null){
      try{
        
        let res = await getFeatureControlRuleById(id.current, tenantId)
        let osPlatform =  getFirstElementOfList(res.data.osPlatform) ;
        let clientId = getFirstElementOfList(res.data.clientId)
     
        res = {...res.data, clientId: clientId, osPlatform: osPlatform }
        
        reset(res)
        setTenantId(clientId)
      }
      catch(err){
        setResponse({
          msg:
            err.response && err.response.data
              ? err.response.data.message
              : "Unknown",
          isError: true,
        });
      }
    }
    
  }

  
  useEffect(()=>{
    let tenants = localStorage.getItem(TENANTS)
    if(typeof tenants === "undefined" || tenants.length === 0){
      setResponse({msg:"Seems Like you don't have sufficient access", isError:true})
      return;
    }

    if(typeof tenants != String)
    tenants = tenants.split(',')

    if(clientIdList.length === 0 && tenants.length !==0){
      setClientIdList(tenants)
    }

    if(typeof tenantId === "undefined" || tenantId === ""){ 
      let client = localStorage.getItem(CLIENT_NAME)
      if(typeof client !=="undefined" && client !== ""){
        setTenantId(client)
      }
      return
    }

    prepareEditForm()

  },[id, tenantId]);

  // function prepareListFromOptions(options){
   
  //   if(typeof options === "undefined" || options === null)
  //     return undefined;

  //   let result = options instanceof Array ?options.map(option=>{
  //       return option.value;
  //   }) : [options.value]

  //   if(result.length > 0)
  //     return result;
  //   else
  //     return ;
  // }

  function prepareSubmitData(data){
    data.id = id.current;
    // let clientId = prepareListFromOptions(data.clientId);
    // let osPlatform = prepareListFromOptions(data.osPlatform)
    Object.keys(data).forEach(key => {
      if (data[key] === '') {
        delete data[key];
      }
    });
    let client  = typeof data.clientId !=="undefined" ? data.clientId : tenantId;
    data.clientId = [client]
    if(typeof data.osPlatform !=="undefined" && data.osPlatform!=null)
      data.osPlatform = [data.osPlatform]
    data = {...data}
    return data
  }

  function onSubmit(data, ev){
    ev.preventDefault()
    let method = typeof id.current !=="undefined" && id.current !== null ? "PUT" : "POST"
    data = prepareSubmitData(data)
    
    saveFeatureControlRule(data, method)
    .then(res=>{
      setResponse({msg:"Submitted Successfully", isError:false})

      reset(resetObj)

      props.history.replace("/editfeaturecontrol-rule");
      id.current = null 
    })
    .catch(err=>{
      setResponse({msg:"Error:" + err && err.response && err.response.data && err.response.data.error, isError:true})
    })
  }

  const errorRequiredField= (message) => {
    if(typeof message === "undefined" || message === "")
    message="This field is required"
    return(<small className="text-danger">{message}</small>)
  } 

  return (
    <>
      <div className="center-block" style={{textAlign:'center'}}>
        <h4>Feature Control Rule Form</h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                Rule Name*
              </label>
              <input
                className="fs-14"
                type="text"
                readOnly={typeof id.current !=="undefined" && id.current !== null}
                autoComplete="off"
                {...register('ruleId',{required:true, pattern :{value: NON_EMPTY_STRING_PATTERN,message:"Please enter a non-blank string. Terminal Whitespaces are not allowed"}})}
                placeholder="Enter Rule Id"
                maxLength="50"
              />
              {errors.ruleId && errorRequiredField(errors.ruleId.message)}
             
            </fieldset>
          </div>
        
        </div>

        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                Description
              </label>
              <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('description',{required:false})}
                placeholder="Enter Feature Description"
                maxLength="100"
              />
             
            </fieldset>
          </div>
        </div>

        <div className="row m-0 mt-30 mr-20">
          
         {/* <div className="col-md-6" style={{zIndex:3}}> 
          <fieldset className="pl-20">
          <label className="fs-14" htmlFor="AdSlotId">
              Client Ids
            </label>
          
            <SelectFormCustomChange
              options= {clientIdList}
              control={control} 
              onChange={handleClientChange}
              name="clientId" 
              placeholder ="Select Clients" 
              required={false} 
              isMulti={true}
              
            />
            </fieldset>
          </div> */}
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                Client Id*
              </label>
              <select
                name="clientId"
                className="fs-14"
                disabled={typeof id.current !=="undefined" && id.current !== null}
                {...register('clientId',{required:true})}
                >
                <option value="">Select a Client</option>
                {clientIdList.map((clientId) => (
                  <option value={clientId} key={clientId}>
                    {clientId}
                  </option>
                ))}
              </select>
              {!!errors.clientId && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
          {/* <div className="col-md-6" style={{zIndex:2}}> 
          <fieldset className="pl-20">
          <label className="fs-14" htmlFor="AdSlotId">
              Platforms
            </label>
          
            <SelectForm 
              options= {PlatformOptions}
              control={control} 
              register={register} 
              name="osPlatform" 
              placeholder ="Select Platforms" 
              required={false} 
              isMulti={false}
              
            />
            </fieldset>
          </div> */}
           <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                Platform
              </label>
              <select
                name="clientId"
                className="fs-14"
                {...register('osPlatform',{required:false})}
                >
                <option value="">Select a Platform</option>
                {PlatformList.map((platform) => (
                  <option value={platform} key={platform}>
                    {platform}
                  </option>
                ))}
              </select>
              {!!errors.client_id && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-3">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
               SDK Build Number
              </label>
            </fieldset>
          </div>
            <div className="col-md-3">
              <input
                className="fs-14"
                type="text"
                autoComplete="off"
                // disabled={typeof watch("clientId") !== "undefined" && watch("clientId").length > 1}
                {...register('minSdkBuildNo',{required:false, pattern:{value:/[0-9]{1,50}/,message:"Only Numbers are allowed"}})}
                placeholder="Enter Min Sdk Build No"
                maxLength="50"
              />
             {errors.minSdkBuildNo && errorRequiredField(errors.minSdkBuildNo.message)}
            </div>
            
          <div className="col-md-3">
              <input
                className="fs-14"
                type="text"
                autoComplete="off"
                // disabled={typeof watch("clientId") !== "undefined" && watch("clientId").length > 1}
                {...register('maxSdkBuildNo',{required:false, pattern:{value:/[0-9]{1,50}/,message:"Only Numbers are allowed"}})}
                placeholder="Enter Max Sdk Build No"
                maxLength="50"
              />
               {errors.maxSdkBuildNo && errorRequiredField(errors.maxSdkBuildNo.message)}
          </div>
        </div>

        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-3">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                Client Build Number
              </label>
            </fieldset>
            </div>
            <div className="col-md-3">
              <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('minClientBuildNo',{required:false, pattern:{value:/[0-9]{1,50}/,message:"Only Numbers are allowed"}})}
                placeholder="Enter Min Client Build No"
                maxLength="50"
              />
              {errors.minClientBuildNo && errorRequiredField(errors.minClientBuildNo.message)}
            {/* </fieldset> */}
          </div>
          <div className="col-md-3">
            <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('maxClientBuildNo',{required:false, pattern:{value:/[0-9]{1,50}/,message:"Only Numbers are allowed"}})}
                placeholder="Enter Max Client Build No"
                maxLength="50"
              />
              {errors.maxClientBuildNo && errorRequiredField(errors.maxClientBuildNo.message)}
          </div>
        </div>

        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-3">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                App Version
              </label>
            </fieldset>
            </div>
            <div className="col-md-3">
              <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('minAppVersion',{required:false, pattern:{value:SEMANTIC_VERSION_PATTERN,message:"Enter proper semantic version like 2.32.1 with max 4 segments"}})}
                placeholder="Enter Min App Version"
                maxLength="50"
              />
              {errors.minAppVersion && errorRequiredField(errors.minAppVersion.message)}
            {/* </fieldset> */}
          </div>
          <div className="col-md-3">
            <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('maxAppVersion',{required:false, pattern:{value:SEMANTIC_VERSION_PATTERN,message:"Enter proper semantic version like 2.32.1 with max 4 segments"}})}
                placeholder="Enter Max App Version"
                maxLength="50"
              />
              {errors.maxAppVersion && errorRequiredField(errors.maxAppVersion.message)}
          </div>
        </div>

        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-3">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                SDK Version
              </label>
            </fieldset>
            </div>
            <div className="col-md-3">
              <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('minSdkVersion',{required:false, pattern:{value:SEMANTIC_VERSION_PATTERN,message:"Enter proper semantic version like 2.32.1 with max 4 segments"}})}
                placeholder="Enter Min SDK Version"
                maxLength="50"
              />
              {errors.minSdkVersion && errorRequiredField(errors.minSdkVersion.message)}
            {/* </fieldset> */}
          </div>
          <div className="col-md-3">
            <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('maxSdkVersion',{required:false, pattern:{value:SEMANTIC_VERSION_PATTERN,message:"Enter proper semantic version like 2.32.1 with max 4 segments"}})}
                placeholder="Enter Max SDK Version"
                maxLength="50"
              />
              {errors.maxSdkVersion && errorRequiredField(errors.maxSdkVersion.message)}
          </div>
        </div>
       
        <div className="row m-0 mt-30 mr-20 vertical-text-center">
          <div className="col-md-9 text-center">
            <div className={response.isError ? "text-danger" : "text-success"}>
              <b>
                <h4>{response.msg}</h4>
              </b>
            </div>
          </div>
          <div className="col-md-3">
            <input className="btn btn-success btn-md pull-right" type="submit" />
          </div>
        </div>
      </form>
    </>
  );
}

export default CreateFeatureControlRule;