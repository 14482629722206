import React from "react";
import PropTypes from "prop-types";
import "./tabStyles.css";

export default function TabManager(props){
  const { activeTab, setActiveTab, tabs } = props; 
  
  return (
    <div className="tab-manager">
      {tabs.map(({ label, value }) => (
        <div
          className={`tab ${value === activeTab ? "selected-tab" : ""}`}
          onClick={() => {
            setActiveTab(value);
          }}
          key={label}
        >
          {label}
        </div>
      ))}
    </div>
  );
}

TabManager.propTypes = {
  activeTab: PropTypes.oneOfType([PropTypes.string,PropTypes.number]).isRequired,
  setActiveTab: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(Object).isRequired,
};
