import { ajax } from "../utils/ajax";
import { ADSERVER_CONFIG_ENDPOINT } from "../Common Components/service.urls";

export const getAdServerConfigsList = (name,clientName,os) => {
  name = name !== '' ? `name=${name}` : name;

  return ajax({
    method:"get",
    url:`${ADSERVER_CONFIG_ENDPOINT}/list?${name}&${os}`
  })
}

export const getAdServerConfigById = (id) => {
  return ajax({
    method:"get",
    params:{
      id
    },
    url:`${ADSERVER_CONFIG_ENDPOINT}`
  })
}

export const saveAdServerConfig = (adserverconfig ,method) => {
  return ajax({
    method : method,
    data : adserverconfig ,
    url : `${ADSERVER_CONFIG_ENDPOINT}`
  })
}
export const deleteServerConfigById = (id) => {

  return ajax({
    method:"delete",
    url:`${ADSERVER_CONFIG_ENDPOINT}/${id}`
  })
}

export const getAdServerAuditsById = (id) => {
  return ajax({
    method : "get",
    params :{
      adServerConfigId: id
    },
    url : `${ADSERVER_CONFIG_ENDPOINT}/audits`

  })
}