import React, { useEffect, useState } from 'react'
import { getAdUnitConfigById, getAdUnitConfigsByState, getAdUnitConfigs, toggleAdUnitConfigState, deleteAdUnitConfigById, getAdUnitConfigAuditsById } from '../NewSlotConfig/airtelHeaderBidding.service';
import Modal from '../Common Components/Modal';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom';
import AuditGroup from '../Audits/AuditGroup';


function ViewAdUnitConfig() {

  const [adUnitList, setAdUnitList] = useState([]);
  const [adUnitIds, setAdUnitIds] = useState([]);
  const [adUnitId, setAdUnitId] = useState("");
  const [adUnitState, setAdUnitState] = useState("")
  const [toggle, setToggle] = useState(true);
  const [response, setResponse] = useState({ msg: "", isError: false });

  const [modal, setModal] = useState({
    isOpen: false,
    content: null,
    title: '',
    didMount: null,
    flatten: false
  })

  useEffect(() => {

    getAdUnitConfigs()
      .then(res => {
        setAdUnitList(res.data);
        setResponse({ isError: false, msg: "" })
        const uniqueAdUnitIds = [];
        res.data.map(adUnitId => {
          if (uniqueAdUnitIds.indexOf(adUnitId.id) === -1) {
            uniqueAdUnitIds.push(adUnitId.id)
          }
          return 0
        })
        setAdUnitIds(uniqueAdUnitIds);
      })
      .catch(err => {
        setAdUnitList([])
        let msg = err && err.response && err.response.data ? err.response.data.error : "Error Fetching AdUnit Details";
        setResponse({ isError: true, msg: msg })
      })


  }, [toggle]);

  // useEffect(() => {

  //   getAdUnitConfigById(adUnitId)
  //     .then(res => {
  //       const adUnitIdList = [];
  //       adUnitIdList.push(res.data);
  //       setAdUnitList(adUnitIdList);
  //       setResponse({ isError: false, msg: "" })
  //       setAdUnitIds(res.data.id);
  //     })
  //     .catch(err => {
  //       setAdUnitList([])
  //       let msg = err && err.response && err.response.data ? err.response.data.error : "Error Fetching AdUnit Details";
  //       setResponse({ isError: true, msg: msg })
  //     })


  // }, [adUnitId]);

  function handleChange(func, value) {
    func(value);

    if(func == setAdUnitId){
    setAdUnitId(value);
    getAdUnitConfigById(value)
    .then(res => {
      const adUnitIdList = [];
      adUnitIdList.push(res.data);
      setAdUnitList(adUnitIdList);
      setResponse({ isError: false, msg: "" })
      const uniqueAdUnitIdList = [];
      uniqueAdUnitIdList.push(res.data.id);
      setAdUnitIds(uniqueAdUnitIdList);
    })
    .catch(err => {
      setAdUnitList([])
      let msg = err && err.response && err.response.data ? err.response.data.error : "Error Fetching AdUnit Details";
      setResponse({ isError: true, msg: msg })
    })
  }

  if(func == setAdUnitState){
    setAdUnitState(value);

    getAdUnitConfigsByState(value)
    .then(res => {
      setAdUnitList(res.data);
      setResponse({ isError: false, msg: "" })
      const uniqueAdUnitIds = [];
      res.data.map(adUnit => {
        if (uniqueAdUnitIds.indexOf(adUnit.id) === -1) {
          uniqueAdUnitIds.push(adUnit.id)
        }
        return 0
      })
      setAdUnitIds(uniqueAdUnitIds);
    })
    .catch(err => {
      setAdUnitList([])
      let msg = err && err.response && err.response.data ? err.response.data.error : "Error Fetching Publisher Details";
      setResponse({ isError: true, msg: msg })
    })
  }

  }

  const cellStyle = {
    maxWidth: "280px",
    lineBreak: "anywhere",
  };

  const nameStyle = {
    margin: "0",
    fontFamily: "Tondo, Helvetica Neue, Helvetica, Arial, sans-serif",
  };

  function toggleModal(title = '', content = '', callback, flatten = false) {
    setModal({
      isOpen: !modal.isOpen,
      title: title,
      content: content,
      flatten,
      didMount: callback
    })
  }

  function toggleState(id) {
    toggleAdUnitConfigState(id)
      .then(res => {
        setToggle(!toggle)
      })
      .catch(err => {
        let msg = err && err.response && err.response.data ? err.response.data.error : "Unable to toggle state";
        setResponse({ msg: msg, isError: true })
      })

  }

  function initDeleteConfig(id) {
    const confirmation = window.confirm('Are you sure you want to delete?');

    if (confirmation) {
      deleteAdUnitConfigById(id)
        .then(response => {
          let existingAdUnit = adUnitList.filter(adUnitId => (adUnitId.id !== id));
          setAdUnitList(existingAdUnit)
        })
        .catch(err => {
          let msg = err && err.response && err.response.data ? err.response.data.error : "Unable to Delete";
          setResponse({ msg: msg, isError: true })

        })
    }
  }


  function renderAdUnitRow(adUnit, index) {
    return (
      <div className={`divTableRow`} key={adUnit.id + index}>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{adUnit.id}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{adUnit.state.toUpperCase()}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <span
            className="text-info underline cursor-pointer"
            onClick={() => toggleModal('AdUnit Configuration', adUnit, null, true)}>View</span>
          &emsp;
          <Link to={{ pathname: '/editAdUnitConfig/' + adUnit.id }} className="text-warning underline">Edit</Link>
          &emsp;
          <span
            className="text-info underline cursor-pointer"
            onClick={() => toggleState(adUnit.id)}
          >Toggle</span>
          &emsp;
          <span
            className="text-warning underline cursor-pointer"
            onClick={() => toggleModal("Impression Audits",
              <AuditGroup id={adUnit.id} getAudits={getAdUnitConfigAuditsById} />
            )}
          >Audits</span>
          &emsp;
          <span
            className="text-danger underline cursor-pointer"
            onClick={() => initDeleteConfig(adUnit.id)}
          >Delete</span>
          &emsp;
          <Link to={{ pathname: '/editAdUnitConfig/', state: { adUnit: adUnit } }} className="text-info underline">
            Clone
          </Link>
          &emsp;
        </div>
      </div>
    );
  }

  return (
    <>
      {modal.isOpen &&
        <Modal
          flatten={modal.flatten}
          title={modal.title}
          btnHandler={toggleModal}
          closeTrigger={toggleModal}
          content={modal.content}
          didMount={modal.didMount}
        >
        </Modal>}
      <div>
        <div className="row m-0 mt-20 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 color-dark-gray">Search AdUnit Id</label>
              <Typeahead
                id="campaign-search"
                onChange={e => handleChange(setAdUnitId, e[0])}
                options={adUnitIds}
              />
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 color-dark-gray">AdUnit State</label>
              <form>
                <select
                  className="fs-16"
                  onChange={ev => handleChange(setAdUnitState, ev.target.value)}
                >
                  <option value="">All</option>
                  <option value={`adUnitState=active`}>Active</option>
                  <option value={`adUnitState=inactive`}>InActive</option>
                </select>
              </form>
            </fieldset>
          </div>
        </div>
        <div className="row  mt-20">
        </div>
      </div>
      <div className="m-0 mt-30 mr-20">
        <div className="row">
          <div className="col-sm-12">
          </div>
        </div>
      </div>
      <div className="row m-0 mt-0 mr-20">
        <div className="row m-0 mt-0 mb-20">
          <div className="col-md-12">
            <div className="divTable divTableHover mt-20">
              <div className="divTableHeading">
                <div className="divTableRow">
                  <div className="divTableHead">AdUnit Id</div>
                  <div className="divTableHead">AdUnit State</div>
                  <div className="divTableHead">Actions</div>
                </div>
              </div>
              <div className="divTableBody">
                {adUnitList && adUnitList.map((adUnit, index) => renderAdUnitRow(adUnit, index))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 mt-30 mr-20 vertical-text-center">
        <div className="col-md-9 text-center">
          <div className={response.isError ? "text-danger" : "text-success"}>
            <b>
              <h4>{response.msg}</h4>
            </b>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewAdUnitConfig;
