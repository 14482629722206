import React from "react";
import CreatableForm from "../Common Components/CreatableForm";
import { useFieldArray } from 'react-hook-form';

const BannerConfig = (props) => {
    const { register, control, errors, errorRequiredField } = props;
    
    const { fields, append, remove } = useFieldArray({
        control,
        name: "adSizes",
    });
    return (
        <>
            {/* <div className="row m-0 mt-30 mr-20 field-btn"> */}
            <div className="col-md-6">
                    <fieldset className="pl-20">
                        <label className="fs-14 required-field" htmlFor="bannerMimes">
                           Mimes
                        </label>
                        <CreatableForm
                            control={control}
                            register={register}
                            name="bannerMimes"
                            placeholder="Mimes"
                        />
                        {errors.bannerMimes}
                    </fieldset>
                </div>
                        <div className="col-md-6">
                        <fieldset className="pl-20">
                        <label className="fs-14" htmlFor="bannerApi">
                           Api
                        </label>
                        <CreatableForm
                            control={control}
                            register={register}
                            pattern="^\d*$"
                            name="bannerApi"
                            placeholder="Enter Interger Api list"

                        />
                        {errors.api }
                    </fieldset>
                    </div>
                    {/* </div> */}
            <div className="row m-0 mt-30 mr-20">

            </div>
            {fields.map((field, index) => (
                <div key={field.id + 1}>
                    <div className="row m-0 mt-30 mr-20 field-btn">
                        <div className="col-md-6">
                            <fieldset className="pl-20">
                                <label className="fs-14 " htmlFor="adsWidth">
                                    Ads Width
                                </label>
                                <input
                                    className="fs-14"
                                    type="text"
                                    autoComplete="off"
                                    {...register(`adSizes.${index}.w`, { required: false })}
                                    placeholder="Enter Companion Banner Width"
                                    pattern="[0-9]+"
                                    title="Allowed values are numbers"

                                />
                                {errors.adSizes && errorRequiredField()}
                            </fieldset>
                        </div>
                        <div className="col-md-6">
                            <fieldset className="pl-20">
                                <label className="fs-14 " htmlFor="adsHeight">
                                    Ads Height
                                </label>
                                <input
                                    className="fs-14"
                                    type="text"
                                    autoComplete="off"
                                    {...register(`adSizes.${index}.h`, { required: false })}
                                    placeholder="Enter Companion Banner Height"
                                    pattern="[0-9]+"
                                    title="Allowed values are numbers"

                                />
                                {!!errors.adSizes && errorRequiredField()}
                            </fieldset>
                        </div>
                    </div>
                    {
                        <button className="btn btn-small btn-remove pull-right mt-20" type="button" onClick={() => remove(index)}>
                            Remove  field
                        </button>}
                </div>
            ))}
             <div className="row m-0 mt-30 mr-20">

            <button className="bg-red btn btn-small btn-add  mt-20 ml-30" type="button" onClick={() => append({})}>
                Add Format
            </button>
            </div>

        </>
    )
}

export default BannerConfig;