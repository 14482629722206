import { ajax } from "../utils/ajax";
import { PERSONALIZATION_SCHEDULER_ENDPOINT } from "../Common Components/service.urls";

const serviceEndPointMap = {
  "Personalization" : `${PERSONALIZATION_SCHEDULER_ENDPOINT}/status`
}

export const getFileStatusList = (fileStatus, service) => {
  return ajax({
    method:"get",
    params:{
      fileStatus:fileStatus
    },
    url:`${serviceEndPointMap[service]}`
  })
}


export const startFileProcessing = () => {
  return ajax({
    method:"post",
    url:`${PERSONALIZATION_SCHEDULER_ENDPOINT}/start`
  })
}

