export const AD_SERVER_URL_PATTERN = "((https?):\/\/)?([w|W]{3}\.)?[a-zA-Z0-9]{2,}([a-zA-Z0-9]*((\/?|\.?|\-*|\_*)[a-zA-Z0-9]{2,}))*(\.{1}[a-zA-z]{2,})+$"

export const GENERIC_INPUT_PATTERN = "[A-Z0-9a-z,._-]{1,30}"


export const USER_NOT_IN_PLAN_PATTERN="isUserPlanNotIn\\(\\d+([,]{1}\\d+)*\\)"
export const USER_IN_PLAN_PATTERN="isUserPlanIn\\(\\d+([,]{1}\\d+)*\\)"
export const AD_CONDITION_PATTERN=`true|false|${USER_IN_PLAN_PATTERN}|${USER_NOT_IN_PLAN_PATTERN}`

export const DOB_PATTERN= "^[0-9]{2}-[0-9]{2}-[0-9]{4}$";
export const PHONE_NO_PATTERN= "[6-9]{1}[0-9]{9}";

export const NON_EMPTY_STRING_PATTERN="[A-Z0-9a-z]+([\\s._-]{0,1}[A-Z0-9a-z]+)*"

export const PERCENTAGE_PATTERN="[0-9]{1,3}"

export const SEMANTIC_VERSION_PATTERN=/^[1-9][0-9]?(\.[0-9]{1,2}){0,3}$/

export const PRIORITY_PATTERN="[1-9]{1,2}"

export const NEW_SLOT_CONFIG_CONDITION_PATTERN ="^\\[\\d+,\\d+\\](,\\[\\d+,\\d+\\])*$";

export const IMP_FORMAT ="[0-9]{1,30}";

