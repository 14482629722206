import { useForm } from 'react-hook-form';
import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { saveAccount, getAccountById } from '../NewSlotConfig/airtelHeaderBidding.service';
import { PublisherType } from '../constants/AdConstants'
import { IMP_STATE } from '../constants/AdConstants';
import AppConfig from "./AppConfig";
import SiteConfig from './SiteConfig';

function CreateAccount(props) {

  const id = useRef(props.match.params.id);
  const [response, setResponse] = useState({ msg: "", isError: false });
  const [publisher, setPublisher] = useState(false)

  const location = useLocation();
  const savedPublisher = location?.state?.publisher;

  const {
    register,
    handleSubmit,
    handleChange,
    formState: { errors },
    formState,
    reset,
    control,
    watch
  } = useForm({ mode: "onChange" });

  // function addAuction(){
  //     setAuction(true)
  // }
  // const renderDependentAuctionFormFields = (auction) => {

  //   const Form =  AuctionConfig ;
  //   return (
  //     <Form
  //       control={control}
  //       register={register}
  //       errors={errors}
  //       errorRequiredField={errorRequiredField}
  //       handleChange={handleChange}
  //       watch={watch}
  //     />
  //   );
  // };  

  const resetObj = {
    publisherType: "",
    state: "",
    appName: "",
    appBundle: "",
    appDomain: "",
    storeUrl: "",
    privacyPolicy: "",
    appExt: {},
    publisherName: "",
    publisherCat: "",
    publisherDomain: "",
    pubExt: {},
    siteName: "",
    siteUrl: "",
    siteDomain: "",
    sitePage: ""
  };

  const prepareOptionsListFromValues = (values) => {
    if (typeof values === "undefined") return [];
    return values.reduce((optionsList, value) => {
      optionsList = [...optionsList, { label: value, value: String(value) }];
      return optionsList;
    }, []);
  };

  const prepareNewEditForm = (data) => {
    data.publisherType = data.publisherType ? data.publisherType.toUpperCase() : null;
    data.state = data.status ? data.status.toUpperCase() : null;
    let appName, appBundle, appDomain, storeUrl, privacyPolicy, appExt, siteName, siteUrl, siteDomain, sitePage, publisherName, publisherCat, publisherDomain, pubExt;
    if (data.publisherType == "APP" && data.app) {
      appName = data.app.name;
      appBundle = data.app.bundle;
      appDomain = data.app.domain;
      storeUrl = data.app.storeUrl;
      privacyPolicy = data.app.privacyPolicy;
      appExt = data.app.ext;

      if (data.app.publisher) {
        publisherName = data.app.publisher.name;
        publisherCat = prepareOptionsListFromValues(data.app.publisher.cat);
        publisherDomain = data.app.publisher.domain;
        pubExt = data.app.publisher.ext;
        setPublisher(true)
      }
      data = { ...data, appName, appBundle, appDomain, storeUrl, privacyPolicy, appExt, publisherName, publisherCat, publisherDomain, pubExt };
    }
    if (data.publisherType == "SITE" && data.site) {
      siteName = data.site.name;
      siteUrl = data.site.siteUrl;
      siteDomain = data.site.domain;
      sitePage = data.site.page;
      if (data.site.publisher) {
        publisherName = data.site.publisher.name;
        publisherCat = prepareOptionsListFromValues(data.site.publisher.cat);
        publisherDomain = data.site.publisher.domain;
        pubExt = data.site.publisher.ext;
        setPublisher(true)
      }
      data = { ...data, siteName, siteUrl, siteDomain, sitePage, publisherName, publisherCat, publisherDomain, pubExt };
    }
    return data;
  };

  const prepareEditForm = async () => {
    if (typeof id.current !== "undefined" && id.current !== null) {
      try {
        const res = await getAccountById(id.current);
        return reset(prepareNewEditForm(res.data));;
      } catch (err) {
        setResponse({
          msg:
            err.response && err.response.data
              ? err.response.data.message
              : "Unknown",
          isError: true,
        });
      }
    }
  };

  const prepareFormByData = (data) => {
    return reset(prepareNewEditForm(data));
  }

  useEffect(() => {
    prepareEditForm();
  }, [id]);

  useEffect(() => {
    if (savedPublisher) {
      prepareFormByData(savedPublisher)
    }
  }, [savedPublisher]);


  function onSubmit(data, ev) {

    ev.preventDefault();
    let method = typeof id.current !== "undefined" && id.current !== null ? "PUT" : "POST";
    data.id = id.current;

    const removeNullValues = (data) => {
      return Object.fromEntries(
        Object.entries(data).filter(([_, v]) => v != null)
      );
    };

    let payload = {
      publisherType: data.publisherType.toLowerCase(),
      status: data.state.toLowerCase()
    };

    if (data.id) {
      payload = {
        ...payload,
        id: data.id,
      };
    }

    // payload = {
    //   ...payload,
    //   auction : {
    //     "price-granularity"  : data.priceGranularity ? data.priceGranularity : null,
    //     "truncate-target-attr" : data.truncateTargetAttr ?  Number(data.truncateTargetAttr) : null,
    //     "banner-cache-ttl" : data.bannerCacheTtl ?  Number(data.bannerCacheTtl) : null,
    //     "video-cache-ttl" : data.videoCacheTtl ?  Number(data.videoCacheTtl) : null,
    //     shouldCacheVideoBids : data.shouldCacheVideoBids ? data.shouldCacheVideoBids : null,
    //     "default-integration" : data.defaultIntegration ? data.defaultIntegration : null,
    //     "debug-allow" : data.debugAllow ? data.debugAllow : null,
    //     "bid-validations" : {
    //       banner_creative_max_size : data.bannerCreativeMaxSize ? data.bannerCreativeMaxSize : null,
    //     },
    //     events : {
    //       enabled : data.eventsEnable
    //     },
    //     "price-floors" : {
    //       enabled : data.priceFloorsEnable
    //     },
    //     metrics : {
    //       "verbosity-level" : data.verbosityLevel
    //     }

    //   }
    // };
    //   let filterAuction = removeNullValues(payload.auction)
    // payload.auction = filterAuction

    if (data.publisherType == "APP") {
      payload = {
        ...payload,
        app: {
          name: data.appName ? data.appName : null,
          bundle: data.appBundle ? data.appBundle : null,
          domain: data.appDomain ? data.appDomain : null,
          storeUrl: data.storeUrl ? data.storeUrl : null,
          privacyPolicy: data.privacyPolicy ? data.privacyPolicy == "TRUE" ? 1 : 0 : null,
          ext: data.appExt ? data.appExt : null,
          publisher: {
            name: data.publisherName ? data.publisherName : null,
            cat: data.publisherCat ? data.publisherCat.map((item) => item.value) : null,
            domain: data.publisherDomain ? data.publisherDomain : null,
            ext: data.pubExt ? data.pubExt : null
          }
        }
      }
      let filterPub = removeNullValues(payload.app.publisher)
      payload.app.publisher = filterPub
      let filterApp = removeNullValues(payload.app)
      payload.app = filterApp
    }

    if (data.publisherType == "SITE") {
      payload = {
        ...payload,
        site: {
          name: data.siteName ? data.siteName : null,
          siteUrl: data.siteUrl ? data.siteUrl : null,
          domain: data.siteDomain ? data.siteDomain : null,
          page: data.sitePage ? data.sitePage : null
        }
      }
      let filterSite = removeNullValues(payload.site)
      payload.site = filterSite
    }

    let filteredData = removeNullValues(payload)
    saveAccount(filteredData, method)
      .then((res) => {
        setResponse({ msg: "Submitted Successfully", isError: false });
        reset({});
        reset(resetObj);
        props.history.replace("/editAccount");
        id.current = null;
      })
      .catch((err) => {
        setResponse({ msg: "Error:" + err, isError: true });
      });
  }
  const AdMetaForm = {
    APP: AppConfig,
    SITE: SiteConfig,

  };

  const renderAdMetaForm = (publisherType) => {
    const Form = AdMetaForm[publisherType.toUpperCase()];
    return (
      <Form
        control={control}
        register={register}
        errors={errors}
        errorRequiredField={errorRequiredField}
        handleChange={handleChange}
        watch={watch}
        publisher={publisher}
      />
    );
  };
  const errorRequiredField = (message = "This field is required") => {
    return (<small className="text-danger">{message}</small>)
  }
  return (
    <>
      <div className="center-block" style={{ textAlign: 'center' }}>
        <h4> Publisher Config</h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="publisherType">
                Publisher Type
              </label>
              <select
                name="publisherType"
                className="fs-14"
                {...register('publisherType', { required: true })}>
                <option value="">Select a Ad Type</option>
                {Object.keys(PublisherType).map((publisherType) => (
                  <option value={publisherType} key={publisherType}>
                    {publisherType}
                  </option>
                ))}
              </select>
              {errors.publisherType && errorRequiredField()}
            </fieldset>
          </div>

          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="state">
                State
              </label>
              <select
                name="state"
                className="fs-14"
                {...register('state', { required: true })}>
                <option value="">Select State</option>
                {IMP_STATE.map((state) => (
                  <option value={state} key={state}>
                    {state}
                  </option>
                ))}
              </select>
              {errors.state && errorRequiredField()}
            </fieldset>
          </div>
        </div>

        {watch("publisherType") && renderAdMetaForm(watch("publisherType"))}
        {/* 
        <div className="row m-0 mt-30 mr-20"> 
            </div>
            <button name ="auction"  {...register("auction")}className="bg-green btn btn-small btn-add  mt-20 ml-30" type="button" onClick={() => addAuction()}>
                Add Auction
            </button>
            { auction &&renderDependentAuctionFormFields("auction")} */}

        <div className="row m-0 mt-30 mr-20 vertical-text-center">
          <div className="col-md-9 text-center">
            <div className={response.isError ? "text-danger" : "text-success"}>
              <b>
                <h4>{response.msg}</h4>
              </b>
            </div>
          </div>
          <div className="col-md-3">
            <input className="btn btn-success btn-md pull-right" type="submit" />
          </div>
        </div>

      </form>
    </>
  );
}

export default CreateAccount;