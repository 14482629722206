import React from "react";
import PropTypes from "prop-types";

const AuditPreview = (props) => {
  const { document, updateTime, updatedBy } = props;

  const renderFields = (document) => {
    let content = [];
    for (let key in document) {
      //for handling values other than object and array
      if (typeof document[key] !== "object") {
        content.push(
          <tr>
            <td>{key}</td>
            <td>{document[key].toString()}</td>
          </tr>
        );
      } // for handling objects which are not array and also are not nested object
      else if (
        typeof document[key] === "object" &&
        !Array.isArray(document[key])
      ) {
        // k below is the key for the object
        for (let k in document[key]) {
          if (typeof document[key][k] !== "object") {
            content.push(
              <tr>
                <td>{k}</td>
                <td>{document[key][k].toString()}</td>
              </tr>
            );
          }
        }
      } // for handling array values
      else {
        content.push(
          <tr>
            <td>{key}</td>
            <td>{document[key].join(" , ")}</td>
          </tr>
        );
      }
    }

    return content;
  };

  return (
    <div style={{ overflowX: "scroll" }}>
      <table className="table">
        <tbody>
          {renderFields(document)}
          <tr>
            <td>Updated Time</td>
            <td colSpan="3"> {updateTime} </td>
          </tr>
          <tr>
            <td>Updated By</td>
            <td colSpan="3"> {updatedBy} </td>
          </tr>
        </tbody>
      </table>
      <hr />
    </div>
  );
};

AuditPreview.propTypes = {
  document: PropTypes.object.isRequired,
  updateTime: PropTypes.number.isRequired,
  updatedBy: PropTypes.string.isRequired
};

export default AuditPreview;
