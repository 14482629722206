import {useForm} from 'react-hook-form';
import React , {useRef, useEffect, useState} from 'react';
import {AdTypes} from '../constants/AdConstants'
import { getadsOfferBlacklistedId, saveadsOfferBlacklistedId } from './offerIds.service';
import CreatableForm from '../Common Components/CreatableForm';
import { TENANTS } from '../constants/AdtechConstants';
import { storeClientNameInMemory } from '../utils/adtech.utils';


function AddOfferIdsBlacklisted(props){
  const { register, handleSubmit, formState:{errors}, reset, control } = useForm({mode:"onChange",
  });
  const id = useRef(props.match.params.id);
  const [response, setResponse] = useState({ msg: "", isError: false });
  const [clientNameList, setClientNameList] = useState([]);
  const resetObj = {
    offerId:"", 
    type:"",
    clientName:"",
    blacklistedCps:[]
  }
  const prepareEditForm = (data) => {
    let blacklistedCps = data.blacklistedCps.map(cp => {
      return {label:cp, value:cp}
    })
    
    data.blacklistedCps = blacklistedCps
    reset(data)
  }

  const prepareSubmitData = (data) => {
    let blacklistedCps =  data.blacklistedCps.map(blacklistedCps=>blacklistedCps.value)
   return blacklistedCps
  }
  
  useEffect(()=>{

    let tenants = localStorage.getItem(TENANTS)

    if (typeof tenants === "undefined" || tenants.length === 0) {
      setResponse({ msg: "Seems Like you don't have sufficient access", isError: true })
      return;
    }

    if (typeof tenants != String)
      tenants = tenants.split(',')

    if (clientNameList.length === 0 && tenants.length !== 0) {
      setClientNameList([...tenants])
    }

    if(typeof id.current !=="undefined" && id.current !== null){
      getadsOfferBlacklistedId(id.current)
      .then(res=>{
        prepareEditForm(res.data);
      })
      .catch(err=>{
        setResponse({msg:"Error fetching details", isError:true})
      })
    }
  },[id]);

  function onSubmit(data, ev){
    ev.preventDefault()
    let method = typeof id.current !=="undefined" && id.current !== null? "PUT" : "POST"
    data.id = typeof id.current !=="undefined" && id.current !== null? id.current : data.id;
    data = {...data, blacklistedCps:prepareSubmitData(data)}
    storeClientNameInMemory(data.clientName)
    saveadsOfferBlacklistedId(data, method)
    .then(res=>{
      setResponse({msg:"Submitted Successfully", isError:false})
      reset(resetObj)
      props.history.replace("/editBlacklistedOfferIds");
      id.current = null 
    })
    .catch(err=>{
      setResponse({msg:"Error:" + err.response && err.response.data && err.response.data.error, isError:true})
    })
  }

  const errorRequiredField= (message="This field is required") => {
    return(<small className="text-danger">{message}</small>)
  } 

  return (
    <>
      <div className="center-block" style={{textAlign:'center'}}>
        <h4>Offer Id Form</h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="ClientName">
                Client Name
              </label>
              <select
                name="clientName"
                className="fs-14"
                {...register('clientName', { required: true })}
              >
                <option value="">Select a Client</option>
                {clientNameList.map((clientName) => (
                  <option value={clientName.value} key={clientName.value}>
                    {clientName}
                  </option>
                ))}
              </select>
              {!!errors.clientName   && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
         <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 required-field" htmlFor="AdSlotId">
            Offer Id
            </label>
            <input
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register('offerId',{required:true})}
              placeholder="Enter Offer Id"
              pattern="[0-9]{1,50}"
              title="Allowed values are numbers"
              maxLength="50"
            />
            {!!errors.id && errorRequiredField()}
          </fieldset>
          </div>
        </div>
      
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="type">
                Ad Type
              </label>
              <select
                name="type"
                className="fs-14"
                {...register('type',{required:true})}>
                <option value="">Select a Ad Type</option>
                {Object.keys(AdTypes).map((adType) => (
                  <option value={adType} key={adType}>
                    {adType}
                  </option>
                ))}
              </select>
              {errors.type && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6"> 
          <fieldset className="pl-20">
          <label className="fs-14 required-field" htmlFor="AdSlotId">
              Blacklisted Cps
            </label>
            <CreatableForm 
              control={control} 
              register={register} 
              name="blacklistedCps" 
              placeholder ="Blacklisted Cps" 
              required={true} 
            />
            {errors.blacklistedCps && errorRequiredField()}
            </fieldset>
        </div>
      </div>
        <div className="row m-0 mt-30 mr-20 vertical-text-center">
          <div className="col-md-9 text-center">
            <div className={response.isError ? "text-danger" : "text-success"}>
              <b>
                <h4>{response.msg}</h4>
              </b>
            </div>
          </div>
          <div className="col-md-3">
            <input className="btn btn-success btn-md pull-right" type="submit" />
          </div>
        </div>
      </form>
    </>
  );
}

export default AddOfferIdsBlacklisted;