import { ajax } from "../utils/ajax";
import {IMP_CONFIG_ENDPOINT,ACCOUNT_ENDPOINT, AD_UNIT_CONFIG_ENDPOINT } from "../Common Components/service.urls";

export const getImpConfigs = () => {
  
  return ajax({
    method:"get",
    url:`${IMP_CONFIG_ENDPOINT}/list`
  })
}

export const getImpConfigsByState = (state) => {

  let query = typeof state !== 'undefined' && state !== '' ? `${state}` : 'impState=All';

  return ajax({
    method:"get",
    url:`${IMP_CONFIG_ENDPOINT}/state/list?${query}`
  })
}

export const getImpById = (impId) => {

    return ajax({
      method:"get",
      params : {
        impressionConfigId: impId
      },
      url:`${IMP_CONFIG_ENDPOINT}`
    })
  }
  
  export const toggleImpState = (impId) => {
      return ajax({
        method : "PUT",
        params : {
          impressionConfigId: impId
        },
        url:`${IMP_CONFIG_ENDPOINT}/toggleState`
      })
  }
  
  
  export const saveImpConfig = (impConfig ,method) => {
    return ajax({
      method : method,
      data : impConfig ,
      url : `${IMP_CONFIG_ENDPOINT}`,
      headers:{
        "auth-token": localStorage.getItem('authToken')
    }
    })
  }
  
  export const deleteImpById = (id) => {
  
    return ajax({
      method:"delete",
      params : {
        impressionConfigId:id
      },
      url:`${IMP_CONFIG_ENDPOINT}`
    })
  }
  
  export const getImpConfigAuditsById = (id) => {
    return ajax({
      method : "get",
      params :{
        impressionConfigId: id
      },
      url : `${IMP_CONFIG_ENDPOINT}/audits`
  
    })
  }

  export const getAccountsByState = (state) => {

    let query = typeof state !== 'undefined' && state !== '' ? `${state}` : 'pubStatus=All';
  
    return ajax({
      method:"get",
      url:`${ACCOUNT_ENDPOINT}/state/list?${query}`
    })
  }

  export const getAccounts = () => {
  
    return ajax({
      method:"get",
      url:`${ACCOUNT_ENDPOINT}/list`
    })
  }

  export const getAllAccount = () => {
  
    return ajax({
      method:"get",
      headers:{
        "auth-token": localStorage.getItem('authToken')
    },
      url:`${ACCOUNT_ENDPOINT}/accounts`
    })
  }
  
  export const getAccountById = (accId) => {
  
      return ajax({
        method:"get",
        params : {
          accountId: accId
        },
        url:`${ACCOUNT_ENDPOINT}`
      })
    }
    
    export const toggleAccountState = (accId) => {
        return ajax({
          method : "PUT",
          params : {
            accountId: accId
          },
          url:`${ACCOUNT_ENDPOINT}/toggleState`
        })
    }
    
    
    export const saveAccount = (account ,method) => {
    
      return ajax({
        method : method,
        data : account ,
        url : `${ACCOUNT_ENDPOINT}`,
        headers:{
          "auth-token": localStorage.getItem('authToken')
      }
      })
    }
    
    export const deleteAccountById = (id) => {
    
      return ajax({
        method:"delete",
        params : {
          accountId:id
        },
        url:`${ACCOUNT_ENDPOINT}`
      })
    }
    
    export const getAccountAuditsById = (id) => {
      return ajax({
        method : "get",
        params :{
          accountId: id
        },
        url : `${ACCOUNT_ENDPOINT}/audits`
    
      })
    }

    export const getAdUnitConfigs = () => {
  
    return ajax({
      method:"get",
      url:`${AD_UNIT_CONFIG_ENDPOINT}/list`
    })
  }
  
  export const getAdUnitConfigById = (adUnitConfigId) => {
  
      return ajax({
        method:"get",
        params : {
          adUnitConfigId: adUnitConfigId
        },
        url:`${AD_UNIT_CONFIG_ENDPOINT}`
      })
    }

    export const getAdUnitConfigsByState = (state) => {

      let query = typeof state !== 'undefined' && state !== '' ? `${state}` : 'adUnitState=All';
    
      return ajax({
        method:"get",
        url:`${AD_UNIT_CONFIG_ENDPOINT}/state/list?${query}`
      })
    }
  
    
    export const toggleAdUnitConfigState = (adUnitConfigId) => {
        return ajax({
          method : "PUT",
          params : {
            adUnitConfigId: adUnitConfigId
          },
          url:`${AD_UNIT_CONFIG_ENDPOINT}/toggleState`
        })
    }
    
    
    export const saveAdUnitConfig = (adUnitRequestVo ,method) => {
    
      return ajax({
        method : method,
        data : adUnitRequestVo ,
        url : `${AD_UNIT_CONFIG_ENDPOINT}`,
        headers:{
          "auth-token": localStorage.getItem('authToken')
      }
      })
    }
    
    export const deleteAdUnitConfigById = (id) => {
    
      return ajax({
        method:"delete",
        params : {
          adUnitConfigId:id
        },
        url:`${AD_UNIT_CONFIG_ENDPOINT}`
      })
    }
    
    export const getAdUnitConfigAuditsById = (id) => {
      return ajax({
        method : "get",
        params :{
          adUnitConfigId: id
        },
        url : `${AD_UNIT_CONFIG_ENDPOINT}/audits`
    
      })
    }