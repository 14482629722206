import { useForm } from 'react-hook-form';
import React, { useRef, useEffect, useState } from 'react';
import { getFeatureControlRuleList } from '../FeatureControlRule/feature.control.rule.service';
import { getFeatureControlById, saveFeatureControl } from './feature.control.service';
import { NON_EMPTY_STRING_PATTERN, PERCENTAGE_PATTERN } from '../constants/RegularExpressions';
import SelectForm from '../Common Components/SelectForm';

import moment from 'moment';
import { CLIENT_NAME, TENANTS } from '../constants/AdtechConstants';

import { useLocation } from 'react-router-dom';

function CreateFeatureControl(props) {
  const { register, handleSubmit, formState: { errors }, reset, control, setValue, watch } = useForm({
    mode: "onChange",

  });
  const id = useRef(props.match.params.id);
  const [response, setResponse] = useState({ msg: "", isError: false });
  const [audienceList, setAudienceList] = useState([])
  const [clientId, setClientId] = useState("");
  const [clientIdList, setClientIdList] = useState([])
  const location = useLocation()

  const resetObj = {
    featureId: "",
    description: "",
    key: "",
    ruleGroup: "",
    startDate: "",
    endDate: "",
    clientId: "",
    value1: "",
    value2: "",
    value3: "",
    perc1: "",
    perc2: "",
    perc3: ""
  };



  const prepareAudienceOptionFromList = async (ruleGroup, name, tenantId) => {
    if (typeof ruleGroup === "undefined" || ruleGroup == null)
      return [];

    let res = await getFeatureControlRuleList({ audienceIds: ruleGroup, tenantId: tenantId });
    if (typeof res !== "undefined") {

      // Creating list of all the rules associated with the feature control
      let group = res.data.map((featureRule) => {
          return {
            label: featureRule.ruleId,
            value: featureRule.id,
            };
          });

      setValue(name, group)
    }

  }

  const prepareEditForm = async () => {
    let tenantId = localStorage.getItem(CLIENT_NAME)
   
    if (typeof tenantId === "undefined" || tenantId === null)
      return;



    if (typeof id.current !== "undefined" && id.current !== null) {
      try {
        let res = await getFeatureControlById(id.current, tenantId)
        let value2 = "", perc2 = "", value3 = "", perc3 = "", value1="", perc1=""
        let startDate = new Date(res.data.validity.startDate)
        let endDate = new Date(res.data.validity.endDate)

        if (res.data.valueList[0] !== undefined) {
           value1 = res.data.valueList[0].value
           perc1 = res.data.valueList[0].percentage
        }

        if (res.data.valueList[1] !== undefined) {
          value2 = res.data.valueList[1].value
          perc2 = res.data.valueList[1].percentage
        }
        if (res.data.valueList[2] !== undefined) {
          value3 = res.data.valueList[2].value
          perc3 = res.data.valueList[2].percentage
        }

        setClientId(res.data.clientId)
        reset({
          ...res.data, startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD'),
          value1: value1, perc1: perc1, value2: value2, perc2: perc2, value3: value3, perc3: perc3
        })

        prepareAudienceOptionFromList(res.data.ruleGroup, "ruleGroup", tenantId)

        //  prepareAudienceOptionFromList(res.data.excludedAudienceGroup , "exludedAudienceGroup")
      }
      catch (err) {
        setResponse({
          msg:
            err.response && err.response.data
              ? err.response.data.message
              : "Unknown",
          isError: true,
        });
      }
    }

  }


  useEffect(() => {

    let tenants = localStorage.getItem(TENANTS)
    if (typeof tenants === "undefined" || tenants.length === 0) {
      setResponse({ msg: "Seems Like you don't have sufficient access", isError: true })
      return;
    }

    if(typeof tenants != String)
    tenants = tenants.split(',')

    if(clientIdList.length === 0 && tenants.length !==0){
      setClientIdList(tenants)
    }

    if(typeof clientId === "undefined" || clientId === ""){ 
      let client = localStorage.getItem(CLIENT_NAME)
      if(typeof client !=="undefined" && client !== ""){
        setClientId(client)
      }
      return
    }

     
    getFeatureControlRuleList({ tenantId: clientId })
      .then(res => {

        let audienceList = res.data.map(client => {
          return { label: client.ruleId, value: client.id }
        });

        setAudienceList(audienceList)
        setResponse({ msg: "", isError: false })
      })
      .then(res => prepareEditForm())
      .catch(err => {
        let msg = err && err.response && err.response.data ? err.response.data.error : "Error getting Feature Control Rulse"
        setResponse({ msg: msg, isError: true })
      })
      

  }, [id, clientId]);

  function prepareListFromOptions(options) {
    if (typeof options === "undefined")
      return

    let result = options.map(option => {
        return option.value;
    })

    return result;
  }

  function updateValue() {
    let val1 = watch("value1")
    let val2 = watch("value2")
    let perc1 = watch("perc1")
    let perc2 = watch("perc2")
    if (val2 === "" || perc2 === "") {
      setValue("value3", "")
      setValue("perc3", "")
    }

    if (val1 === "" || perc1 === "") {
      setValue("value2", "")
      setValue("perc2", "")
    }
  }

  function prepareSubmitData(data) {
    let validity = { startDate: data.startDate, endDate: data.endDate };
    let ruleGroup = prepareListFromOptions(data.ruleGroup)
    let valueList = getValueList(data)
    data = { ...data, ruleGroup, validity, valueList, clientId }
    return data;
  }

  function getValueList(data) {
    let  valueList= [];
    if (data.value1 !== "undefined" && data.value1 !== ""){
      valueList= [...valueList, { value: data.value1, percentage: data.perc1 }]
    }

    if (data.value2 !== "undefined" && data.value2 !== ""){
      valueList= [...valueList, { value: data.value2, percentage: data.perc2 }]
    }

    if (data.value3 !== "undefined" && data.value3!== ""){
      valueList= [...valueList, { value: data.value3, percentage: data.perc3 }]
    }

    return valueList;
  }

  function validateData(data){

    if(!validatePercentageSum(data))
      return false;
    return true;
  }

  function validatePercentageSum(data) {

    let sum = 0;
    let fieldMissing = true
    if (data.value1 !== "undefined" && data.value1 !== "" && data.perc1 !== "undefined" && data.perc1 !== "") {
      sum = parseInt(data.perc1)
      fieldMissing = false
    }
    else {
      fieldMissing=true
    }

    let secondSum = false
    if (data.value2 !== "undefined" && data.value2 !== "") {
      if (data.perc2 !== "undefined" && data.perc2 !== "") {
        secondSum = true

        sum += parseInt(data.perc2)
      }
      else {
        fieldMissing = true;
      }
    }

    if (data.perc2 !== "undefined" && data.perc2 !== "" && !secondSum) {
      if (data.value2 !== "undefined" && data.value2 !== "") {
        sum += parseInt(data.perc2)
        fieldMissing = false
      }
      else {
        fieldMissing = true;
      }
    }

    let thirdSum = false
    if (data.value3 !== "undefined" && data.value3 !== "") {
      if (data.perc3 !== "undefined" && data.perc3 !== "") {
        thirdSum = true
        sum += parseInt(data.perc3)
        fieldMissing = false
      }
      else {
        fieldMissing = true;
      }
    }

    if (data.perc3 !== "undefined" && data.perc3 !== "" && !thirdSum) {
      if (data.value3 !== "undefined" && data.value3 !== "") {
        sum += parseInt(data.perc2)
        fieldMissing = false
      }
      else {
        fieldMissing = true;
      }
    }

    if (fieldMissing === true) {
      setResponse({ msg: "Either value or percentage is missing", isError: true })
      return false;
    }

    if (sum !== 100) {
      setResponse({ msg: "Percentage total should be 100", isError: true })
      return false
    }

    return true;
  }

  function onSubmit(data, ev) {
    ev.preventDefault()
    let method = typeof id.current !== "undefined" && id.current !== null ? "PUT" : "POST"
    data.id = id.current
    data = prepareSubmitData(data)
    if (validateData(data)) {
      saveFeatureControl(data, method)
        .then(res => {
          setResponse({ msg: "Submitted Successfully", isError: false })
          reset(resetObj)
          props.history.replace("/editfeaturecontrol");
          id.current = null
        })
        .catch(err => {
          setResponse({ msg: "Error:" + err.response && err.response.data && err.response.data.error, isError: true })
        })


    }
  }

  const errorRequiredField = (message) => {
    if (typeof message === "undefined" || message === "")
      message = "This field is required"
    return (<small className="text-danger">{message}</small>)
  }

  return (
    <>
      <div className="center-block" style={{ textAlign: 'center' }}>
        <h4>Feature Control Form</h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                Name*
              </label>
              <input
                className="fs-14"
                type="text"
                readOnly={typeof id.current !== "undefined" && id.current !== null}
                autoComplete="off"
                {...register('featureId', { required: true, pattern: { value: NON_EMPTY_STRING_PATTERN, message: "Please enter a non-blank string. Terminal Whitespaces are not allowed" } })}
                placeholder="Enter Feature Id"
                maxLength="50"
              />
              {errors.featureId && errorRequiredField(errors.featureId.message)}
            </fieldset>
          </div>
        </div>

        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                Description
              </label>
              <input
                className="fs-14"
                type="text"
                autoComplete="off"

                {...register('description', { required: false })}
                placeholder="Enter Feature Description"
                maxLength="100"
              />
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                Client Id*
              </label>
              <select
                name="clientId"
                id="clientId"
                className="fs-14"
                disabled={typeof id.current !== "undefined" && id.current !== null}
                value={clientId}
                onChange={e => {
                  setClientId(e.target.value)
                }}
              // {...register('clientId',{required:true})}
              >
                <option value="">Select a Client</option>
                {clientIdList.map((clientId) => (
                  <option value={clientId} key={clientId}>
                    {clientId}
                  </option>
                ))}
              </select>
              {(typeof clientId === "undefined" || clientId === "") && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        {/* {&&<> */}
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                Attribute Key*
              </label>
              <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('key', { required: true, pattern: { value: NON_EMPTY_STRING_PATTERN, message: "Please enter a non-blank string. Terminal Whitespaces are not allowed" } })}
                placeholder="Enter Key"

                maxLength="50"
              />
              {errors.key && errorRequiredField(errors.key.message)}
            </fieldset>
          </div>

        </div>
        <div className="row m-0 mt-30 mr-20">

          <div className="col-md-6" style={{ zIndex: 3 }}>
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                Rule*
              </label>

              <SelectForm
                options={audienceList}
                control={control}
                register={register}
                name="ruleGroup"
                placeholder="Select Rule"
                required={true}
                isMulti={true}
                disabled={typeof clientId === "undefined" || clientId === ""}
              />
              {errors.ruleGroup && errorRequiredField(errors.ruleGroup.message)}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30" >
          {/* <div  className="mt-20"> */}
          <div className="col-md-3 m-0 mr-20">
          <fieldset className="pl-20">
            <label className="fs-14" htmlFor="AdSlotId">
              Value 1*
            </label>
            <input
              className="fs-14"
              type="text"
              name='value1'
              autoComplete="off"
              {...register('value1', { required: true, pattern: { value: NON_EMPTY_STRING_PATTERN, message: "Please enter a non-blank string. Terminal Whitespaces are not allowed" },
              onChange: (e) => updateValue() })}
              placeholder="Enter Attribute Value"
              maxLength="50"
            />
            {errors.value1 && errorRequiredField(errors.value1.message)}
            </fieldset>
          </div>
          <div className="col-md-3 m-0">
            <label className="fs-14" htmlFor="AdSlotId">
              Percentage*
            </label>
            <input
              className="fs-14"
              type="number"
              name="perc1"
              min={1}
              max={100}
              autoComplete="off"
              {...register('perc1', { required: true, pattern: { value: PERCENTAGE_PATTERN, message: "Please enter a number" },
              onChange: (e) => updateValue() })}
              placeholder="Enter Attribute Percentage"
              maxLength="3"
            />
            {errors.perc1 && errorRequiredField(errors.perc1.message)}
          </div>
        </div>

        <div className="row m-0 mt-30"
          disabled={typeof watch("value1") === "undefined" || watch("value1") === "" || typeof watch("perc1") === "undefined"
            || watch("perc1") === ""} >
          {/* <div  className="mt-20"> */}
          <div className="col-md-3 m-0 mr-20">
          <fieldset className="pl-20">
            <label className="fs-14" htmlFor="AdSlotId">
              Value 2
            </label>
            <input
              className="fs-14"
              type="text"
              name='value2'
              autoComplete="off"
              {...register('value2', {
                required: false, pattern: { value: NON_EMPTY_STRING_PATTERN, message: "Please enter a non-blank string. Terminal Whitespaces are not allowed" },
                onChange: (e) => updateValue()
              })}
              placeholder="Enter Attribute Value"
              maxLength="50"

            />
            {errors.value2 && errorRequiredField(errors.value2.message)}
            </fieldset>
          </div>
          <div className="col-md-3 m-0">
            <label className="fs-14" htmlFor="AdSlotId">
              Percentage
            </label>
            <input
              className="fs-14"
              type="number"
              name='perc2'
              min={1}
              max={99}
              autoComplete="off"
              {...register('perc2', {
                required: false, pattern: { value: PERCENTAGE_PATTERN, message: "Please enter 2 digit" },
                onChange: (e) => updateValue()
              })}
              placeholder="Enter Attribute Percentage"
              maxLength="2"
            />
            {errors.perc2 && errorRequiredField(errors.perc2.message)}
          </div>
        </div>

        <div className="row m-0 mt-30"
          disabled={typeof watch("value2") === "undefined" || watch("value2") === "" || typeof watch("perc2") === "undefined" || watch("perc2") === ""}
        >
          {/* <div  className="mt-20"> */}
          <div className="col-md-3 m-0 mr-20">
          <fieldset className="pl-20">
            <label className="fs-14" htmlFor="AdSlotId">
              Value 3
            </label>
            <input
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register('value3', { required: false, pattern: { value: NON_EMPTY_STRING_PATTERN, message: "Please enter a non-blank string. Terminal Whitespaces are not allowed" } })}
              placeholder="Enter Attribute Value"
              maxLength="80"
            />
            {errors.value3 && errorRequiredField(errors.value3.message)}
            </fieldset>
          </div>
          <div className="col-md-3 m-1">
            <label className="fs-14" htmlFor="AdSlotId">
              Percentage
            </label>
            <input
              className="fs-14"
              type="number"
              name='perc3'
              min={1}
              max={98}
              autoComplete="off"
              {...register('perc3', { required: false, pattern: { value: PERCENTAGE_PATTERN, message: "Please enter a 2 digit number." } })}
              placeholder="Enter Attribute Percentage"
              maxLength="2"
            />
            {errors.perc3 && errorRequiredField(errors.perc3.message)}
          </div>
        </div>

        {/* <div className="row m-0 mt-30 mr-20">
           <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                Attribute Value*
              </label>
              <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('value',{required:true, pattern :{value: NON_EMPTY_STRING_PATTERN,message:"Please enter a non-blank string. Terminal Whitespaces are not allowed"}})}
                placeholder="Enter Attribute Value"
                maxLength="50"
              />
              {errors.value && errorRequiredField(errors.value.message)}
            </fieldset>
          </div>
         
        </div> */}

        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-3">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                Start Date*
              </label>
            </fieldset>
          </div>
          <div className="col-md-3">
            <input
              className="fs-18 disable"
              type="date"
              style={{ width: "100%" }}

              min={moment(new Date()).format('YYYY-MM-DD')}
              {...register('startDate', { required: true })}
              placeholder="Enter Starting Date"
              readonly={typeof id.current !== "undefined" && id.current !== null ? "readonly" : false}
            />
            {errors.startDate && errorRequiredField()}
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-3">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                End Date*
              </label>
            </fieldset>
          </div>
          <div className="col-md-3">
            <input
              className="fs-18"
              type="date"
              style={{ width: "100%" }}
              min={moment(new Date()).format('YYYY-MM-DD')}
              {...register('endDate', { required: true })}
              placeholder="Enter End Date"

            />
            {errors.endDate && errorRequiredField()}
          </div>
        </div>
        {/* </>
      } */}


        <div className="row m-0 mt-30 mr-20 vertical-text-center">
          <div className="col-md-9 text-center">
            <div className={response.isError ? "text-danger" : "text-success"}>
              <b>
                <h4>{response.msg}</h4>
              </b>
            </div>
          </div>
          <div className="col-md-3">
            <input className="btn btn-success btn-md pull-right" type="submit" />
          </div>
        </div>
      </form>
    </>
  );
}

export default CreateFeatureControl;