import { useForm } from 'react-hook-form';
import "../User.css"
import { useHistory } from 'react-router-dom'
import React, { useState } from 'react'
import { updateUserCredential } from '../user.service'
import { DOB_PATTERN, PHONE_NO_PATTERN } from '../../constants/RegularExpressions';
import { FORGOT_PASSWORD_ENDPOINT, UPDATE_CREDENTIALS_ENDPOINT } from '../../Common Components/service.urls';

function UpdateCredential() {

    const { register, handleSubmit, reset } = useForm();
    const [response, setResponse] = useState({ msg: "", isError: false });
    const[readonly, setReadOnly] = useState("read")
    let id = localStorage.getItem('userId');
    let endPoint = typeof id !== "undefined" && id !== null ? UPDATE_CREDENTIALS_ENDPOINT: FORGOT_PASSWORD_ENDPOINT
    const onSubmit = (data) => {
        updateUserCredential(data,endPoint)
            .then(res => {
                setResponse({
                    msg: res.data,
                    isError: false,
                });
                //reset();
                localStorage.clear();
                window.location.href = "/";

            })
            .catch(err => {
                if (typeof err.response !== "undefined")
                    setResponse({ msg: err.response.data.error, isError: true })
                else
                    setResponse({ msg: "Oops! Something is wrong", isError: true })
            })
    }

    return (
        <div>

            <div className='register-box'>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="new-password">
                    <div className='form-group'>
                        <label htmlFor='userId'>UserId</label>
                        <input type="text" value={id} className="form-control" readonly={readonly} onFocus={(e)=>{setReadOnly(false)}} autoComplete='off' id="userId" {...register('userId')} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='mailId'>Mail Id</label>
                        <input type="text" className="form-control" autoComplete='off' id="mailId" {...register('mailId')} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='phoneNo'>Phone No</label>
                        <input type="text" className="form-control"  autoComplete='off' placeholder='Ex : 9999988888' id="phoneNo" pattern={PHONE_NO_PATTERN} title='Not a valid mobile number' {...register('phoneNo')} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='dob'>DOB</label>
                        <input type="text" className="form-control" autoComplete='off' placeholder='Ex: 01-01-2022' id="dob" pattern={DOB_PATTERN} title='Invalid dob' {...register('dob')} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='password'>Password</label>
                        <input type="password" readonly={readonly} onFocus={(e)=>{setReadOnly(false)}} className="form-control" id="password" {...register('password')} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor='confirmPassword'>Confirm Password</label>
                        <input type="password" readonly={readonly} onFocus={(e)=>{setReadOnly(false)}} className="form-control" id="confirmPassword" {...register('confirmPassword')} />
                    </div>
                    <div className={response.isError ? "text-danger" : "text-success"}>
                        <b>
                            <h5>{response.msg}</h5>
                        </b>
                    </div>
                    <button type='submit' className='btn button-22'>Update Password</button>
                </form>
            </div>
        </div>
    )
}

export default UpdateCredential
