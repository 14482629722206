import React from 'react';
import CreatableForm from '../Common Components/CreatableForm';
import { useFieldArray } from 'react-hook-form';

const PrebidConfig = (props) => {
    const { register, control, errors, errorRequiredField } = props;
    const { fields:prebidFields, append:prebidAppend, remove:prebidRemove } = useFieldArray({
        control,
        name: "prebidAdSizes",
    });  
    

    return (
        <>         
                <div className="row m-0 mt-30 mr-20">
                <div className="col-md-6">
                    <fieldset className="pl-20">
                        <label className="fs-14 required-field" htmlFor="prebidSource">
                            Header Bidding Source
                        </label>
                        <input
                            className="fs-14"
                            type="text"
                            name="source"
                            autoComplete="off"
                            defaultValue="AIRTEL"
                            readOnly
                            {...register('prebidSource', {
                                required: true,
                                setValueAs: (value) => value.toUpperCase()
                            })}
                        />
                        {!!errors.prebidSource && errorRequiredField()}
                    </fieldset>
                </div>
                <div className="col-md-6">
                    <fieldset className="pl-20">
                        <label className="fs-14 required-field" htmlFor="prebidAdUnitIds">
                            Ad Unit Ids
                        </label>
                        <CreatableForm
                            control={control}
                            register={register}
                            name="prebidAdUnitIds"
                            placeholder="Ad Unit Ids"
                            required={true}
                        />
                        {errors.prebidAdUnitIds && errorRequiredField()}
                    </fieldset>
                </div>
            </div>

            <div className="row m-0 mt-30 mr-20">
                <div className="col-md-6">
                    <fieldset className="pl-20">
                        <label className="fs-14" htmlFor="prebidSlotAdType">
                            Slot Ad Type
                        </label>
                        <select
                            className="fs-14"
                            {...register("prebidSlotAdType", {
                                required: false,
                                validate: (value) => value !== "Select a Slot Ad Type",
                            })}
                        >
                            <option value="">Select a Slot Ad Type</option>
                            <option value="BANNER">BANNER</option>
                            <option value="INTERSTITIAL">INTERSTITIAL</option>
                            <option value="AUDIO">AUDIO</option>
                            <option value="VIDEO">VIDEO</option>
                        </select>
                        {!!errors.prebidSlotAdType && errorRequiredField()}
                    </fieldset>
                </div>
            </div>

            {prebidFields.map((prebidField, index) => (
                <div key={prebidField.id + 1}>
                    <div className="row m-0 mt-30 mr-20 field-btn">
                        <div className="col-md-6">
                            <fieldset className="pl-20">
                                <label className="fs-14 " htmlFor="adsWidth">
                                    Ads Width
                                </label>
                                <input
                                    className="fs-14"
                                    type="text"
                                    autoComplete="off"
                                    {...register(`prebidAdSizes.${index}.width`, { required: false })}
                                    placeholder="Enter Companion Banner Width"
                                    pattern="[0-9]+"
                                    title="Allowed values are numbers"
                                />
                                {errors.prebidAdSizes && errorRequiredField()}
                            </fieldset>
                        </div>
                        <div className="col-md-6">
                            <fieldset className="pl-20">
                                <label className="fs-14 " htmlFor="adsHeight">
                                    Ads Height
                                </label>
                                <input
                                    className="fs-14"
                                    type="text"
                                    autoComplete="off"
                                    {...register(`prebidAdSizes.${index}.height`, { required: false })}
                                    placeholder="Enter Companion Banner Height"
                                    pattern="[0-9]+"
                                    title="Allowed values are numbers"

                                />
                                {!!errors.prebidAdSizes && errorRequiredField()}
                            </fieldset>
                        </div>
                    </div>

                    {<button className="btn btn-small btn-remove pull-right mt-20" type="button" onClick={() => prebidRemove(index)}>
                            Remove  field
                    </button>}
                </div>
            ))}
            <button className="bg-red btn btn-small btn-add  mt-20 ml-30" type="button" onClick={() => prebidAppend({})}>
                Add Ads Size
            </button>   
            
        </>
    )
}

export default PrebidConfig;