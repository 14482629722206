import React, {useState, useEffect} from 'react'
import { deleteSlotPriorityById, getSLotPrioritiesAuditsById, getSlotPrioritiesList } from './slotPriorites.service';
import { Link } from 'react-router-dom';
import Modal from '../Common Components/Modal';
import { Typeahead } from 'react-bootstrap-typeahead';
import { TENANTS } from '../constants/AdtechConstants';
import { PlatformList } from '../constants/AdConstants';
import { storeClientNameInMemory } from '../utils/adtech.utils';
import AuditGroup from '../Audits/AuditGroup';

function ViewSlotPriorities() {
  const [slotPrioritiesList, setSlotPrioritiesList] = useState([]);
  const [response, setResponse] = useState({ msg: "", isError: false });
  const [clientNameList, setClientNameList] = useState([]);
  const [clientName, setClientName] = useState("")
  const [os, setOs] = useState("");
  const [slotNameList, setSlotNameList] = useState([])
  const [slotName, setSlotName] = useState("")


  const [modal, setModal] = useState({
    isOpen : false,
    content : null,
    title : '',
    didMount : null,
    flatten : false
  })

  function handleChange(func, value) {
    func(value);
  }

  const cellStyle = {
    maxWidth: "280px",
    lineBreak: "anywhere",
  };

  const nameStyle = {
    margin: "0",
    fontFamily: "Tondo, Helvetica Neue, Helvetica, Arial, sans-serif",
  };
  useEffect(() => {

    let tenants = localStorage.getItem(TENANTS)

    if (typeof tenants === "undefined" || tenants.length === 0) {
      setResponse({ msg: "Seems Like you don't have sufficient access", isError: true })
      return;
    }

    if (typeof tenants != String)
      tenants = tenants.split(',')

    if (clientNameList.length === 0 && tenants.length !== 0) {
      setClientName(`clientName=${tenants[0]}`)
      setClientNameList([...tenants])

      setOs(`os=${PlatformList[0]}`)
    }


    if (typeof clientName === "undefined" || clientName === "") {
      return;
    }

    storeClientNameInMemory(clientName.split('=')[1]);

    getSlotPrioritiesList({clientName, os, slotName})
    .then(res=>{
      setSlotPrioritiesList(res.data);
      const uniqueKeys = [];
      res.data.map(slotPriority => {
        if (uniqueKeys.indexOf(slotPriority.slotName) === -1) {
          uniqueKeys.push(slotPriority.slotName)
        }
        return 0
      })
      setSlotNameList(uniqueKeys);
      setResponse({isError:false, msg:""})
    })
    .catch(err=>{
      setSlotPrioritiesList([])
      let msg = err && err.response && err.response.data ?err.response.data.error : "Error fetching Slot Priorities";
      setResponse({isError:true, msg:msg})
    })
    return () => {
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotName, clientName, os]);

  function toggleModal(title = '', content = '', callback, flatten = false){
    setModal({
        isOpen : !modal.isOpen,
        title : title,
        content : content,
        flatten,
        didMount : callback
    })
  }


  function initDeleteProperties(id) {
    const confirmation = window.confirm('Are you sure you want to delete?');

    if(confirmation){
        deleteSlotPriorityById(id)
        .then( response => {
          let existingPriorities = slotPrioritiesList.filter(slotPriority => (slotPriority.id !== id));
            setSlotPrioritiesList(existingPriorities)
        })
        .catch( err => {
          let msg = err && err.response && err.response.data ? err.response.data.error : "Unable to Delete";
          setResponse({msg:msg, isError:true})
        })
    }
  }


  function renderAdPropertiesRow(content,index ){
    return (
      <div className={`divTableRow`} key={content.slotName+index}>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{content.slotName}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <span 
          className="text-info underline cursor-pointer"
          onClick={() => toggleModal("Slot Source Priority", content, null, true)}>View</span> 
          &emsp;
          <Link to={'/editpriority/' + content.id} className="text-warning underline">Edit</Link>
          {/* &emsp; */}
          <span
            className="text-info underline cursor-pointer"
            onClick={() => toggleModal("Slot priorities Audits",
              <AuditGroup id={content.id} getAudits={getSLotPrioritiesAuditsById} />
            )}
          >Audits</span>
          <span 
          className="text-danger underline cursor-pointer"
          onClick={()=>initDeleteProperties(content.id)}
          >Delete</span> 
          &emsp;
        </div>
      </div>
    );
  }
 
  return (
    <>
      {modal.isOpen && 
      <Modal 
          flatten={modal.flatten}
          title={modal.title} 
          btnHandler={toggleModal}
          closeTrigger={toggleModal}
          content={modal.content}
          didMount={modal.didMount}
      >
      </Modal>}
      <div>
       <div className="m-0 mt-30 mr-20">
          <div className="row">
            <div className="col-sm-4">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray">Search Slot Name</label>
                <Typeahead
                  id="type-search"
                  onChange={e => handleChange(setSlotName, e[0])}
                  options={slotNameList}
                />
              </fieldset>
            </div>
            
            <div className="col-sm-4">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray">Client Name</label>
                <form>
                  <select
                    name='clientName'
                    id='clientName'
                    value={clientName}
                    onChange={e => handleChange(setClientName, e.target.value)}
                    className="fs-16" >
                    {clientNameList.map(clientName =>
                      <option
                        key={clientName}
                        value={`clientName=${clientName}`}>
                        {clientName}
                      </option>)}
                  </select>
                </form>
              </fieldset>
            </div>
            <div className="col-sm-4">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray"> OS</label>
                <form>
                  <select
                    name='os'
                    id='os'
                    value={os}
                    onChange={e => handleChange(setOs, e.target.value)}
                    className="fs-16" > 
                    {PlatformList.map(os =>
                      <option key={os} value={`os=${os}`}>
                        {os}
                      </option>)}
                  </select>
                </form>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 mt-0 mr-20">
        <div className="row m-0 mt-0 mb-20">
          <div className="col-md-12">
          <h5 className="h5 text-center" style={{"textAlign":"center"}}>Slot Source Priorities</h5>
            <div className="divTable divTableHover mt-20">
              <div className="divTableHeading">
                <div className="divTableRow">
                  <div className="divTableHead">Slot Name/Id</div>
                  <div className="divTableHead">Actions</div>
                </div>
              </div>
              <div className="divTableBody">
                {slotPrioritiesList && 
                slotPrioritiesList.map((slotPriority, index)=>renderAdPropertiesRow(slotPriority, index))}
              </div>
            </div>
          </div>
        </div>  
      </div>
      <div className="row m-0 mt-30 mr-20 vertical-text-center">
        <div className="col-md-9 text-center">
          <div className={response.isError ? "text-danger" : "text-success"}>
            <b>
              <h4>{response.msg}</h4>
            </b>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewSlotPriorities
