import React from 'react';
import { useForm } from "react-hook-form";
import { useState } from "react";
import PublisherConfig  from './PublisherConfig';

function SiteConfig(props){
  
const {
    register, 
    control, 
    errors , 
    handleChange,
    watch,
    errorRequiredField} = props;
   
  const [publisher,setPublisher]=useState(false)
  
  function addPublisher(){
    setPublisher(true)
  }

  const renderDependentPublisherFormFields = (publisher) => {

    const Form =  PublisherConfig ;
    return (
      <Form
        control={control}
        register={register}
        errors={errors}
        errorRequiredField={errorRequiredField}
        handleChange={handleChange}
        watch={watch}
      />
    );
};  
 

  return (
    <>
      <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 required-field" htmlFor="siteName">
             Name
            </label>
            <input
              name="siteName"
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register('siteName',{required:true})}
              placeholder="Enter name"
              // pattern="[a-zA-Z0-9\.\-_\s]{1,50}"
              title="Allowed values are numbers, alphabets, spaces, '.', '-', '_'"
              maxLength="500"
            />
            {errors.siteName && errorRequiredField()}
          </fieldset>
        </div>
        
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 required-field" htmlFor="siteUrl">
              Site url
            </label>
            <input
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register('siteUrl',{required:true})}
              placeholder="Enter site url"
            />
            {errors.bundle && errorRequiredField()}
          </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14" htmlFor="siteDomain">
            Domain
            </label>
            <input
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register('siteDomain',{required:false})}
              placeholder="Enter domain"
            />
            {errors.siteDomain && errorRequiredField()}
          </fieldset>
        </div>

        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 required-field" htmlFor="sitePage">
              Page
            </label>
            <input
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register('sitePage',{required:true})}
              placeholder="Enter page"
            />
            {errors.sitePage && errorRequiredField()}
          </fieldset>
        </div>
        </div>
       
        <div className="row m-0 mt-30 mr-20"> 
            </div>
            <button name ="publisher"  {...register("publisher")}className="bg-red btn btn-small btn-add  mt-20 ml-30" type="button" onClick={() => addPublisher()}>
                Add Publisher
            </button>
            { publisher &&renderDependentPublisherFormFields("publisher")}

    </>
  )
}

export default SiteConfig;