import React, {useState, useEffect} from 'react'
import Modal from '../Common Components/Modal';
import { Link } from 'react-router-dom';
import {getFeatureControlRuleList, deleteFeatureControlRuleById, getFeatureControlRuleAuditsById} from './feature.control.rule.service';

import AuditGroup from '../Audits/AuditGroup';
import { TENANTS } from '../constants/AdtechConstants';
import { Typeahead } from 'react-bootstrap-typeahead';
import { storeClientNameInMemory } from '../utils/adtech.utils';

function ViewFeatureControlRule() {
  const [featureControlRuleList, setFeatureControlRuleList] = useState([]);
  const [response, setResponse] = useState({ msg: "", isError: false });

  const [tenantId, setTenantId] = useState("")
  const [tenantList, setTenantList] = useState([])
  const [ruleId, setRuleId] = useState("")
  const [ruleIds, setRuleIds] = useState([]);

  function handleChange(func, value){
    func(value);
  }

  function handleClientChange(value){
    setTenantId(value);
    storeClientNameInMemory(value)
  }

  const [modal, setModal] = useState({
    isOpen : false,
    content : null,
    title : '',
    didMount : null,
    flatten : false
  })


  const cellStyle = {
    maxWidth: "280px",
    lineBreak: "anywhere",
  };

  const nameStyle = {
    margin: "0",
    fontFamily: "Tondo, Helvetica Neue, Helvetica, Arial, sans-serif",
  };

    useEffect(() => {

    let tenants = localStorage.getItem(TENANTS)

    if (typeof tenants === "undefined" || tenants.length === 0) {
      setResponse({ msg: "Seems Like you don't have sufficient access", isError: true })
      return;
    }

    if(typeof tenants != String)
      tenants = tenants.split(',')

    if(tenantList.length === 0 && tenants.length !==0){
      setTenantList(tenants)
      handleClientChange(tenants[0])
    }

    if(typeof tenantId === "undefined" || tenantId === ""){ 
      return
    }
      getFeatureControlRuleList({tenantId,ruleId})
      .then(res=>{
        setFeatureControlRuleList(res.data);
        setResponse({msg:"", isError:false})
        const uniqueRuleIds = [];
        res.data.map(rule => {
          if (uniqueRuleIds.indexOf(rule.ruleId) === -1) {
            uniqueRuleIds.push(rule.ruleId)
          }
          return 0
        })
        setRuleIds(uniqueRuleIds);
      })
      .catch(err=>{
        // let msg = err && err
        let msg = err && err.response && err.response.data ? err.response.data.error : "Error getting Feature Control Rules"
        
        setResponse({msg:msg, isError:true})
        setFeatureControlRuleList([])
      })
    return () => {
      
    }
  }, [tenantId,ruleId]);

  function toggleModal(title = '', content = '', callback, flatten = false){
    setModal({
        isOpen : !modal.isOpen,
        title : title,
        content : content,
        flatten,
        didMount : callback
    })
  }


  function initDeleteProperties(id) {
    const confirmation = window.confirm('Are you sure you want to delete?');

    if(confirmation){
        deleteFeatureControlRuleById(id, tenantId)
        .then( response => {
          let existingFeatureControlRule = featureControlRuleList.filter(audience => (audience.id !== id));
            setFeatureControlRuleList(existingFeatureControlRule)
        })
        .catch( err => {
         
            if(!err.response.data.success){
                const message = err.response.data.error;
                
                setResponse({msg:message, isError:true})
            }
        })
    }
  }


  function renderFeatureControlRuleRow(content,index ){
    return (
      <div className={`divTableRow`} key={content.ruleId+index}>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{content.ruleId}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{content.description}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{content.clientId}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <span 
            className="text-info underline cursor-pointer"
            onClick={() => toggleModal("Feature Control Rule", content, null, true)}
          >View</span> 
          &emsp;
          <Link to={{ pathname :'/editfeaturecontrol-rule/' + content.id , query:{tenantId:tenantId}}} className="text-warning underline" state={{tenantId:tenantId}}>Edit</Link>
          &emsp;
          <span 
            className="text-info underline cursor-pointer"
            onClick={() => toggleModal("Feature Control Rule Audits", 
            <AuditGroup id = {content.id} tenantId={tenantId} getAudits={getFeatureControlRuleAuditsById}/>
            )}
          >Audits</span> 
          &emsp;
          <span 
          className="text-danger underline cursor-pointer"
          onClick={()=>initDeleteProperties(content.id)}
          >Delete</span> 
          &emsp;
        </div>
      </div>
    );
  }
 
  return (
    <>
      {modal.isOpen && 
      <Modal 
          flatten={modal.flatten}
          title={modal.title} 
          btnHandler={toggleModal}
          closeTrigger={toggleModal}
          content={modal.content}
          didMount={modal.didMount}
      >
      </Modal>}
     
      <div className="row m-0 mt-0 mr-20">
        <div className="row">
        <div className="col-sm-3">
            <fieldset className="pl-20">
              <label className="fs-14 color-dark-gray">Search RuleId</label>
              <Typeahead
                id="feature-search"
                onChange={e => handleChange(setRuleId, e[0])}
                options={ruleIds}
              />
            </fieldset>
          </div>
          <div className="col-sm-3">
            <fieldset className="pl-20">
              <label className="fs-14 color-dark-gray">Tenants</label>
              <form>
                <select 
                  name = 'tenantId' 
                  id='tenantId' 
                  value={tenantId} 
                  onChange={e=> handleClientChange(e.target.value)} 
                  className="fs-16" >
                  {/* <option value="" >All Tenants</option> */}
                  {tenantList.map(tenantId => 
                  <option 
                  key={tenantId} 
                  value={tenantId}>
                      {tenantId}
                  </option>)}
                </select>  
              </form>
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-0 mb-20">
          <div className="col-md-12">
          <h5 className="h5 text-center" style={{"textAlign":"center"}}>Feature Control Rules</h5>
            <div className="divTable divTableHover mt-20">
              <div className="divTableHeading">
                <div className="divTableRow">
                  <div className="divTableHead">Rule Id</div>
                  <div className="divTableHead">Description</div>
                  <div className="divTableHead">ClientId</div>
                  <div className="divTableHead">Actions</div>
                </div>
              </div>
              <div className="divTableBody">
                {featureControlRuleList && 
                featureControlRuleList.map((featureControlRule, index)=>renderFeatureControlRuleRow(featureControlRule, index))}
              </div>
            </div>
          </div>
        </div>  
      </div>
      <div className="row m-0 mt-30 mr-20 vertical-text-center">
        <div className="col-md-9 text-center">
          <div className={response.isError ? "text-danger" : "text-success"}>
            <b>
              <h4>{response.msg}</h4>
            </b>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewFeatureControlRule
