import React, { useState, useEffect } from 'react'
import { deleteRecommendationsById, getAdRecommendationAuditsById, getAdRecommendations } from './adrecommendation.service'
import Modal from '../Common Components/Modal';
import { Link } from 'react-router-dom';
import { TENANTS } from '../constants/AdtechConstants';
import { AdTypes,PlatformList } from '../constants/AdConstants';
import { Typeahead } from 'react-bootstrap-typeahead';
import { storeClientNameInMemory } from '../utils/adtech.utils';
import AuditGroup from '../Audits/AuditGroup';


function ViewAdRecommendations() {
  const [adRecommendations, setAdRecommendations] = useState([]);
  const [response, setResponse] = useState({ msg: "", isError: false });
  const [clientName, setClientName] = useState("")
  const [os, setOs] = useState("");
  const [adType, setAdType] = useState("");
  const [type, setType] = useState("");
  const [types, setTypes] = useState([]);
  const [clientNameList, setClientNameList] = useState([]);



  const [modal, setModal] = useState({
    isOpen: false,
    content: null,
    title: '',
    didMount: null,
    flatten: false
  })


  const cellStyle = {
    maxWidth: "280px",
    lineBreak: "anywhere",
  };

  const nameStyle = {
    margin: "0",
    fontFamily: "Tondo, Helvetica Neue, Helvetica, Arial, sans-serif",
  };

  function handleChange(func, value) {
    func(value);
  }

  useEffect(() => {

    let tenants = localStorage.getItem(TENANTS)

    if (typeof tenants === "undefined" || tenants.length === 0) {
      setResponse({ msg: "Seems Like you don't have sufficient access", isError: true })
      return;
    }

    if (typeof tenants != String)
      tenants = tenants.split(',')

    if (clientNameList.length === 0 && tenants.length !== 0) {
      setClientName(`clientName=${tenants[0]}`)
      setClientNameList([...tenants])

      setOs(`os=${PlatformList[0]}`)
    }


    if (typeof clientName === "undefined" || clientName === "") {
      return;
    }

    storeClientNameInMemory(clientName.split('=')[1]);

    getAdRecommendations(adType, clientName, os, type)
      .then(res => {
        setAdRecommendations(res.data);
        setResponse({ isError: false, msg: "" })
        const uniqueKeys = [];
        res.data.map(property => {
          if (uniqueKeys.indexOf(property.type) === -1) {
            uniqueKeys.push(property.type)
          }
          return 0
        })
        setTypes(uniqueKeys);
      })
      .catch(err => {
        setAdRecommendations([])
        let msg = err && err.response && err.response.data ? err.response.data.error : "Error Fetching Ad Recommendations";
        setResponse({ isError: true, msg: msg })
      })

    return () => {

    }
  }, [adType, type, clientName, os, clientNameList.length]);

  function toggleModal(title = '', content = '', callback, flatten = false) {
    setModal({
      isOpen: !modal.isOpen,
      title: title,
      content: content,
      flatten,
      didMount: callback
    })
  }

  function initDeleteRecommendations(id) {
    const confirmation = window.confirm('Are you sure you want to delete?');

    if (confirmation) {
      deleteRecommendationsById(id)
        .then(response => {
          let existingRecommendations = adRecommendations.filter(adrecommendation => (adrecommendation.id !== id));
          setAdRecommendations(existingRecommendations)
        })
        .catch(err => {
          let msg = err && err.response && err.response.data ? err.response.data.error : "Unable to Delete";
          setResponse({msg:msg, isError:true})
        })
    }
  }
  function renderAdRecommendationRow(content, index) {
    return (
      <div className={`divTableRow`} key={content.type + index}> 
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{content.type}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{content.adId}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{content.priority}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{String(content.recommended)}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{content.adType}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <span
            className="text-info underline cursor-pointer"
            onClick={() => toggleModal("Ad Recommendation", content, null, true)}>View</span>
          &emsp;
          <Link to={'/editrecommendation/' + content.id} className="text-warning underline">Edit</Link>
          {/* &emsp; */}
          <span
            className="text-info underline cursor-pointer"
            onClick={() => toggleModal("Ad Recommendation Audits",
              <AuditGroup id={content.id} getAudits={getAdRecommendationAuditsById} />
            )}
          >Audits</span>
          <span
            className="text-danger underline cursor-pointer"
            onClick={() => initDeleteRecommendations(content.id)}
          >Delete</span>
          &emsp;
        </div>
      </div>
    );
  }

  return (
    <>
      {modal.isOpen &&
        <Modal
          flatten={modal.flatten}
          title={modal.title}
          btnHandler={toggleModal}
          closeTrigger={toggleModal}
          content={modal.content}
          didMount={modal.didMount}
        >
        </Modal>}
      <div>
        <div className="m-0 mt-30 mr-20">
          <div className="row">
            <div className="col-sm-3">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray">Search Recommendation Type</label>
                <Typeahead
                  id="type-search"
                  onChange={e => handleChange(setType, e[0])}
                  options={types}
                />
              </fieldset>
            </div>
         
            <div className="col-sm-3">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray">Client Name</label>
                <form>
                  <select
                    name='clientName'
                    id='clientName'
                    value={clientName}
                    onChange={e => handleChange(setClientName, e.target.value)}
                    className="fs-16" >
                    {clientNameList.map(clientName =>
                      <option
                        key={clientName}
                        value={`clientName=${clientName}`}>
                        {clientName}
                      </option>)}
                  </select>
                </form>
              </fieldset>
            </div>
            <div className="col-sm-3">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray"> OS</label>
                <form>
                  <select
                    name='os'
                    id='os'
                    value={os}
                    onChange={e => handleChange(setOs, e.target.value)}
                    className="fs-16" > 
                    {PlatformList.map(os =>
                      <option key={os} value={`os=${os}`}>
                        {os}
                      </option>)}
                  </select>
                </form>
              </fieldset>
            </div>
            <div className="col-sm-3">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray"> Ad Type</label>
                <form>
                  <select
                    name='adType'
                    id='adType'
                    value={adType}
                    onChange={e => handleChange(setAdType, e.target.value)}
                    className="fs-16" >
                    <option value="" >Select type</option>
                    {Object.keys(AdTypes).map(type =>
                      <option key={type} value={`adType=${type}`}>
                        {type}
                      </option>)}
                  </select>
                </form>
              </fieldset>
            </div>
          </div>
        </div>
        <div className="m-0 mt-30 mr-20">
          <div className="row">
            <div className="col-sm-12">
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 mt-0 mr-20">
        <div className="row m-0 mt-0 mb-20">
          <div className="col-md-12">
            <h5 className="h5 text-center" style={{ "textAlign": "center" }}>Ad Recommendations</h5>
            <div className="divTable divTableHover mt-20">
              <div className="divTableHeading">
                <div className="divTableRow">
                  <div className="divTableHead">Recommendation Type</div>
                  <div className="divTableHead">Ad Id</div>
                  <div className="divTableHead">Priority</div>
                  <div className="divTableHead">Recommended</div>
                  <div className="divTableHead">Ad Type</div>
                  <div className="divTableHead">Actions</div>
                </div>
              </div>
              <div className="divTableBody">
                {adRecommendations &&
                  adRecommendations.map((adrecommendation, index) => renderAdRecommendationRow(adrecommendation, index))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 mt-30 mr-20 vertical-text-center">
        <div className="col-md-9 text-center">
          <div className={response.isError ? "text-danger" : "text-success"}>
            <b>
              <h4>{response.msg}</h4>
            </b>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewAdRecommendations
