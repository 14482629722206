import React, {useState, useEffect} from 'react'
import Modal from '../Common Components/Modal';
import {getPendingUserAccessRequestList, rejectRequestedTenantAccessForUserId, approveTenantAccessRequestForUserId} from '../Authorization/authorization.service'
import { TENANTS } from '../constants/AdtechConstants';

function PendingAccessRequests() {
  const [pendingAccessRequestList, setPendingAccessRequestList] = useState([]);
  const [response, setResponse] = useState({ msg: "", isError: false });

  const [tenantId, setTenantId] = useState("")
  const [tenantList, setTenantList] = useState([])


  const [modal, setModal] = useState({
    isOpen : false,
    content : null,
    title : '',
    didMount : null,
    flatten : false
  })

  function handleChange(func, value){
    func(value);
  }

  const cellStyle = {
    maxWidth: "280px",
    lineBreak: "anywhere",
  };

  const nameStyle = {
    margin: "0",
    fontFamily: "Tondo, Helvetica Neue, Helvetica, Arial, sans-serif",
  };
  useEffect(() => {
    let tenants = localStorage.getItem(TENANTS).split(',')
    // setTenantList(tenants)
    let currentTenantId = tenantId;
    if(tenants.length > 0 && tenantId === "")
      currentTenantId = tenants[0]
    // if(tenants.length > 0 && tenantId === "")
    // setTenantId(tenants[0])

    getPendingUserAccessRequestList(currentTenantId)
    .then(res=>{
      setPendingAccessRequestList(res.data);
    })
    .then(res=>{
      setTenantId(currentTenantId)
      setTenantList(tenants)
      setResponse({msg:"", isError:false})
    })
    .catch(err=>{
      setPendingAccessRequestList([])
      setTenantList(tenants)
      let msg = err && err.response && err.response.data ? err.response.data.message : "Error getting pending Access Request Details"
      
      setResponse({msg:msg, isError:true})
    })
    return () => {
      
    }
  }, [tenantId]);

  function toggleModal(title = '', content = '', callback, flatten = false){
    setModal({
        isOpen : !modal.isOpen,
        title : title,
        content : content,
        flatten,
        didMount : callback
    })
  }
  function approveAccess(userId, tenantId) {
    const confirmation = window.confirm('Are you sure you want to approve?');

    if(confirmation){
        approveTenantAccessRequestForUserId(userId, tenantId)
        .then( response => {
          let existingPendingAccessRequestList = pendingAccessRequestList.filter(userRole => (userRole.userId !== userId));
            setPendingAccessRequestList(existingPendingAccessRequestList)
        })
        .catch( err => {
         
            if(!err.response.data.success){
                const message = err.response.data.error;
                setResponse({msg:message, isError:true})
            }
        })
    }
  }

  function rejectAccessRequest(userId, tenantId) {
    const confirmation = window.confirm('Are you sure you want to reject?');

    if(confirmation){
        rejectRequestedTenantAccessForUserId(userId, tenantId)
        .then( response => {
          let existingPendingAccessRequestList = pendingAccessRequestList.filter(userRole => (userRole.userId !== userId));
          setPendingAccessRequestList(existingPendingAccessRequestList)
        })
        .catch( err => {
        
            if(!err.response.data.success){
                const message = err.response.data.error;
                setResponse({msg:message, isError:true})
            }
        })
    }
  }


  function renderRequestAccessRow(content,index ){
    return (
      <div className={`divTableRow`} key={content.id+index}>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{content.userId}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{content.tenantId}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{content.roleId}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <span 
          className="text-info underline cursor-pointer"
          onClick={() => toggleModal("User Access Details", content, null, true)}>View</span> 
          &emsp;
          {/* <Link to={'/edit-adclient/' + content.id} className="text-warning underline">Edit</Link>
          &emsp; */}
          <span 
          className="text-warning underline cursor-pointer"
          onClick={()=>approveAccess(content.userId, content.tenantId)}
          >Approve</span> 
          &emsp;
          <span 
          className="text-danger underline cursor-pointer"
          onClick={()=>rejectAccessRequest(content.userId, content.tenantId)}
          >Delete</span> 
          &emsp;
        </div>
      </div>
    );
  }
 
  return (
    <>
      {modal.isOpen && 
      <Modal 
          flatten={modal.flatten}
          title={modal.title} 
          btnHandler={toggleModal}
          closeTrigger={toggleModal}
          content={modal.content}
          didMount={modal.didMount}
      >
      </Modal>}
     
      <div className="row m-0 mt-0 mr-20">
      <div className="row">
          <div className="col-sm-6">
            <fieldset className="pl-20">
              <label className="fs-14 color-dark-gray">Tenants</label>
              <form>
                <select 
                  name = 'tenantId' 
                  id='tenantId' 
                  value={tenantId} 
                  onChange={e=> handleChange(setTenantId, e.target.value)} 
                  className="fs-16" >
                  {/* <option value="" >All Tenants</option> */}
                  {tenantList.map(tenantId => 
                  <option 
                  key={tenantId} 
                  value={tenantId}>
                      {tenantId}
                  </option>)}
                </select>  
              </form>
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-0 mb-20">
          <div className="col-md-12">
          <h5 className="h5 text-center" style={{"textAlign":"center"}}>Pending Access Requests</h5>
            <div className="divTable divTableHover mt-20">
              <div className="divTableHeading">
                <div className="divTableRow">
                  <div className="divTableHead">User Id</div>
                  <div className="divTableHead">Tenant Id</div>
                  <div className="divTableHead">Role Id</div>
                  <div className="divTableHead">Actions</div>
                </div>
              </div>
              <div className="divTableBody">
                {pendingAccessRequestList && 
                pendingAccessRequestList.map((accessRequest, index)=>renderRequestAccessRow(accessRequest, index))}
              </div>
            </div>
          </div>
        </div>  
      </div>
      <div className="row m-0 mt-30 mr-20 vertical-text-center">
        <div className="col-md-9 text-center">
          <div className={response.isError ? "text-danger" : "text-success"}>
            <b>
              <h4>{response.msg}</h4>
            </b>
          </div>
        </div>
      </div>
    </>
  )
}

export default PendingAccessRequests
