import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <div className="Sidebar" to={item.path} onClick={item.subNav && showSubnav}>
        <div>
          {item.icon}
          <span>{item.title}</span>
        </div>
      </div>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <Link className="Dropdown"  to={item.path} key={item.key}>
              <span style={{ "marginLeft": "16px", "fontSize":"15px"}}>{item.title}</span>
            </Link>
          );
        })}
    </>
  );
};

export default SubMenu;
