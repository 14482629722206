import {useForm} from 'react-hook-form';
import React , {useRef, useEffect, useState} from 'react';
import { getSlotPrioritiesById, saveSlotPriorities } from './slotPriorites.service';
import CreatableForm from '../Common Components/CreatableForm';
import { NON_EMPTY_STRING_PATTERN } from '../constants/RegularExpressions';
import { PlatformList } from '../constants/AdConstants';
import { TENANTS } from '../constants/AdtechConstants';
import { storeClientNameInMemory } from '../utils/adtech.utils';

function AddSlotPriority(props){
  const { register, handleSubmit, formState:{errors}, reset, control } = useForm({mode:"onSubmit"
  });
  const id = useRef(props.match.params.id);
  const [response, setResponse] = useState({ msg: "", isError: false });
  const [clientNameList, setClientNameList] = useState([])

  const resetObj = {
    "slotName":"", 
    "sourcePriorityList":[], 
    "clientName":'',
    "os":''
  }
 
  const prepareOptionsListFromValues = (values) => {
    if(typeof values === "undefined")
      return []

    return values.reduce((optionsList, value) =>{
      optionsList = [...optionsList, {label:value, value:String(value)}]
      return optionsList
    },[])
  }

  const prepareEditForm = (data) => {
    data = {...data, sourcePriorityList:prepareOptionsListFromValues(data.sourcePriorityList)}
    reset(data)
  }

  useEffect(()=>{

    let tenants = localStorage.getItem(TENANTS)
    if (typeof tenants === "undefined" || tenants.length === 0) {
      setResponse({ msg: "Seems Like you don't have sufficient access", isError: true })
      return;
    }
    if (typeof tenants != String)
      tenants = tenants.split(',')
    setClientNameList(tenants);


    if(typeof id.current !=="undefined" && id.current !== null){
      getSlotPrioritiesById(id.current)
      .then(res=>{
        prepareEditForm(res.data)
      })
      .catch(err=>{
        let msg = err.response && err.response.data
        ? err.response.data.message
        : "Unknown"
        setResponse({msg:msg, isError:true})
      })
    }
  },[id]);

  const prepareSubmitData = (data) => {
    return data.sourcePriorityList.map(item=>item.value)
  }

  function onSubmit(data, ev){
    ev.preventDefault()
    let method = typeof id.current !=="undefined" && id.current !== null ? "PUT" : "POST"
    data.id = id.current
    data = {...data, sourcePriorityList:prepareSubmitData(data)}
    storeClientNameInMemory(data.clientName)
    saveSlotPriorities(data, method)
    .then(res=>{
      setResponse({msg:"Submitted Successfully", isError:false})
      reset({resetObj})
      props.history.replace("/editpriority");
      // reset({})
      id.current = null 
    })
    .catch(err=>{
      setResponse({msg:"Error:" + err.response && err.response.data && err.response.data.error, isError:true})
    })
  }

  const errorRequiredField= (message="This field is required") => {
    return(<small className="text-danger">{message}</small>)
  } 

  return (
    <>
      <div className="center-block" style={{textAlign:'center'}}>
        <h4>Slot Source Priority Form</h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="ClientName">
                Client Name
              </label>
              <select
                name="clientName"
                className="fs-14"
                {...register('clientName', { required: true })}
              >
                <option value="">Select a Client</option>
                {clientNameList.map((clientName) => (
                  <option value={clientName.value} key={clientName.value}>
                    {clientName}
                  </option>
                ))}
              </select>
              {!!errors.clientName   && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="OS">
                OS
              </label>
              <select
                name="os"
                className="fs-14"
                {...register('os', { required: true })}
              >
                <option value="">Select a OS</option>
                {PlatformList.map(os => 
                      <option key={os} value={os}>
                          {os}
                      </option>)}
              </select>
              {!!errors.os && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="AdSlotId">
                Slot Name
              </label>
              <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('slotName',{required:true})}
                placeholder="Enter Ad Slot Name"
                pattern={NON_EMPTY_STRING_PATTERN}
                title="Please enter a non blank string. Terminal whitespaces are not allowed"
                maxLength="50"
              />
              {errors.slotName && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6"> 
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="slotSourcePriority">
               Enter Source in Priority Order
              </label>
              <CreatableForm 
                control={control} 
                register={register} 
                name="sourcePriorityList" 
                placeholder ="Source Priorities List" 
                required={true} 
              />
              {errors.sourcePriorityList && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20 vertical-text-center">
          <div className="col-md-9 text-center">
            <div className={response.isError ? "text-danger" : "text-success"}>
              <b>
                <h4>{response.msg}</h4>
              </b>
            </div>
          </div>
          <div className="col-md-3">
            <input className="btn btn-success btn-md pull-right" type="submit" />
          </div>
        </div>
      </form>
    </>
  )
}

export default AddSlotPriority;