import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { loginUser } from '../user.service';
import "./login.css"

import {parseJwt} from "../../utils/CommonUtility"
import {ACCESS_TOKEN, USER_ID, USER_INFO, TENANTS} from "../../constants/AdtechConstants"

function Login(props) {

  const [userId, setuserId] = useState("");
  const [password, setPassword] = useState("");
  const [response, setResponse] = useState({ msg: "", isError: false });
  const history = useHistory();

  if (props.from === 'app') {
    localStorage.clear();
  }
  async function login() {
    let item = { userId, password };
    let body = JSON.stringify(item)

    loginUser(body)
      .then(res => {
        setLocalStorageParams(res);
        
        history.push("/adconfig")
      })
      .catch(err => {
        if (typeof err.response !== "undefined")
          setResponse({ msg: err.response.data.error, isError: true })
        else
          setResponse({ msg: "Oops! Something is wrong", isError: true })
      })
  }

  const setLocalStorageParams = (res) => {
    localStorage.setItem(USER_INFO, JSON.stringify(res.data));
    localStorage.setItem(USER_ID, userId)
    localStorage.setItem(ACCESS_TOKEN,res.headers.jwttoken)
    let decodedToken = parseJwt(res.headers.jwttoken)
    let tenants = typeof decodedToken.authorities !== "undefined" ? Object.keys(decodedToken.authorities): [];
   
    localStorage.setItem(TENANTS, tenants);
  }
  return (
    <div>

      <div className="login-box">
        <h2>Login</h2>
        <div className='user-box'>
          <input type="text" onChange={(e) => setuserId(e.target.value)} className="form-control" />
          <label>UserId</label>
          <br />
        </div>
        <div className='user-box'>
          <input type="password" onChange={(e) => setPassword(e.target.value)} className="form-control" />
          <label>Password</label>
          <br />
        </div>
        <div className={response.isError ? "text-danger" : "text-danger"}>
          <b>
            <h4>{response.msg}</h4>
          </b>
        </div>
        <button onClick={login} className="btn button-22"> Login </button>
        <div className="row bottom-tab">
          <div className="col-md-2"></div>
          
          <div className="col-md-4">
            <Link to="/register">Register</Link>
          </div>

          {/* <div className="col-md-4">
            <Link to="/updateCredential">Forgot Password</Link>
          </div> */}
          
          <div className="col-md-2"></div>
        </div>
      </div>
    </div>
  )
}



export default Login
