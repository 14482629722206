import React, { useState } from "react";
import { useForm } from "react-hook-form";
import CreatableForm from "../Common Components/CreatableForm";

const PublisherConfig = (props) => {
    const { register, control, errors, errorRequiredField } = props;

    return (
        <>
           
           <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14" htmlFor="publisherName">
            Name
            </label>
            <input
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register('publisherName',{required:false})}
              placeholder="Enter Publisher Name"
            />
            {errors.publisherName && errorRequiredField()}
          </fieldset>
        </div>

        <div className="col-md-6">
            <fieldset className="pl-20">
                        <label className="fs-14" htmlFor="publisherCat">
                            cat
                        </label>
                        <CreatableForm
                            control={control}
                            register={register}
                            name="publisherCat"
                            placeholder="Enter cats"
                            required={false}
                        />
                        {errors.publisherCat && errorRequiredField()}
             </fieldset>
        </div>
        </div>

        <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 " htmlFor="publisherDomain">
            Domain
            </label>
            <input
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register('publisherDomain',{required:false})}
              placeholder="Enter Publisher Domain"
            />
            {errors.publisherName && errorRequiredField()}
          </fieldset>
          </div>

          <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14" htmlFor="pubExt">
              ext
            </label>
            <input
              className="fs-14"
              name="pubExt"
              type="text"
              autoComplete="off"
              {...register('pubExt',{required:false})}
              placeholder="Enter ext"
            />
            {errors.pubExt && errorRequiredField()}
          </fieldset>
          </div>
        </div>
        </>
    )
}

export default PublisherConfig;