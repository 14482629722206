import moment from "moment";
import { CLIENT_NAME } from "../constants/AdtechConstants";

export function getFormattedDateFromEpoch(inputDate) {
  let outDate = moment(inputDate).format("YYYY-MM-DD H:mm:ss");

  return outDate;
}

export function storeClientNameInMemory(clientName){
  localStorage.setItem(CLIENT_NAME, clientName);
}