import { ajax } from "../utils/ajax";
import { AD_CONFIG_ENDPOINT, NEW_AD_CONFIG_ENDPOINT } from "../Common Components/service.urls";

export const getAdConfigs = (adSlotId = '', clientId, adType, adState, os) => {
  let query = typeof adSlotId !== 'undefined' && adSlotId !=='' ? `slotId=${adSlotId}&` : '';
  query += typeof adType !== 'undefined' && adType !== '' ? `${adType}&` : '';
  query += typeof os !== 'undefined' && os !== '' ? `${os}&` : '';
  query += typeof adState !== 'undefined' && adState !== '' ? `${adState}` : '';

  return ajax({
    method:"get",
    url:`${AD_CONFIG_ENDPOINT}/list?${query}`
  })
}

export const getAdConfigById = (id) => {

  return ajax({
    method:"get",
    url:`${AD_CONFIG_ENDPOINT}/${id}`
  })
}

export const toggleAdSate = (adId) => {
    return ajax({
      method : "PUT",
      params : {
        id: adId
      },
      url:`${AD_CONFIG_ENDPOINT}/toggleAdState`
    })
}


export const saveAdConfig = (adConfig ,method) => {
  return ajax({
    method : method,
    data : adConfig ,
    url : `${AD_CONFIG_ENDPOINT}`
  })
}

export const deleteConfigById = (id) => {

  return ajax({
    method:"delete",
    params : {
      id
    },
    url:`${AD_CONFIG_ENDPOINT}`
  })
}

export const getAdConfigAuditsById = (id) => {
  return ajax({
    method : "get",
    params :{
      adConfigId: id
    },
    url : `${AD_CONFIG_ENDPOINT}/audits`

  })
}
// TODO NEW ad config
export const saveNewAdConfig = (adConfig ,method) => {
  return ajax({
    method : method,
    data : adConfig ,
    url : `${NEW_AD_CONFIG_ENDPOINT}`
  })
}
export const getNewAdConfigs = (adSlotId = '', clientId, adType, adState, os) => {
  let query = typeof adSlotId !== 'undefined' && adSlotId !=='' ? `slotId=${adSlotId}&` : '';
  query += typeof adType !== 'undefined' && adType !== '' ? `${adType}&` : '';
  query += typeof os !== 'undefined' && os !== '' ? `${os}&` : '';
  query += typeof adState !== 'undefined' && adState !== '' ? `${adState}` : '';

  return ajax({
    method:"get",
    url:`${NEW_AD_CONFIG_ENDPOINT}/list?${query}`
  })
}
export const newToggleAdSate = (adId) => {
  return ajax({
    method : "PUT",
    params : {
      id: adId
    },
    url:`${NEW_AD_CONFIG_ENDPOINT}/toggleAdState`
  })
}
export const getNewAdConfigAuditsById = (id) => {
  return ajax({
    method : "get",
    params :{
      newSlotConfigId: id
    },
    url : `${NEW_AD_CONFIG_ENDPOINT}/audits`

  })
}
export const deleteNewConfigById = (id) => {

  return ajax({
    method:"delete",
    params : {
      newSlotConfigId: id
    },
    url:`${NEW_AD_CONFIG_ENDPOINT}`
  })
}
export const getNewAdConfigById = (id) => {

  return ajax({
    method:"get",
    url:`${NEW_AD_CONFIG_ENDPOINT}/${id}`,
    params : {
      newSlotConfigId: id
    },
  })
}