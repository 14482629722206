import React from 'react';
import CreatableForm from '../../Common Components/CreatableForm';


function VideoMetaForm(props){
  const {register, control, errors , errorRequiredField} = props;
  return (
    <>
      <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 required-field" htmlFor="AdSlotId">
              Ad Unit Id
            </label>
            <input
              name="adUnitId"
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register('adUnitId',{required:true})}
              placeholder="Enter Ad Unit Id"
              // pattern="[a-zA-Z0-9\.\-_\s]{1,50}"
              title="Allowed values are numbers, alphabets, spaces, '.', '-', '_'"
              maxLength="500"
            />
            {errors.adUnitId && errorRequiredField()}
          </fieldset>
        </div>
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 required-field" htmlFor="AdSlotId">
              Frequency
            </label>
            <input
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register('freqInterval',{required:true})}
              placeholder="Enter Frequency Interval"
              pattern="[0-9]{1,50}"
              title="Allowed values are numbers, alphabets, spaces, '.', '-', '_'"
              maxLength="50"
            />
            {errors.freqInterval && errorRequiredField()}
          </fieldset>
        </div>
      </div>
      <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 required-field" htmlFor="AdSlotId">
             Companion Banner Width
            </label>
            <input
              name="companionBannerWidth"
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register('companionBannerWidth',{required:true})}
              placeholder="Enter Companion Banner Width"
              pattern="[0-9]{1,50}"
              title="Allowed values are numbers"
              maxLength="50"
            />
            {errors.companionBannerWidth && errorRequiredField()}
          </fieldset>
        </div>
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 required-field" htmlFor="AdSlotId">
            Companion Banner Height
            </label>
            <input
              name="companionBannerHeight"
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register('companionBannerHeight',{required:true})}
              placeholder="Enter Companion Banner Height"
              pattern="[0-9]{1,50}"
              title="Allowed values are numbers"
              maxLength="50"
            />
            {errors.companionBannerHeight && errorRequiredField()}
          </fieldset>
        </div>
      </div>
      <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6"> 
          <fieldset className="pl-20">
          <label className="fs-14" htmlFor="AdSlotId">
              Blacklisted Cps
            </label>
          
            <CreatableForm 
              control={control} 
              register={register} 
              name="blackListedCps" 
              placeholder ="Blacklisted Cps" 
              required={false} 
            />
            </fieldset>
        </div>
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14" htmlFor="AdSlotId">
              Targeted
            </label>
            <select
              name="targeted"
              className="fs-14"
              {...register('targeted',{required:true})}>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
            {errors.targeted && errorRequiredField()}
          </fieldset>
        </div>
      </div>
    </>
  )
}

export default VideoMetaForm;