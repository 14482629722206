import { useForm } from 'react-hook-form';
import React, { useRef, useEffect, useState } from 'react';
import { getAdServerConfigById, saveAdServerConfig } from './adserverconfig.service';

import { AD_SERVER_URL_PATTERN, NON_EMPTY_STRING_PATTERN, PERCENTAGE_PATTERN } from '../constants/RegularExpressions';
import { TENANTS } from '../constants/AdtechConstants';
import { PlatformList } from '../constants/AdConstants';
import { storeClientNameInMemory } from '../utils/adtech.utils';

function CreateAdServerConfig(props) {
  const { register, handleSubmit, formState: { errors }, reset, control } = useForm({ mode: "onChange" });
  const id = useRef(props.match.params.id);
  const [response, setResponse] = useState({ msg: "", isError: false });
  const [clientIdList, setClientIdList] = useState([])
  const [isVmaxAdServer,setIsVmaxAdServer] = useState(false)

  const resetObj = {
    accountId:"",
    clientName:"",
    os:"",
    url:"",
    urlPercentage:"",
    alternateUrl:"",
    name:"",
    videoAdUrl:""
  };


  useEffect(() => {

    let tenants = localStorage.getItem(TENANTS)

    if (typeof tenants === "undefined" || tenants.length === 0) {
      setResponse({ msg: "Seems Like you don't have sufficient access", isError: true })
      return;
    }
    if (typeof tenants != String)
      tenants = tenants.split(',')
    setClientIdList(tenants)
    if (typeof id.current !== "undefined" && id.current !== null) {
      getAdServerConfigById(id.current)
      .then(res => {
        reset(res.data)
        manageFlagForVmaxServer(res.data.name);
    
      })
      .catch(err => {
        let msg = err && err.response && err.response.data ? err.response.data.error : "Error Getting Ad Server Details";
        setResponse({msg:msg, isError:true})
      })
    }

  }, [id]);

  function onSubmit(data, ev) {
    ev.preventDefault()
    setResponse({ msg: "", isError: false })
    let method = typeof id.current !== "undefined" && id.current !== null ? "PUT" : "POST"
    data.id = id.current
    storeClientNameInMemory(data.clientName)
    if(!isVmaxAdServer){
      data.videoAdUrl = undefined;
    }

    saveAdServerConfig(data, method)
      .then(res => {
        setResponse({ msg: "Submitted Successfully", isError: false })
        reset(resetObj)
        props.history.replace("/editserver");
        id.current = null

      })
      .catch(err => {
        setResponse({ msg: "Error:" + err.response.data.error, isError: true })
      })

  }

 const onChangeCaptureHandler = (e) => {

        var adServerName = e.target.value;

        manageFlagForVmaxServer(adServerName);

    };

    function manageFlagForVmaxServer(adServerName){

      
          if(adServerName==null || adServerName == undefined || adServerName === '')
               setIsVmaxAdServer(false);

              var data = adServerName.toLowerCase();

              if(data.indexOf('vmax') != -1){
                setIsVmaxAdServer(true);
              }
              else{
                setIsVmaxAdServer(false);
              }
    }

   const  isVmax = (message = "") => {
   return !isVmaxAdServer?'':(<div className='col-md-6' >
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="videoAdUrl">
                Video Ad Url
              </label>
              <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('videoAdUrl', { required: false })}
                placeholder="Enter Video Ad Url"
                pattern={AD_SERVER_URL_PATTERN}
                title="Please enter a valid videoAdUrl"
                maxLength="100"
              />
               {!errors.videoAdUrl} 
            </fieldset>
          </div>)

  }



  const errorRequiredField = (message = "This field is required") => {
    return (<small className="text-danger">{message}</small>)
  }

  return (
    <>
      <div className="center-block" style={{ textAlign: 'center' }}>
        <h4>Ad Server Config Form</h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="ClientName">
                Client Name
              </label>
              <select
                name="clientName"
                className="fs-14"
                {...register('clientName', { required: true })}
              >
                <option value="">Select a Client</option>
                {clientIdList.map((clientName) => (
                  <option value={clientName.value} key={clientName.value}>
                    {clientName}
                  </option>
                ))}
              </select>
              {!!errors.clientName && errorRequiredField()}
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="OS">
                OS
              </label>
              <select
                name="os"
                className="fs-14"
                // disabled={typeof id.current !== "undefined" && id.current !== null}
                {...register('os', { required: true })}
              >
                <option value="">Select a OS</option>
                {PlatformList.map(os =>
                  <option key={os} value={os}>
                    {os}
                  </option>)}
              </select>
              {!!errors.os && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="AdSlotId">
                Ad Server Name
              </label>
                 <input onChangeCapture={onChangeCaptureHandler}
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('name', { required: true })}
                placeholder="Enter Ad Server Name"
                pattern={NON_EMPTY_STRING_PATTERN}
                title="Please enter a non-blank string( whitespace are not allowed in the beginning or the end)"
                maxLength="50"
                control={control}
              />
              {errors.name && errorRequiredField()}
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                Account Id
              </label>
              <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('accountId', { required: false })}
                placeholder="Enter Account Id"
                // pattern="[a-zA-Z0-9\.\-_]{1,50}"
                pattern={NON_EMPTY_STRING_PATTERN}
                title="Please enter a non-blank string"
                maxLength="50"
              />
              {/* {!!errors.accountId && errorRequiredField()} */}
            </fieldset>
          </div>
        </div>
       
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="AdSlotId">
                Url
              </label>
              <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('url', { required: true })}
                placeholder="Enter Get Ad Url"
                pattern={AD_SERVER_URL_PATTERN}
                title="Please enter a valid url"
                maxLength="250"
              />
              {errors.url && errorRequiredField()}
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AlternateUrl">
                Alternate Url
              </label>
              <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('alternateUrl', { required: false })}
                placeholder="Enter Alternate Url"
                pattern={AD_SERVER_URL_PATTERN}
                title="Please enter a valid url"
                maxLength="250"
              />
              {/* {!!errors.alternateUrl && errorRequiredField()} */}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="UrlPercentage">
                Url Percentage
              </label>
              <input
                name="urlPercentage"
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('urlPercentage', { required: false })}
                placeholder="Enter Url Percentage"
                pattern={PERCENTAGE_PATTERN}
                title="Allowed values are numbers"
                maxLength="3"
              />
              {/* {errors.urlPercentage && errorRequiredField()} */}
            </fieldset>
          </div>
          {isVmax()}
        </div>

        <div className="row m-0 mt-30 mr-20 vertical-text-center">
          <div className="col-md-9 text-center">
            <div className={response.isError ? "text-danger" : "text-success"}>
              <b>
                <h4>{response.msg}</h4>
              </b>
            </div>
          </div>
          <div className="col-md-3">
            <input className="btn btn-success pull-right" type="submit" />
          </div>
        </div>
      </form>
    </>
  )
}

export default CreateAdServerConfig;
