import React, {useState, useEffect} from 'react'
import { Typeahead } from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom';
import AuditGroup from '../Audits/AuditGroup';
import Modal from '../Common Components/Modal';
import { PlatformList } from '../constants/AdConstants';
import { TENANTS } from '../constants/AdtechConstants';
import { storeClientNameInMemory } from '../utils/adtech.utils';
import { deleteServerConfigById, getAdServerAuditsById, getAdServerConfigsList } from './adserverconfig.service';

function ViewAdServerConfigs() {
  const [adServerConfigsList, setAdServerConfigsList] = useState([]);
  const [response, setResponse] = useState({ msg: "", isError: false });
  const [clientName, setClientName] = useState("")
  const [os, setOs] = useState("");
  const [name, setName] = useState("");
  const [names, setNames] = useState([]);
  const [clientNameList, setClientNameList] = useState([]);


  const [modal, setModal] = useState({
    isOpen : false,
    content : null,
    title : '',
    didMount : null,
    flatten : false
  })


  const cellStyle = {
    maxWidth: "200px",
    // lineBreak: "anywhere",
    // overflowWrap: "break-word",
    wordWrap: "break-word"
  };

  const urlStyle = {
    margin: "0",
    fontFamily: "Tondo, Helvetica Neue, Helvetica, Arial, sans-serif",
    overflow : "auto",
    whiteSpace :"normal"
  }
  const nameStyle = {
    margin: "0",
    fontFamily: "Tondo, Helvetica Neue, Helvetica, Arial, sans-serif",
  };

  function handleChange(func, value) {
    func(value);
  }

  useEffect(() => {

    let tenants = localStorage.getItem(TENANTS)

    if (typeof tenants === "undefined" || tenants.length === 0) {
      setResponse({ msg: "Seems Like you don't have sufficient access", isError: true })
      return;
    }

    if (typeof tenants != String)
      tenants = tenants.split(',')

    if (clientNameList.length === 0 && tenants.length !== 0) {
      setClientName(`clientName=${tenants[0]}`)
      setClientNameList([...tenants])

      setOs(`os=${PlatformList[0]}`)
    }


    if (typeof clientName === "undefined" || clientName === "") {
      return;
    }
    
    storeClientNameInMemory(clientName.split('=')[1]);

    getAdServerConfigsList(name, clientName, os)
    .then(res=>{
      setAdServerConfigsList(res.data);
      setResponse({ isError: false, msg: "" })
      const uniqueKeys = [];
      res.data.map(server => {
        if (uniqueKeys.indexOf(server.name) === -1) {
          uniqueKeys.push(server.name)
        }
        return 0
      })
      setNames(uniqueKeys);
      setResponse({isError:false, msg:""})
    })
    .catch(err=>{
      let msg = err.response.data.error;
      setResponse({ isError: true, msg: msg }) 
      setAdServerConfigsList([])
    })
    return () => {
      
    }
  }, [name, clientName, os]);

  function toggleModal(title = '', content = '', callback, flatten = false){
    setModal({
        isOpen : !modal.isOpen,
        title : title,
        content : content,
        flatten,
        didMount : callback
    })
  }


  function initDeleteServer(id) {
    const confirmation = window.confirm('Are you sure you want to delete?');

    if(confirmation){
        deleteServerConfigById(id)
        .then( response => {
          let existerServerConfigs = adServerConfigsList.filter(serverConfig => (serverConfig.id !== id));
            setAdServerConfigsList(existerServerConfigs)
        })
        .catch( err => {
          let msg = err && err.response && err.response.data ? err.response.data.error : "Unable to Delete";
          setResponse({msg:msg, isError:true})
        })
    }
  }


  function renderAdServerRow(content,index ){
    return (
      <div className={`divTableRow`} key={content.name+index}>
         <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{content.name}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{content.accountId}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={urlStyle}>{content.url}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={urlStyle}>{content.alternateUrl}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{content.urlPercentage}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <span 
          className="text-info underline cursor-pointer"
          onClick={() => toggleModal("Server Configuration Details", content, null, true)}>View</span> 
          &emsp;
          <Link to={'/editserver/' + content.id} className="text-warning underline">Edit</Link>
          {/* &emsp; */}
          <span
            className="text-info underline cursor-pointer"
            onClick={() => toggleModal("Ad Server Audits",
              <AuditGroup id={content.id} getAudits={getAdServerAuditsById} />
            )}
          >Audits</span>
          <span 
          className="text-danger underline cursor-pointer"
          onClick={()=>initDeleteServer(content.id)}
          >Delete</span> 
          &emsp;
        </div>
      </div>
    );
  }
 
  return (
    <>
      {modal.isOpen && 
      <Modal 
          flatten={modal.flatten}
          title={modal.title} 
          btnHandler={toggleModal}
          closeTrigger={toggleModal}
          content={modal.content}
          didMount={modal.didMount}
      >
      </Modal>}

      <div>
        <div className="m-0 mt-30 mr-20">
          <div className="row">
            <div className="col-sm-4">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray">Search Name</label>
                <Typeahead
                  id="name-search"
                  onChange={e => handleChange(setName, e[0])}
                  options={names}
                />
              </fieldset>
            </div>
            <div className="col-sm-4">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray">Client Name</label>
                <form>
                  <select
                    name='clientName'
                    id='clientName'
                    value={clientName}
                    onChange={e => handleChange(setClientName, e.target.value)}
                    className="fs-16" >
                    {clientNameList.map(clientName =>
                      <option
                        key={clientName}
                        value={`clientName=${clientName}`}>
                        {clientName}
                      </option>)}
                  </select>
                </form>
              </fieldset>
            </div>
            <div className="col-sm-4">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray"> OS</label>
                <form>
                  <select
                    name='os'
                    id='os'
                    value={os}
                    onChange={e => handleChange(setOs, e.target.value)}
                    className="fs-16" > 
                    {PlatformList.map(os =>
                      <option key={os} value={`os=${os}`}>
                        {os}
                      </option>)}
                  </select>
                </form>
              </fieldset>
            </div>
          </div>
        </div>
        <div className="m-0 mt-30 mr-20">
          <div className="row">
            <div className="col-sm-12">
            </div>
          </div>
        </div>
      </div>
     
      <div className="row m-0 mt-0 mr-20">
        <div className="row m-0 mt-0 mb-20">
          <div className="col-md-12">
          <h5 className="h5 text-center" style={{"textAlign":"center"}}>Ad Server Configs</h5>
            <div className="divTable divTableHover mt-20">
              <div className="divTableHeading">
                <div className="divTableRow">
                  <div className="divTableHead">Name</div>
                  <div className="divTableHead">AccountId</div>
                  <div className="divTableHead">URL</div>
                  <div className="divTableHead">Alternate Url</div>
                  <div className="divTableHead"> Url <br/>Percentage</div>
                  <div className="divTableHead">Actions</div>
                </div>
              </div>
              <div className="divTableBody">
                {adServerConfigsList && 
                adServerConfigsList.map((slotPriority, index)=>renderAdServerRow(slotPriority, index))}
              </div>
            </div>
          </div>
        </div>  
      </div>
      <div className="row m-0 mt-30 mr-20 vertical-text-center">
        <div className="col-md-9 text-center">
          <div className={response.isError ? "text-danger" : "text-success"}>
            <b>
              <h4>{response.msg}</h4>
            </b>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewAdServerConfigs
