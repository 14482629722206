import { ajax } from "../utils/ajax";
import { BLACKLISTED_OFFER_ID_ENDPOINT } from "../Common Components/service.urls";

export const getOfferIdsList = ({clientName, offerId}) => {
  offerId = typeof offerId === "undefined" ? '' : offerId
  return ajax({
    method:"get",
    url:`${BLACKLISTED_OFFER_ID_ENDPOINT}/list?offerId=${offerId}`
  })
}

export const getadsOfferBlacklistedId = (id) => {
  return ajax({
    method:"get",
    params:{
      id
    },
    url:`${BLACKLISTED_OFFER_ID_ENDPOINT}`
  })
}

export const saveadsOfferBlacklistedId= (adsOfferBlacklisted ,method) => {
  return ajax({
    method : method,
    data : adsOfferBlacklisted ,
    url : `${BLACKLISTED_OFFER_ID_ENDPOINT}`
  })
}
export const deleteOfferId = (id) => {

  return ajax({
    method:"delete",
    url:`${BLACKLISTED_OFFER_ID_ENDPOINT}/${id}`
  })
}

export const getBlackListedOfferIdsAuditsById = (id) => {
  return ajax({
    method : "get",
    params :{
      offerBlackListedId: id
    },
    url : `${BLACKLISTED_OFFER_ID_ENDPOINT}/audits`

  })
}