import React, {  useEffect } from 'react'

function Logout(props) {
  
  useEffect(() => {
    localStorage.clear();
    window.location.href = "/";
   
  }, []);


  return (
    <>
    </>
  )
}

export default Logout
