import React, { useEffect, useState } from 'react'
import { deleteConfigById, getAdConfigAuditsById, getAdConfigs, toggleAdSate } from './adconfig.service'
import Modal from '../Common Components/Modal';
import { Typeahead } from 'react-bootstrap-typeahead';
import { AdTypes } from '../constants/AdConstants'
import { Link } from 'react-router-dom';
import { TENANTS } from '../constants/AdtechConstants';
import { PlatformList } from '../constants/AdConstants'
import { storeClientNameInMemory } from '../utils/adtech.utils';
import AuditGroup from '../Audits/AuditGroup';


function ViewAdConfig() {

  const [adConfigsList, setAdConfigsList] = useState([]);
  const [adIds, setAdIds] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [adState, setAdState] = useState("")
  const [clientName, setClientName] = useState("")
  const [adSlotId, setAdSlotId] = useState("")
  const [adType, setAdType] = useState("");
  const [os, setOs] = useState("");
  const [toggle, setToggle] = useState(true);
  const [response, setResponse] = useState({ msg: "", isError: false });

  const [modal, setModal] = useState({
    isOpen: false,
    content: null,
    title: '',
    didMount: null,
    flatten: false
  })

  useEffect(() => {

    let tenants = localStorage.getItem(TENANTS)

    if(typeof tenants === "undefined" || tenants.length === 0){
      setResponse({msg:"Seems Like you don't have sufficient access", isError:true})
      return;
    }

    if (typeof tenants != String)
      tenants = tenants.split(',')

    if (clientList.length === 0 && tenants.length !== 0) {
      setClientList(tenants)
      setClientName(`clientName=${tenants[0]}`)
      setOs(`os=${PlatformList[0]}`)
    }

    if (typeof clientName === "undefined" || clientName === "" || typeof os === "undefined" || os === "") {
      return;
    }
    storeClientNameInMemory(clientName.split('=')[1]);
    getAdConfigs(adSlotId, clientName, adType, adState, os)
      .then(res => {
        setAdConfigsList(res.data);
        setResponse({ isError: false, msg: "" })
        const uniqueAdIds = [];
        res.data.map(adconfig => {
          if (uniqueAdIds.indexOf(adconfig.adId) === -1) {
            uniqueAdIds.push(adconfig.adId)
          }
          return 0
        })
        setAdIds(uniqueAdIds);
      })
      .catch(err => {
        setAdConfigsList([])
        let msg = err && err.response && err.response.data ? err.response.data.error : "Error Fetching Ad Config Details";
        setResponse({ isError: true, msg: msg })
      })


  }, [adState, adType, adSlotId, clientName, toggle, os, clientList.length]);

  function handleChange(func, value) {
    func(value);
  }

  const cellStyle = {
    maxWidth: "280px",
    lineBreak: "anywhere",
  };

  const nameStyle = {
    margin: "0",
    fontFamily: "Tondo, Helvetica Neue, Helvetica, Arial, sans-serif",
  };

  function toggleModal(title = '', content = '', callback, flatten = false) {
    setModal({
      isOpen: !modal.isOpen,
      title: title,
      content: content,
      flatten,
      didMount: callback
    })
  }

  function toggleState(id) {
    toggleAdSate(id)
      .then(res => {
        setToggle(!toggle)
      })
      .catch(err => {
        let msg = err && err.response && err.response.data ? err.response.data.error : "Unable to toggle state";
        setResponse({ msg: msg, isError: true })
      })

  }

  function initDeleteConfig(id) {
    const confirmation = window.confirm('Are you sure you want to delete?');

    if (confirmation) {
      deleteConfigById(id)
        .then(response => {
          let existingConfig = adConfigsList.filter(adconfig => (adconfig.id !== id));
          setAdConfigsList(existingConfig)
        })
        .catch(err => {
          let msg = err && err.response && err.response.data ? err.response.data.error : "Unable to Delete";
          setResponse({ msg: msg, isError: true })

        })
    }
  }


  function renderAdConfigRow(adconfig, index) {

    return (
      <div className={`divTableRow`} key={adconfig.adId + index}>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{adconfig.adId}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{adconfig.adState}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{adconfig.adType}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <span
            className="text-info underline cursor-pointer"
            onClick={() => toggleModal('AdSlot Configuration', adconfig, null, true)}>View</span>
          &emsp;
          <Link to={"/editadconfig/" + adconfig.id} className="text-warning underline">Edit</Link>
          &emsp;
          <span
            className="text-info underline cursor-pointer"
            onClick={() => toggleState(adconfig.id)}
          >Toggle</span>
          &emsp;
          <span
            className="text-warning underline cursor-pointer"
            onClick={() => toggleModal("Ad Config Audits",
              <AuditGroup id={adconfig.id} getAudits={getAdConfigAuditsById} />
            )}
          >Audits</span>
          &emsp;
          <span
            className="text-danger underline cursor-pointer"
            onClick={() => initDeleteConfig(adconfig.id)}
          >Delete</span>
           &emsp;
          <Link to={{ pathname: '/editadconfig/', state: { adconfig: adconfig } }} className="text-info underline">
            Clone
          </Link>

        </div>
      </div>
    );
  }

  return (
    <>
      {modal.isOpen &&
        <Modal
          flatten={modal.flatten}
          title={modal.title}
          btnHandler={toggleModal}
          closeTrigger={toggleModal}
          content={modal.content}
          didMount={modal.didMount}
        >
        </Modal>}
      <div>
        <div className="m-0 mt-20 mr-20">

          <div className="row">
            <div className="col-sm-3">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray">Client Name</label>
                <form>
                  <select
                    name='clientName'
                    id='clientName'
                    value={clientName}
                    onChange={e => handleChange(setClientName, e.target.value)}
                    className="fs-16" >
                    {clientList.map(clientName =>
                      <option
                        key={clientName}
                        value={`clientName=${clientName}`}>
                        {clientName}
                      </option>)}
                  </select>
                </form>
              </fieldset>
            </div>
            <div className="col-sm-3">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray"> OS</label>
                <form>
                  <select
                    name='os'
                    id='os'
                    value={os}
                    onChange={e => handleChange(setOs, e.target.value)}
                    className="fs-16" >
                    {PlatformList.map(os =>
                      <option key={os} value={`os=${os}`}>
                        {os}
                      </option>)}
                  </select>
                </form>
              </fieldset>
            </div>
            <div className="col-sm-3">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray"> Ad Type</label>
                <form>
                  <select
                    name='adType'
                    id='adType'
                    value={adType}
                    onChange={e => handleChange(setAdType, e.target.value)}
                    className="fs-16" >
                    <option value="" >Select type</option>
                    {Object.keys(AdTypes).map(type =>
                      <option key={type} value={`adType=${type}`}>
                        {type}
                      </option>)}
                  </select>
                </form>
              </fieldset>
            </div>
            <div className="col-sm-3">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray">Ad State</label>
                <form>
                  <select
                    className="fs-16"
                    onChange={ev => handleChange(setAdState, ev.target.value)}
                  >
                    <option value="">All</option>
                    <option value={`adState=ACTIVE_STATE`}>Active</option>
                    <option value={`adState=INACTIVE_STATE`}>InActive</option>
                  </select>
                </form>
              </fieldset>
            </div>
          </div>
          <div className="row  mt-20">
            <div className="col-sm-4">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray">Search AdId</label>
                <Typeahead
                  id="campaign-search"
                  onChange={e => handleChange(setAdSlotId, e[0])}
                  options={adIds}
                />
              </fieldset>
            </div>
          </div>
        </div>
        <div className="m-0 mt-30 mr-20">
          <div className="row">
            <div className="col-sm-12">
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 mt-0 mr-20">
        <div className="row m-0 mt-0 mb-20">
          <div className="col-md-12">
            <div className="divTable divTableHover mt-20">
              <div className="divTableHeading">
                <div className="divTableRow">
                  <div className="divTableHead">AdId</div>
                  <div className="divTableHead">Ad State</div>
                  <div className="divTableHead">Ad Type</div>
                  <div className="divTableHead">Actions</div>
                </div>
              </div>
              <div className="divTableBody">
                {adConfigsList && adConfigsList.map((adconfig, index) => renderAdConfigRow(adconfig, index))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 mt-30 mr-20 vertical-text-center">
        <div className="col-md-9 text-center">
          <div className={response.isError ? "text-danger" : "text-success"}>
            <b>
              <h4>{response.msg}</h4>
            </b>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewAdConfig
