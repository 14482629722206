import { GET_ALL_USER_ENDPOINT, SAVE_CREDENTIALS_ENDPOINT, UPDATE_CREDENTIALS_ENDPOINT, USER_APPROVAL_ENDPOINT, USER_LOGIN_ENDPOINT } from "../Common Components/service.urls";
import { ajax } from "../utils/ajax";

export const saveUserDetails = (data, method, endPoint) => {
    return ajax({
        method: method,
        data: data,
        url: endPoint
    })
}

export const saveUserCredentials = (data) => {
    return ajax({
        method: 'POST',
        data: data,
        url: `${SAVE_CREDENTIALS_ENDPOINT}`
    })
}


export const updateUserCredential = (data,endPoint) => {
    return ajax({
        method: 'PUT',
        data: data,
        url: endPoint,
        headers:{
            "auth-token": localStorage.getItem('authToken')
        }
    })
}

export const userApproval = (userId, isApproved) => {
    return ajax({
        method: 'POST',
        params: {
            userId: userId,
            userApproval: isApproved
        },
        url: `${USER_APPROVAL_ENDPOINT}`,
        headers:{
            "auth-token": localStorage.getItem('authToken')
        }
    })
}

export const getAllUsers = () => {
    return ajax({
        method: 'GET',
        url: `${GET_ALL_USER_ENDPOINT}`,
        headers:{
            "auth-token": localStorage.getItem('authToken')
        }
    })
}

export const loginUser = (data) => {
    return ajax({
        method: 'POST',
        data: data,
        url: `${USER_LOGIN_ENDPOINT}`
    })
} 