import React, { useState, useEffect } from 'react';
import PublisherConfig from './PublisherConfig';
import { BooleanFlag } from '../constants/AdConstants';


function AppConfig(props) {

  const [publisher, setPublisher] = useState(false)

  const {
    register,
    control,
    errors,
    watch,
    handleChange,
    errorRequiredField
  } = props;

  function addPublisher() {
    setPublisher(true)
  }

  useEffect(() => {
    if (props.publisher) {
      addPublisher()
    }
  }, [])

  const renderDependentPublisherFormFields = (publisher) => {

    const Form = PublisherConfig;
    return (
      <Form
        control={control}
        register={register}
        errors={errors}
        errorRequiredField={errorRequiredField}
        handleChange={handleChange}
        watch={watch}
      />
    );
  };

  return (
    <>
      <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 required-field" htmlFor="appName">
              Name
            </label>
            <input
              name="appName"
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register('appName', { required: true })}
              placeholder="Enter name"
              // pattern="[a-zA-Z0-9\.\-_\s]{1,50}"
              title="Allowed values are numbers, alphabets, spaces, '.', '-', '_'"
              maxLength="500"
            />
            {errors.appName && errorRequiredField()}
          </fieldset>
        </div>
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 required-field" htmlFor="appBundle">
              Bundle
            </label>
            <input
              className="fs-14"
              name="appBundle"
              type="text"
              autoComplete="off"
              {...register('appBundle', { required: true })}
              placeholder="Enter Bundle"
            />
            {errors.appBundle && errorRequiredField()}
          </fieldset>
        </div>
      </div>

      <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 required-field" htmlFor="appDomain">
              Domain
            </label>
            <input
              name="appDomain"
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register('appDomain', { required: true })}
              placeholder="Enter Domain"
              title="Allowed values are numbers, alphabets, spaces, '.', '-', '_'"
              maxLength="500"
            />
            {errors.appDomain && errorRequiredField()}
          </fieldset>
        </div>
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14" htmlFor="storeUrl">
              Store URL
            </label>
            <input
              className="fs-14"
              name="storeUrl"
              type="text"
              autoComplete="off"
              {...register('storeUrl', { required: false })}
              placeholder="Enter store Url"
            />
            {errors.storeUrl && errorRequiredField()}
          </fieldset>
        </div>
      </div>

      <div className="row m-0 mt-30 mr-20">

        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 " htmlFor="privacyPolicy">
              Privacy Policy
            </label>
            <select
              name="privacyPolicy"
              className="fs-14"
              {...register('privacyPolicy', { required: false })}>
              <option value="">Select Privacy Policy</option>
              {BooleanFlag.map((flag) => (
                <option value={flag} key={flag}>
                  {flag}
                </option>
              ))}
            </select>
            {errors.privacyPolicy && errorRequiredField()}
          </fieldset>
        </div>
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14" htmlFor="appExt">
              ext
            </label>
            <input
              className="fs-14"
              name="appExt"
              type="text"
              autoComplete="off"
              {...register('appExt', { required: false })}
              placeholder="Enter ext"
            />
            {errors.appExt && errorRequiredField()}
          </fieldset>
        </div>
      </div>

      <div className="row m-0 mt-30 mr-20">
      </div>
      <button name="publisher"  {...register("publisher")} className="bg-red btn btn-small btn-add  mt-20 ml-30" type="button" onClick={() => addPublisher()}>
        Add Publisher
      </button>
      {publisher && renderDependentPublisherFormFields("publisher")}

    </>
  )
}

export default AppConfig;