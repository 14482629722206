import Axios from "axios";
import { AUTHENTICATE_TOKEN_ENDPOINT } from "../../modules/Common Components/service.urls";
import { ACCESS_TOKEN } from "../../modules/constants/AdtechConstants";

export const checkAuthentication = ()=>{
    let auth = localStorage.getItem("user-info");
    if(typeof auth == "undefined" || auth === null || auth==="false"){
        return false;
    }
    else{
       
        Axios({
            method: 'get',
            url: AUTHENTICATE_TOKEN_ENDPOINT,
            headers: {
                'Content-Type': 'application/json',
                 'auth-token': localStorage.getItem(ACCESS_TOKEN)
            }
        }).then( response=>{
                return true;
        })
        .catch(() => {
            localStorage.clear()
            return false;
        });
    }
        return true;
}
