import React from 'react';

function SourceConfig(props) {

  const { register, control, errors, errorRequiredField } = props;

  return (
    <>

      <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 " htmlFor="fd">
              Final Impression
            </label>
            <input
              name="fd"
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register('fd', { required: false })}
              placeholder="Enter final impression"
              pattern="^\d*$"
              title="Allowed values are numbers"
              maxLength="500"
            />
            {errors.fd && errorRequiredField()}
          </fieldset>
        </div>
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14" htmlFor="transactionId">
              Transaction ID
            </label>
            <input
              className="fs-14"
              name="transactionId"
              type="text"
              autoComplete="off"
              {...register('transactionId', { required: false })}
              placeholder="Enter transaction Id"
            />
            {errors.transactionId && errorRequiredField()}
          </fieldset>
        </div>
      </div>

      <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 " htmlFor="sourceExt">
              ext
            </label>
            <input
              className="fs-14"
              name="sourceExt"
              type="text"
              autoComplete="off"
              {...register('sourceExt', { required: false })}
              placeholder="Enter ext"
            />
            {errors.sourceExt && errorRequiredField()}
          </fieldset>
        </div>
      </div>


    </>
  )
}

export default SourceConfig;