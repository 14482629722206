import { ajax } from "../utils/ajax";
import { FEATURE_CONTROL_RULE_ENDPOINT } from "../Common Components/service.urls";

export const getFeatureControlRuleList = ({audienceIds, tenantId,ruleId}) => {
  
  audienceIds = typeof audienceIds === "undefined" ? audienceIds: audienceIds.toString()
  return ajax({
    method:"get",
    params:{
      "featureControlRuleIds" : audienceIds,
      tenantId : tenantId,
      ruleId:ruleId
    },
    url:`${FEATURE_CONTROL_RULE_ENDPOINT}/list`
  })
}


export const deleteFeatureControlRuleById = (id, tenantId) => {

  return ajax({
    method:"delete",
    params:{
      tenantId:tenantId
    },
    url:`${FEATURE_CONTROL_RULE_ENDPOINT}/${id}`
  })
}

export const saveFeatureControlRule = (audience ,method) => {
  return ajax({
    method : method,
    data : audience ,
    params:{
      tenantId: audience.clientId[0]
    },
    url : `${FEATURE_CONTROL_RULE_ENDPOINT}`
  })
}

export const getFeatureControlRuleById = (id, tenantId) => {
  return ajax({
    method : "get",
    params:{
      tenantId:tenantId
    },
    url : `${FEATURE_CONTROL_RULE_ENDPOINT}/${id}`
  })
}

export const getFeatureControlRuleAuditsById = (id, tenantId) => {
  return ajax({
    method : "get",
    params :{
      featureControlRuleId: id,
      "tenantId":tenantId
    },
    url : `${FEATURE_CONTROL_RULE_ENDPOINT}/audits`

  })
}