import { ajax } from "../utils/ajax";
import { FEATURE_CONTROL_ENDPOINT } from "../Common Components/service.urls";

export const getFeatureControlList = (tenantId,featureId,featureState) => {
  return ajax({
    method:"get",
    params:{
      tenantId:tenantId,
      featureId:featureId,
      featureState:featureState
    },
    url:`${FEATURE_CONTROL_ENDPOINT}/list`
  })
}


export const deleteFeatureControlById = (id,tenantId) => {

  return ajax({
    method:"delete",
    params:{
      tenantId:tenantId
    },
    url:`${FEATURE_CONTROL_ENDPOINT}/${id}`
  })
}

export const saveFeatureControl = (featureControl ,method) => {
  
  return ajax({
    method : method,
    params:{
      tenantId:featureControl.clientId
    },
    data : featureControl ,
    url : `${FEATURE_CONTROL_ENDPOINT}`
  })
}

export const getFeatureControlById = (id, tenantId) => {
  return ajax({
    method : "get",
    params:{
      tenantId:tenantId
    },
    url : `${FEATURE_CONTROL_ENDPOINT}/${id}`
  })
}

export const getFeatureControlAuditsById = (id, tenantId) => {
  return ajax({
    method : "get",
    params :{
      featureControlId: id,
      "tenantId":tenantId
    },
    url : `${FEATURE_CONTROL_ENDPOINT}/audits`

  })
}