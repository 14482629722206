import React from 'react';
import CreatableForm from '../Common Components/CreatableForm';
import { useFieldArray } from 'react-hook-form';

const APSConfig = (props) => {
    const { register, control, errors, errorRequiredField } = props;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "adSizes",
    });

    return (
        <>
            <div className="row m-0 mt-30 mr-20">
                <div className="col-md-6">
                    <fieldset className="pl-20">
                        <label className="fs-14 required-field" htmlFor="source">
                            Header Bidding Source
                        </label>
                        <input
                            className="fs-14"
                            type="text"
                            name="source"
                            autoComplete="off"
                            defaultValue="APS"
                            readOnly
                            {...register('source', {
                                required: true,
                                setValueAs: (value) => value.toUpperCase()
                            })}
                        />
                        {!!errors.source && errorRequiredField()}
                    </fieldset>
                </div>
                <div className="col-md-6">
                    <fieldset className="pl-20">
                        <label className="fs-14 required-field" htmlFor="adUnitIds">
                            Ad Unit Ids
                        </label>
                        <CreatableForm
                            control={control}
                            register={register}
                            name="adUnitIds"
                            placeholder="Ad Unit Ids"
                            required={true}
                        />
                        {errors.adUnitIds && errorRequiredField()}
                    </fieldset>
                </div>
            </div>

            <div className="row m-0 mt-30 mr-20">
                <div className="col-md-6">
                    <fieldset className="pl-20">
                        <label className="fs-14" htmlFor="slotAdType">
                            Slot Ad Type
                        </label>
                        <select
                            className="fs-14"
                            {...register("slotAdType", {
                                required: false,
                                validate: (value) => value !== "Select a Slot Ad Type",
                            })}
                        >
                            <option value="">Select a Slot Ad Type</option>
                            <option value="BANNER">BANNER</option>
                            <option value="INTERSTITIAL">INTERSTITIAL</option>
                            <option value="AUDIO">AUDIO</option>
                            <option value="VIDEO">VIDEO</option>
                        </select>
                        {!!errors.slotAdType && errorRequiredField()}
                    </fieldset>
                </div>
            </div>

            {fields.map((field, index) => (
                <div key={field.id + 1}>
                    <div className="row m-0 mt-30 mr-20 field-btn">
                        <div className="col-md-6">
                            <fieldset className="pl-20">
                                <label className="fs-14 " htmlFor="adsWidth">
                                    Ads Width
                                </label>
                                <input
                                    className="fs-14"
                                    type="text"
                                    autoComplete="off"
                                    {...register(`adSizes.${index}.width`, { required: false })}
                                    placeholder="Enter Companion Banner Width"
                                    pattern="[0-9]+"
                                    title="Allowed values are numbers"

                                />
                                {errors.adSizes && errorRequiredField()}
                            </fieldset>
                        </div>
                        <div className="col-md-6">
                            <fieldset className="pl-20">
                                <label className="fs-14 " htmlFor="adsHeight">
                                    Ads Height
                                </label>
                                <input
                                    className="fs-14"
                                    type="text"
                                    autoComplete="off"
                                    {...register(`adSizes.${index}.height`, { required: false })}
                                    placeholder="Enter Companion Banner Height"
                                    pattern="[0-9]+"
                                    title="Allowed values are numbers"
                                />
                                {!!errors.adSizes && errorRequiredField()}
                            </fieldset>
                        </div>
                    </div>
                    {<button className="btn btn-small btn-remove pull-right mt-20" type="button" onClick={() => remove(index)}>
                        Remove  field
                    </button>}
                </div>
            ))}
            <button className="bg-red btn btn-small btn-add  mt-20 ml-30" type="button" onClick={() => append({})}>
                Add Ads Size
            </button>
        </>
    )
}

export default APSConfig;