import React, { useState } from "react";
import TabManager from "../Common Components/TabManager";
import ApprovedRequests from "./ApprovedRequests";
import PendingAcessRequests from "./PendingAccessRequests";


const TABS = [
  { label: "Approved Access", value: "approved" },
  { label: "Pending Requests", value: "pendingRequests" },
];

function ActiveForm(component) {
  const [activeTab, setActiveTab] = useState("approved");
  const changeTab = (value) => {
    setActiveTab(value)
  }
  return (
    <div>
      <TabManager
        tabs={TABS}
        activeTab={activeTab}
        setActiveTab={changeTab}
      />
      <div> {component[activeTab]}</div>
    </div>
  );
}

const ViewAuthorizationDetails = () => {

  const component = {
    approved : (
      <ApprovedRequests />
    ),
    pendingRequests: (
      <PendingAcessRequests />
    ),
  };


  return (
    <div> 
      <div style={{ position: "relative" }}>
        {ActiveForm(component)}
      </div>
    </div>
  );
};

export default ViewAuthorizationDetails;
