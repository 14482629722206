import React from 'react';
import Select from 'react-select';
import {Controller} from 'react-hook-form';

function SelectForm(props){
  const {control, register , name , placeholder, options, required, isMulti, disabled} = props;
  return (
    <>
      <Controller
      control={control}
      refs={register}
      name={name}
      defaultValue={[]}
      options={[]}
      rules={{ required: required }}
      render ={({field})=>
        <Select {...field}
          placeholder={placeholder}
          options={options}
          isClearable
          closeMenuOnSelect
          isMulti={isMulti}
          isDisabled={disabled}
      />
      }
      />
  </>
  )
}

export default SelectForm;