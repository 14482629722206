import { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Navbar from './screens/Shared/Navbar';
import './App.css'
import './resources/css/campaign-portal.css'
import ViewAdConfig from './modules/AdConfig/ViewAdConfig';
import ViewSlotPriorities from './modules/SlotPriorites/ViewSlotPriorities';
import ViewAdProperties from './modules/AdProperties/ViewAdProperties';
import ViewAdRecommendations from './modules/AdRecommendations/ViewAdRecommendations';
import CreateAdConfig from './modules/AdConfig/CreateAdConfig'
import CreateAdRecommendations from './modules/AdRecommendations/CreateAdRecommendations';
import CreateAdProperties from './modules/AdProperties/CreateAdProperties';
import "./polyfills"
import AddSlotPriority from './modules/SlotPriorites/AddSlotPriority';
import ViewAdServerConfigs from './modules/Ad Servers/ViewAdServerConfigs';
import ViewAdClients from './modules/AdClients/ViewAdClients';
import CreateAdClient from './modules/AdClients/CreateAdClient';
import CreateAdServerConfig from './modules/Ad Servers/CreateAdServerConfig';
import ViewBlackListedOfferIds from './modules/BlackListedOfferIds/ViewBlackListedOfferIds';
import AddOfferIdsBlacklisted from './modules/BlackListedOfferIds/AddOfferIdsBlacklisted';
import ViewFeatureControl from './modules/FeatureControls/ViewFeatureControls';
import CreateFeatureControl from './modules/FeatureControls/AddFeatureControls';
import ViewFeatureControlRule from './modules/FeatureControlRule/ViewFeatureControlRule';
import CreateFeatureControlRule from './modules/FeatureControlRule/CreateFeatureControlRule';
import { checkAuthentication } from './screens/Shared/checkAuthentication';
import UpdateCredential from './modules/User/User Credentials/UpdateCredential';
import RegisterUser from './modules/User/RegisterUser/RegisterUser';
import Logout from './modules/User/Logout';

import CreateAccessRequest from './modules/Authorization/CreateAccessRequest';
import ViewAuthorizationDetails from './modules/Authorization/ViewAuthorizationDetails';
import ViewFileProcessingStatus from './modules/PersonalizationFileProcessor/ViewFileProcessingStatus';
import CreateNewSlotConfig from './modules/NewSlotConfig/CreateNewSlotConfig'; 
import ViewNewAdConfig from './modules/NewSlotConfig/ViewNewAdConfig'; 
import CreateImpressionConfig from './modules/Impression Config/CreateImpressionConfig'; 
import ViewImpressionConfig from './modules/Impression Config/ViewImpressionConfig'; 
import CreateAdUnitConfig from './modules/AdUnit Config/CreateAdUnitConfig'; 
import ViewAdUnitConfig from  './modules/AdUnit Config/ViewAdUnitConfig'
import CreateAccount from './modules/Publisher/CreateAccount'; 
import ViewAccount from './modules/Publisher/ViewAccount'
class App extends Component {

  componentDidMount() {
    document.title = "Adtech Targeting Portal";
  }

  render() {

    if (!checkAuthentication()) {
      return <Redirect to='/login' />
    }
    return (
      <>
        <Router>
          <Navbar />
          <section className="content-body">
            <Switch >
              <Route path="/adconfig" component={ViewAdConfig} />
              <Route key="edit" path="/editadconfig/:id?" component={CreateAdConfig} />
              <Route key="create"  path="/createConfig/" component={CreateAdConfig} />
              <Route path="/slot-priority" component={ViewSlotPriorities} />
              <Route path="/editpriority/:id?" component={AddSlotPriority} />
              <Route path="/ad-recommendation" component={ViewAdRecommendations} />
              <Route path="/editrecommendation/:id?" component={CreateAdRecommendations} />
              <Route path="/ad-properties" component={ViewAdProperties} />
              <Route path="/editproperties/:id?" component={CreateAdProperties} />
              <Route path="/ad-server" component={ViewAdServerConfigs} />
              <Route path="/editserver/:id?" component={CreateAdServerConfig} />
              <Route path="/ad-client" component={ViewAdClients} />
              <Route path="/edit-adclient/:id?" component={CreateAdClient} />
              {/* <Route path="/wynk-client" component={ViewWynkClients} />
                <Route path="/edit-wynkclient/:id?" component={createWynkClient} /> */}
              <Route path="/blacklistedOfferIds" component={ViewBlackListedOfferIds} />
              <Route path="/editBlacklistedOfferIds/:id?" component={AddOfferIdsBlacklisted} />
              <Route path="/featurecontrol-rule/" component={ViewFeatureControlRule} />
              <Route path="/editfeaturecontrol-rule/:id?" component={CreateFeatureControlRule} />
              <Route path="/featurecontrol/" component={ViewFeatureControl} />
              <Route path="/editfeaturecontrol/:id?" component={CreateFeatureControl} />
              <Route path="/updateCredential" component={UpdateCredential} />
              <Route path="/updateUser" component={RegisterUser} />
              <Route path="/logout" component={Logout} />
              <Route path="/featurecontrol-rule/" component={ViewFeatureControlRule} />
              <Route path="/editfeaturecontrol-rule/:id?" render={props => <CreateFeatureControlRule  {...props} />} />
              <Route path="/featurecontrol/" component={ViewFeatureControl} />
              <Route path="/editfeaturecontrol/:id?" component={CreateFeatureControl} />
              <Route path="/access-request/:id?" component={CreateAccessRequest} />
              <Route path="/view-auth-access" component={ViewAuthorizationDetails} />
              <Route path="/view-file-status" component={ViewFileProcessingStatus} />

              {/* routes for new ad config */}
              <Route key="editNew" path="/editNewAdconfig/:id?" component={CreateNewSlotConfig} />
              <Route key="createNew" path="/createNewAdconfig/" component={CreateNewSlotConfig} />
              <Route path="/newAdconfig" component={ViewNewAdConfig} />

              {/* routes for impression config */}
              <Route key="editNewImp" path="/editImpConfig/:id?" component={CreateImpressionConfig} />
              <Route key="createImp" path="/createImp/" component={CreateImpressionConfig} />
              <Route path="/newImp" component={ViewImpressionConfig} />

               {/* routes for adUnit config */}
               <Route key="editAdUnitConfig" path="/editAdUnitConfig/:id?" component={CreateAdUnitConfig} />
              <Route key="createAdUnit" path="/createAdUnit/" component={CreateAdUnitConfig} />
              <Route path="/newAdUnit" component={ViewAdUnitConfig} />

              {/* routes for account */}
              <Route key="editAccount" path="/editAccount/:id?" component={CreateAccount} />
              <Route key="createAccount" path="/createAccount/" component={CreateAccount} />
              <Route path="/newAccount" component={ViewAccount} />

            </Switch>
          </section>
        </Router>

      </>
    );
  }
}

export default App;