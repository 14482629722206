import { useForm } from "react-hook-form";
import React, { useRef, useEffect, useState } from "react";
import { IMP_STATE } from "../constants/AdConstants";
import { useLocation } from 'react-router-dom';
import { saveImpConfig, getImpById } from "../NewSlotConfig/airtelHeaderBidding.service";
import { useFieldArray } from 'react-hook-form';
import BannerConfig from "./BannerConfig";
import VideoConfig from "./VideoConfig";
function CreateImpressionConfig(props) {

  const {
    register,
    handleSubmit,
    handleChange,
    formState: { errors },
    reset,
    watch,
    control,
  } = useForm({ mode: "onChange" });

  const location = useLocation();

  const imp = location?.state?.imp;


  const { fields: bidderFields, append, remove } = useFieldArray({
    control,
    name: "addBidder",
  });

  const id = useRef(props.match.params.id);

  const prepareOptionsListFromValues = (values) => {
    if (typeof values === "undefined") return [];
    return values.reduce((optionsList, value) => {
      optionsList = [...optionsList, { label: value, value: String(value) }];
      return optionsList;
    }, []);
  };

  const prepareNewEditForm = (data) => {
    data.impState = data.state ? data.state.toUpperCase() : null;

    if (data.ext) {

      const temporaryExt = [];
      (Object.keys(data.ext)).forEach((item) => {
        Object.keys(data.ext[item]).forEach(i => {
          const t = {};
          t.bidderName = item;
          t.bidderKey = i;
          t.bidderValue = data.ext[item][i];
          temporaryExt.push(t);
        })
      })
      data.addBidder = temporaryExt;
    }

    if (data.banner) {
      data.bannerApi = prepareOptionsListFromValues(data.banner.api);
      data.bannerMimes = prepareOptionsListFromValues(data.banner.mimes);
      data.adSizes = data.banner.format;
      setBanner(true);
    }

    if (data.video) {
      data.videoApi = prepareOptionsListFromValues(data.video.api);
      data.videoMimes = prepareOptionsListFromValues(data.video.mimes);
      data.videoWidth = data.video.w;
      data.videoHeight = data.video.h;
      setVideo(true)
    }
    return data;
  };

  const prepareEditForm = async () => {
    if (typeof id.current !== "undefined" && id.current !== null) {
      try {
        const res = await getImpById(id.current);
        return reset(prepareNewEditForm(res.data));;
      } catch (err) {
        setResponse({
          msg:
            err.response && err.response.data
              ? err.response.data.message
              : "Unknown",
          isError: true,
        });
      }
    }
  };

  const prepareFormByData = (data) => {
    return reset(prepareNewEditForm(data));
  }

  useEffect(() => {
    prepareEditForm();
  }, [id]);

  useEffect(() => {
    if (imp) {
      prepareFormByData(imp)
    }
  }, [imp]);

  const renderDependentBannerFormFields = (banner) => {

    const Form = BannerConfig;
    return (
      <Form
        control={control}
        register={register}
        errors={errors}
        errorRequiredField={errorRequiredField}
        handleChange={handleChange}
        watch={watch}
      />
    );
  };
  const renderDependentVedioFormFields = (video) => {

    const Form = VideoConfig;
    return (
      <Form
        control={control}
        register={register}
        errors={errors}
        errorRequiredField={errorRequiredField}
        handleChange={handleChange}
        watch={watch}
      />
    );
  };
  const [response, setResponse] = useState({ msg: "", isError: false });

  const [banner, setBanner] = useState(false)
  const [video, setVideo] = useState(false)

  const errorRequiredField = (message = "This field is required") => {
    return <small className="text-danger">{message}</small>;
  };

  const removeNullValues = (data) => {
    return Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v != null)
    );
  };

  const resetObj = {
    bidfloor: "",
    bidfloorcur: "USD",
    impState: "",
    videoWidth: "",
    videoHeight: "",
    addBidder : [],
    ext: {}
  };


  function onSubmit(data, ev) {
    ev.preventDefault();
    let method =
      typeof id.current !== "undefined" && id.current !== null ? "PUT" : "POST";
    data.id = id.current;

    let payload = {
      bidfloor: data.bidfloor ? Number(data.bidfloor) : null,
      bidfloorcur: data.bidfloorcur ? data.bidfloorcur : null,
      state: data.impState.toLowerCase(),
      ext: data.ext ? data.ext : null,
      tempExt: data.addBidder

    };

    if (data.id) {
      payload = {
        ...payload,
        id: data.id,
      };
    }



    if ((data.bannerMimes && data.bannerMimes.length > 0) || 
        (data.bannerApi && data.bannerApi.length>0) || (data.adSizes && data.adSizes.length>0)) {
      payload = {
        ...payload,
        banner: {
          mimes: data.bannerMimes.map((mime) => { return mime.value }),
          api: data.bannerApi.map((api) => { return Number(api.value) }),
          format: data.adSizes,
        }
      };
    }

    if ((data.videoMimes && data.videoMimes.length>0) || (data.videoApi && data.videoApi.length>0 ) || 
         data.videoWidth || data.videoHeight) {
      payload = {
        ...payload,
        video: {
          mimes: data.videoMimes.map((mime) => { return mime.value }),
          api: data.videoApi.map((api) => { return Number(api.value) }),
          w: data.videoWidth,
          h: data.videoHeight
        }
      };
    }
    const filteredData = removeNullValues(payload);
    saveImpConfig(filteredData, method)
      .then((res) => {
        setResponse({ msg: "Submitted Successfully", isError: false });
        reset({});
        reset(resetObj);
        setBanner(false);
        setVideo(false);
        props.history.replace("/editImpConfig/");
        id.current = null;
      })
      .catch((err) => {
        setResponse({ msg: "Error:" + err.response.data.error, isError: true });
      });
  }
  function addBanner() {
    setBanner(true)
  }
  function addVideo() {
    setVideo(true)
  }

  return (
    <div>
      <div className="center-block" style={{ textAlign: "center" }}>
        <h4>Impression Config </h4>
      </div>
      <form onSubmit={
        handleSubmit(onSubmit)}>

        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="bidfloor">
                Bid Floor
              </label>
              <input
                type="text"
                autoComplete="off"
                name="bidfloor"
                className="fs-14"
                pattern="([0-9]*[.])?[0-9]+"
                {...register("bidfloor", { required: false })}>
              </input>
              {!!errors.bidfloor && errorRequiredField()}
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="bidfloorcur">
                Bid Floor Currency
              </label>
              <input
                type="text"
                autoComplete="off"
                name="bidfloorcur"
                className="fs-14"
                {...register("bidfloorcur", { required: false })}
              >
              </input>
              {!!errors.os && errorRequiredField()}
            </fieldset>
          </div>
        </div>

        {/* row 1 ends here */}
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label htmlFor="impExt">
                ext
              </label>
              <input
                type="text"
                autoComplete="off"
                name="impExt"
                className="fs-14"
                {...register("impExt")}
              >
              </input>
              {!!errors.impExt && errorRequiredField()}
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="impState" >
                Imp State
              </label>
              <select
                name="impState"
                className="fs-14"
                {...register("impState", { required: true })}
              >
                <option value="">Select a Imp State</option>
                {IMP_STATE.map((state) => (
                  <option value={state} key={state}>
                    {state}
                  </option>
                ))}
              </select>
              {errors.impState && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
          <button name="addBannerBidder" className="bg-green btn btn-small btn-add  mt-20 ml-30" type="button" onClick={() => append({})}>
            Add Bidder
          </button>
        </div>
        {bidderFields.map((field, index) => (
          <div key={field.id}>
            <div className="col-md-7 pb-20 mt-20 card-border">
              <fieldset className="pl-20">
                <label className="fs-14 required-field" htmlFor="bidderName">
                  Bidder Name
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  name="bidderName"
                  className="fs-14"
                  {...register(`addBidder[${index}].bidderName`, { required: true })}>
                </input>
                {!!errors.bidderName && errorRequiredField()}

              </fieldset>
              <div className="d-flex pt-15">
                <fieldset className="pl-20">
                  <label className="fs-14 required-field" htmlFor="bidderName">
                    Bidder Key
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    name="bidderKey"
                    className="fs-14"
                    {...register(`addBidder[${index}].bidderKey`, { required: true })}
                  >
                  </input>
                  {/* {!!errors.bidderName && errorRequiredField()} */}

                </fieldset>
                <fieldset className="pl-20">
                  <label className="fs-14 required-field" htmlFor="bidderName">
                    Bidder Value
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    name="bidderValue"
                    className="fs-14"
                    {...register(`addBidder[${index}].bidderValue`, { required: true })}
                  >
                  </input>
                  {!!errors.bidderName && errorRequiredField()}

                </fieldset>
              </div>
            </div>

            {
              <button className="btn btn-small btn-remove pull-right mt-20" type="button" onClick={() => remove(index)}>
                Remove  field
              </button>}
          </div>
        ))}

        <div className="row m-0 mt-30 mr-20">
        </div>
        <button name="banner"  {...register("banner")} className="bg-green btn btn-small btn-add  mt-20 ml-30" type="button" onClick={() => addBanner()}>
          Add Banner
        </button>
        <div className="row m-0 mt-30 mr-20">

          {banner && renderDependentBannerFormFields("banner")}
          <div className="row m-0 mt-30 mr-20">
            <button name="video"  {...register("video")} className="bg-green btn btn-small btn-add  mt-20 ml-30" type="button" onClick={() => addVideo()}>
              Add Video
            </button>
          </div>
          {video && renderDependentVedioFormFields("video")}
          <div className="row m-0 mt-30 mr-20 vertical-text-center">
            <div className="col-md-9 text-center">
              <div className={response.isError ? "text-danger" : "text-success"}>
                <b>
                  <h4>{response.msg}</h4>
                </b>
              </div>
            </div>
            <div className="col-md-3 mt-60 ">
              <input className="btn btn-success pull-right" type="submit" />
            </div>
          </div>
        </div>
      </form>
    </div>
  )

}
export default CreateImpressionConfig;
