import { ajax } from "../utils/ajax";
import { AD_RECOMMENDATIONS_ENDPOINT } from "../Common Components/service.urls";

export const getAdRecommendations = (adType, clientName, os, type='') => {
  type = type !== '' ? `recommendationType=${type}` : type;

  return ajax({
    method:"get",
    url:`${AD_RECOMMENDATIONS_ENDPOINT}/list?${adType}&${os}&${type}`
  })
}

export const getAdRecommendationsById = (id) => {
  return ajax({
    method:"get",
    params:{
      id
    },
    url:`${AD_RECOMMENDATIONS_ENDPOINT}`
  })
}

export const saveAdRecommendations = (adRecommendations ,method) => {
  return ajax({
    method : method,
    data : adRecommendations ,
    url : `${AD_RECOMMENDATIONS_ENDPOINT}`
  })
}

export const deleteRecommendationsById = (id) => {

  return ajax({
    method:"delete",
    url:`${AD_RECOMMENDATIONS_ENDPOINT}/${id}`
  })
}

export const getAdRecommendationAuditsById = (id) => {
  return ajax({
    method : "get",
    params :{
      adRecommendationId: id
    },
    url : `${AD_RECOMMENDATIONS_ENDPOINT}/audits`

  })
}