import React, { useEffect, useState } from 'react'
import { getImpById, getImpConfigs, getImpConfigsByState, toggleImpState, deleteImpById, getImpConfigAuditsById } from '../NewSlotConfig/airtelHeaderBidding.service';
import Modal from '../Common Components/Modal';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom';
import AuditGroup from '../Audits/AuditGroup';


function ViewImpressionConfig() {

  const [impList, setImpList] = useState([]);
  const [impIds, setImpIds] = useState([]);
  const [impId, setImpId] = useState("");
  const [impState, setImpState] = useState("")
  const [toggle, setToggle] = useState(true);
  const [response, setResponse] = useState({ msg: "", isError: false });

  const [modal, setModal] = useState({
    isOpen: false,
    content: null,
    title: '',
    didMount: null,
    flatten: false
  })

  useEffect(() => {

    getImpConfigs()
      .then(res => {
        setImpList(res.data);
        setResponse({ isError: false, msg: "" })
        const uniqueImpIds = [];
        res.data.map(imp => {
          if (uniqueImpIds.indexOf(imp.id) === -1) {
            uniqueImpIds.push(imp.id)
          }
          return 0
        })
        setImpIds(uniqueImpIds);
      })
      .catch(err => {
        setImpList([])
        let msg = err && err.response && err.response.data ? err.response.data.error : "Error Fetching Publisher Details";
        setResponse({ isError: true, msg: msg })
      })


  }, [toggle]);

  // useEffect(() => {

  //   getImpById(impId)
  //     .then(res => {
  //       const impList = [];
  //       impList.push(res.data);
  //       setImpList(impList);
  //       setResponse({ isError: false, msg: "" })
  //       setImpIds(res.data.id);
  //     })
  //     .catch(err => {
  //       setImpList([])
  //       let msg = err && err.response && err.response.data ? err.response.data.error : "Error Fetching Publisher Details";
  //       setResponse({ isError: true, msg: msg })
  //     })


  // }, [impId]);

  function handleChange(func, value) {
    func(value);

    if(func == setImpId){
    setImpId(value)
    getImpById(value)
      .then(res => {
        const impList = [];
        impList.push(res.data);
        setImpList(impList);
        setResponse({ isError: false, msg: "" })
        const uniqueImpList = [];
        uniqueImpList.push(res.data.id);
        setImpIds(uniqueImpList);
      })
      .catch(err => {
        setImpList([])
        let msg = err && err.response && err.response.data ? err.response.data.error : "Error Fetching Publisher Details";
        setResponse({ isError: true, msg: msg })
      })
    }

     if(func == setImpState){

        setImpState(value)
        getImpConfigsByState(value)
        .then(res => {
          setImpList(res.data);
          setResponse({ isError: false, msg: "" })
          const uniqueImpIds = [];
          res.data.map(imp => {
            if (uniqueImpIds.indexOf(imp.id) === -1) {
              uniqueImpIds.push(imp.id)
            }
            return 0
          })
          setImpIds(uniqueImpIds);
        })
        .catch(err => {
          setImpList([])
          let msg = err && err.response && err.response.data ? err.response.data.error : "Error Fetching Publisher Details";
          setResponse({ isError: true, msg: msg })
        })
    }
    
  }

  const cellStyle = {
    maxWidth: "280px",
    lineBreak: "anywhere",
  };

  const nameStyle = {
    margin: "0",
    fontFamily: "Tondo, Helvetica Neue, Helvetica, Arial, sans-serif",
  };

  function toggleModal(title = '', content = '', callback, flatten = false) {
    setModal({
      isOpen: !modal.isOpen,
      title: title,
      content: content,
      flatten,
      didMount: callback
    })
  }

  function toggleState(id) {
    toggleImpState(id)
      .then(res => {
        setToggle(!toggle)
      })
      .catch(err => {
        let msg = err && err.response && err.response.data ? err.response.data.error : "Unable to toggle state";
        setResponse({ msg: msg, isError: true })
      })

  }

  function initDeleteConfig(id) {
    const confirmation = window.confirm('Are you sure you want to delete?');

    if (confirmation) {
      deleteImpById(id)
        .then(response => {
          let existingImp = impList.filter(imp => (imp.id !== id));
          setImpList(existingImp)
        })
        .catch(err => {
          let msg = err && err.response && err.response.data ? err.response.data.error : "Unable to Delete";
          setResponse({ msg: msg, isError: true })

        })
    }
  }


  function renderImpressionRow(imp, index) {
    return (
      <div className={`divTableRow`} key={imp.id + index}>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{imp.id}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{imp.state.toUpperCase()}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <span
            className="text-info underline cursor-pointer"
            onClick={() => toggleModal('Impression Configuration', imp, null, true)}>View</span>
          &emsp;
          <Link to={{ pathname: '/editImpConfig/' + imp.id }} className="text-warning underline">Edit</Link>
          &emsp;
          <span
            className="text-info underline cursor-pointer"
            onClick={() => toggleState(imp.id)}
          >Toggle</span>
          &emsp;
          <span
            className="text-warning underline cursor-pointer"
            onClick={() => toggleModal("Impression Audits",
              <AuditGroup id={imp.id} getAudits={getImpConfigAuditsById} />
            )}
          >Audits</span>
          &emsp;
          {/* <span
            className="text-danger underline cursor-pointer"
            onClick={() => initDeleteConfig(imp.id)}
          >Delete</span> */}
          &emsp;
          <Link to={{ pathname: '/editImpConfig/', state: { imp: imp } }} className="text-info underline">
            Clone
          </Link>
          &emsp;
        </div>
      </div>
    );
  }

  return (
    <>
      {modal.isOpen &&
        <Modal
          flatten={modal.flatten}
          title={modal.title}
          btnHandler={toggleModal}
          closeTrigger={toggleModal}
          content={modal.content}
          didMount={modal.didMount}
        >
        </Modal>}
      <div>
        <div className="row m-0 mt-20 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 color-dark-gray">Search Impression Id</label>
              <Typeahead
                id="campaign-search"
                onChange={e => handleChange(setImpId, e[0])}
                options={impIds}
              />
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 color-dark-gray">Impression State</label>
              <form>
                <select
                  className="fs-16"
                  onChange={ev => handleChange(setImpState, ev.target.value)}
                >
                  <option value="">All</option>
                  <option value={`impState=active`}>Active</option>
                  <option value={`impState=inactive`}>InActive</option>
                </select>
              </form>
            </fieldset>
          </div>
        </div>
        <div className="row  mt-20">
        </div>
      </div>
      <div className="m-0 mt-30 mr-20">
        <div className="row">
          <div className="col-sm-12">
          </div>
        </div>
      </div>
      <div className="row m-0 mt-0 mr-20">
        <div className="row m-0 mt-0 mb-20">
          <div className="col-md-12">
            <div className="divTable divTableHover mt-20">
              <div className="divTableHeading">
                <div className="divTableRow">
                  <div className="divTableHead">Impression Id</div>
                  <div className="divTableHead">Impression State</div>
                  <div className="divTableHead">Actions</div>
                </div>
              </div>
              <div className="divTableBody">
                {impList && impList.map((imp, index) => renderImpressionRow(imp, index))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 mt-30 mr-20 vertical-text-center">
        <div className="col-md-9 text-center">
          <div className={response.isError ? "text-danger" : "text-success"}>
            <b>
              <h4>{response.msg}</h4>
            </b>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewImpressionConfig;
