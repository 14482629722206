import { useForm } from "react-hook-form";
import React, { useRef, useEffect, useState } from "react";
import {AdStates, PlatformList, SLOT_LEVEL_RETRY} from "../constants/AdConstants";
import { useLocation } from 'react-router-dom';
import { saveNewAdConfig, getNewAdConfigById } from "../AdConfig/adconfig.service";
import { NEW_SLOT_CONFIG_CONDITION_PATTERN, NON_EMPTY_STRING_PATTERN} from "../constants/RegularExpressions";
import { TENANTS } from "../constants/AdtechConstants";
import { storeClientNameInMemory } from "../utils/adtech.utils";
import APSConfig from "../Impression Config/APSConfig";  
import PrebidConfig from "../Impression Config/PrebidConfig"; 

function CreateNewSlotConfig(props) {
  const {
    register,
    handleSubmit,
    handleChange,
    formState: { errors },
    reset,
    watch,
    control,
  } = useForm({ mode: "onChange" });

const [aps,setAps]=useState(false)
const [prebid,setPrebid]=useState(false)
const [headerBiddingSlotItem,setHeaderBiddingSlotItem]=useState(false)

function addAps(){
  setHeaderBiddingSlotItem(true);
  setAps(true)
}
function addPrebid(){
  setHeaderBiddingSlotItem(true);
  setPrebid(true)
}

function removePrebid(){
  setPrebid(false)
}

function removeAPS(){
  setAps(false)
}

  const id = useRef(props.match.params.id);
  const location = useLocation();
  const adconfig = location?.state?.adconfig;
  const [clientIdList, setClientIdList] = useState([]);
  const [response, setResponse] = useState({ msg: "", isError: false });

  const resetObj = {
    adId: "",
    clientName: "",
    os: "",
    source: "",
    adType: "",
    adState: "",
    condition: "",
    creativeFormat: [],
    priority: "",
    adSource: "",
    loadingStrategy: "",
    slotAdType: "",
    tmax:"",
    newSlotConfigCondition: "",
  };

  const prepareOptionsListFromValues = (values) => {
    if (typeof values === "undefined") return [];
    return values.reduce((optionsList, value) => {
      optionsList = [...optionsList, { label: value, value: String(value) }];
      return optionsList;
    }, []);
  };
  
  const prepareNewEditForm = (data) => {
    let adUnitIds,source,adSizes,slotAdType,prebidAdUnitIds,prebidSource,prebidAdSizes,prebidSlotAdType;
    if(data.headerBiddingSlotItems && data.headerBiddingSlotItems.length>0){
      data.headerBiddingSlotItems.forEach((element,index) => {
        if(element.source == "APS"){
          adUnitIds = prepareOptionsListFromValues(element.adUnitIds);
          source=element.source;
          adSizes= element.adSizes;
          slotAdType= element.slotAdType;
          setAps(true);

        }else if(element.source == "AIRTEL"){
          prebidAdUnitIds = prepareOptionsListFromValues(element.adUnitIds);
          prebidSource=element.source;
          prebidAdSizes= element.adSizes;
          prebidSlotAdType= element.slotAdType; 
          setPrebid(true);
        }

      })};
  
    data = {...data,adUnitIds,source,adSizes,slotAdType,prebidAdUnitIds,prebidSource,prebidAdSizes,prebidSlotAdType};
    return data;
  };

  const prepareEditForm = async () => {
    if (typeof id.current !== "undefined" && id.current !== null) {
      try {
        const res = await getNewAdConfigById(id.current);
        return reset(prepareNewEditForm(res.data));
      } catch (err) {
        setResponse({
          msg:
            err.response && err.response.data
              ? err.response.data.message
              : "Unknown",
          isError: true,
        });
      }
    }
  };

  const prepareFormByData = (data) => {
    return reset(prepareNewEditForm(data));
  }

  useEffect(() => {
    let tenants = localStorage.getItem(TENANTS);
    if (typeof tenants === "undefined" || tenants.length === 0) {
      setResponse({
        msg: "Seems Like you don't have sufficient access",
        isError: true,
      });
      return;
    }
    if (typeof tenants != String) tenants = tenants.split(",");
    setClientIdList(tenants);
    prepareEditForm();
  }, [id]);

  useEffect(() => {
    if (adconfig) {
      let tenants = localStorage.getItem(TENANTS);
      if (typeof tenants != String) tenants = tenants.split(",");
      setClientIdList(tenants);
      prepareFormByData(adconfig)
    }
  }, [adconfig]);

  useEffect(() => {
    if(!aps && !prebid){
      setHeaderBiddingSlotItem(false);
    }
    if(aps || prebid){
      setHeaderBiddingSlotItem(true);
    }
  }, [aps,prebid]);

  function onSubmit(data, ev) {
    setResponse({ msg: "", isError: false });
    ev.preventDefault();
    let method =
      typeof id.current !== "undefined" && id.current !== null ? "PUT" : "POST";
    data.id = id.current;
    storeClientNameInMemory(data.clientName);

    if(adconfig){
      delete data.clientId;
      delete data.version;
    }
    if(data?.newSlotConfigCondition?.length===0){
      delete data.newSlotConfigCondition;
    }
    // payload
    let payload = {
      clientName: data.clientName,
      os: data.os,
      adState: data.adState,
      adId: data.adId,
      slotLevelRetryMechanism: data.slotLevelRetryMechanism,
      newSlotConfigCondition: data.newSlotConfigCondition,
      tmax: data.tmax
    };

    if (data.id) {
      payload = {
        ...payload,
        id: data.id,
      };
    }
   
    let headerBidderConfigList = [];

    if(aps ){
      let apsConfig = {
        source: data.source
      }
      if(data.adUnitIds?.length>0){
     apsConfig = {
      ...apsConfig,
      adUnitIds: data.adUnitIds
        ? data?.adUnitIds.map((item) => item.value)
        : []
    }
  }
    if(data.slotAdType){
      apsConfig = {
        ...apsConfig,
        slotAdType:data.slotAdType
      };
    }
    if(data.adSizes){
      apsConfig = {
        ...apsConfig,
        adSizes: data.adSizes,
      };
    }
    headerBidderConfigList.push(apsConfig);
    }

    if(prebid){
      let prebidConfig = {
        source: data.prebidSource,
      };
      if(data.prebidAdUnitIds?.length>0){
       prebidConfig = {
        ...prebidConfig,
        adUnitIds: data.prebidAdUnitIds
          ? data?.prebidAdUnitIds.map((item) => item.value)
          : []
      }
    }
      if(data.prebidSlotAdType){
        prebidConfig = {
          ...prebidConfig,
          slotAdType:data.prebidSlotAdType
        };
      }
      if(data.prebidAdSizes){
        prebidConfig = {
          ...prebidConfig,
          adSizes: data.prebidAdSizes,
        };
      }
      headerBidderConfigList.push(prebidConfig);
      }
      if (headerBiddingSlotItem) {
        payload = {
          ...payload,
          loadingStrategy: headerBiddingSlotItem ?  "HEADER_BIDDING" : null,
          headerBiddingSlotItems: headerBidderConfigList
      };
    }

    saveNewAdConfig(payload, method)
      .then((res) => {
        setResponse({ msg: "Submitted Successfully", isError: false });
        reset({});
        reset(resetObj);
        setPrebid(false);
        setAps(false);
        props.history.replace("/editNewAdconfig");
        id.current = null;
      })
      .catch((err) => {
        setResponse({ msg: "Error:" + err.response.data.error, isError: true });
      });
  }

  const renderPrebidFormFields = (prebid) => {
    const Form = PrebidConfig;
    return (
      <Form
        control={control}
        register={register}
        errors={errors}
        errorRequiredField={errorRequiredField}
        handleChange={handleChange}
        watch={watch}
      />
    );
  };

  const renderAPSFormFields = (aps) => {
   
    const Form = APSConfig;
    return (
      <Form
        control={control}
        register={register}
        errors={errors}
        errorRequiredField={errorRequiredField}
        handleChange={handleChange}
        watch={watch}
      />
    );
  };


  const errorRequiredField = (message = "This field is required") => {
    return <small className="text-danger">{message}</small>;
  };
  return (
    <div>
      <div className="center-block" style={{ textAlign: "center" }}>
        <h4>New Slot Config Form</h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row m-0 mt-30 mr-20">
          {/* client Name  */}
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="ClientName">
                Client Name
              </label>
              <select
                name="clientName"
                className="fs-14"
                {...register("clientName", { required: true })}
              >
                <option value="">Select a Client</option>
                {clientIdList.map((clientName) => (
                  <option value={clientName.value} key={clientName.value}>
                    {clientName}
                  </option>
                ))}
              </select>
              {!!errors.clientName && errorRequiredField()}
            </fieldset>
          </div>

          {/* OS name  */}
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="OS">
                OS
              </label>
              <select
                name="os"
                className="fs-14"
                {...register("os", { required: true })}
              >
                <option value="">Select a OS</option>
                {PlatformList.map((os) => (
                  <option key={os} value={os}>
                    {os}
                  </option>
                ))}
              </select>
              {!!errors.os && errorRequiredField()}
            </fieldset>
          </div>
        </div>

        {/* row 1 ends here */}

        <div className="row m-0 mt-30 mr-20">
          {/* slot id */}
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="adId">
                Slot Id
              </label>
              <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register("adId", { required: true })}
                placeholder="Enter Ad Slot Ids"
                // pattern="[a-zA-Z0-9\.\-_\s]{1,50}"
                pattern={NON_EMPTY_STRING_PATTERN}
                title="Please enter a non-blank string. Terminal whitespaces are not allowed."
                maxLength="50"
              />
              {errors.adId && errorRequiredField()}
            </fieldset>
          </div>

          {/* slot level retry mechanish */}
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="slotLevelRetryMechanism">
                Slot Level Retry
              </label>
              <select
                name="slotLevelRetryMechanism"
                className="fs-14"
                {...register("slotLevelRetryMechanism",{required :true})}
              >
                <option value="">Select Retry</option>
                {SLOT_LEVEL_RETRY.map((slotOption) => {
                  return slotOption==="ENABLE" ? <option value={slotOption} key={slotOption} selected>
                  {slotOption}
                </option> : <option value={slotOption} key={slotOption}>
                    {slotOption}
                  </option>
                  
                  })}
              </select>
            </fieldset>
          </div>

          {/* Row 2 ends here */}
        </div>

        <div className="row m-0 mt-30 mr-20">
         
        <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="newSlotConfigCondition">
                New Slot Config Exclusion Condition
              </label>
              <input
                name="newSlotConfigCondition"
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register("newSlotConfigCondition", { required: false })}
                placeholder="Enter New Slot Config Condition"
                pattern={NEW_SLOT_CONFIG_CONDITION_PATTERN}
                title="Please enter a valid new slot config condition eg. [1,5],[6,6], [1,1] etc"
                maxLength="500"
              />
              {errors.condition}
            </fieldset>
            </div>
          

          {/* ads state */}
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="AdState">
                Ad State
              </label>
              <select
                name="adState"
                className="fs-14"
                {...register("adState", { required: true })}
              >
                <option value="">Select a Ad State</option>
                {AdStates.map((adState) => (
                  <option value={adState} key={adState}>
                    {adState}
                  </option>
                ))}
              </select>
              {errors.adState && errorRequiredField()}
            </fieldset>
          </div>
        </div>

        <div className="row m-0 mt-30 mr-20">     
         <div className="col-md-6">
             <fieldset className="pl-20">
               <label className="fs-14 required-field" htmlFor="tmax">
                 Tmax
               </label>
               <input
                 name="tmax"
                 className="fs-14"
                 type="text"
                 autoComplete="off"
                 {...register("tmax", { required: true })}
                 placeholder="Please enter a valid tmax"
                 maxLength="500"
               />
               {errors.tmax && errorRequiredField()}
             </fieldset>
             </div>
         </div>
 

        <div className="row m-0 mt-30 mr-20"> 
            </div>
            <button name ="aps"  {...register("aps")}className="bg-green btn btn-small btn-add  mt-20 ml-30" type="button" onClick={() => addAps()}>
                Add APS Config
            </button>
            {<button className="btn btn-small btn-remove pull-right mt-20" type="button" onClick={() => removeAPS()}>
                        Remove  APS
              </button>}
              
            { aps && renderAPSFormFields("aps")}
            <div className="row m-0 mt-30 mr-20">
            <button name ="prebid"  {...register("prebid")}className="bg-green btn btn-small btn-add  mt-20 ml-30" type="button" onClick={() => addPrebid()}>
                Add Prebid Config
            </button>
            {<button className="btn btn-small btn-remove pull-right mt-20" type="button" onClick={() => removePrebid()}>
                        Remove  Prebid
              </button>}
          {prebid &&renderPrebidFormFields("prebid")}
          </div>

        <div className="row m-0 mt-30 mr-20 vertical-text-center">
          <div className="col-md-9 text-center">
            <div className={response.isError ? "text-danger" : "text-success"}>
              <b>
                <h4>{response.msg}</h4>
              </b>
            </div>
          </div>
          <div className="col-md-3 mt-60 ">
            <input className="btn btn-success pull-right" type="submit" />
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateNewSlotConfig;
