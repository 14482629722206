/* eslint-disable no-sequences */
import {useForm, useFieldArray} from 'react-hook-form';
import React , {useRef, useEffect, useState} from 'react';
import { IFATPlatformMap, PlatformList, Services } from '../constants/AdConstants';
import { getAdClientById, saveAdClient } from './ad.client.service';
import { NON_EMPTY_STRING_PATTERN } from '../constants/RegularExpressions';
import { storeClientNameInMemory } from '../utils/adtech.utils';

function CreateAdClient(props){
  const { register, handleSubmit, formState:{errors}, reset, control, setValue} = useForm({mode:"onChange",
    defaultValues:{
      supportedType:[{key:"",value:""}]
    }
  });
  
  const id = useRef(props.match.params.id);
  const [response, setResponse] = useState({ msg: "", isError: false });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "params",
  });


  const prepareEditForm = (data) => {
    let params = Object.keys(data.params).map(key=>{
      if(typeof key !== "undefined")
        return {key, value:data.params[key]}
    })
    data = {...data ,params}
    reset(data)
  }


  useEffect(()=>{
    if(typeof id.current !=="undefined" && id.current !== null){
      getAdClientById(id.current)
      .then(res=>{
        prepareEditForm(res.data);
      })
      .catch(err=>{
        setResponse({msg:"Error getting Ad Client", isError:true})
      })
    }

  },[id]);

  const prepareSubmitData = (data) => {
   data.params = data.params.reduce((finalParams,param)=>(
      finalParams[param.key]=param.value, finalParams
   ),{})

   data.ifat = IFATPlatformMap[data.os]
  }


  function onSubmit(data, ev){
    ev.preventDefault()
    
    let method = typeof id.current !=="undefined" && id.current !== null ? "PUT" : "POST"
    data.id = id.current
    prepareSubmitData(data)
    storeClientNameInMemory(data.clientName)
    saveAdClient(data, method)
    .then(res=>{
      setResponse({msg:"Submitted Successfully", isError:false})
      setValue('supportedType', [{key:"", value:""}])
      setValue('params', [{key:"", value:""}])
      reset({"clientId":"", enabled:"false"})
      props.history.replace("/edit-adclient"); 
      id.current = null 
     
    })
    .catch(err=>{
      setResponse({msg:"Error:" + err.response.data.error, isError:true})
    })
    
  }

  const errorRequiredField= (message="This field is required") => {
    return(<small className="text-danger">{message}</small>)
  } 

  return (
    <>
      <div className="center-block" style={{textAlign:'center'}}>
        <h4>Ad Client Form</h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="AdSlotId">
                Client Name
              </label>
              <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('clientName',{required:true})}
                placeholder="Enter Client Name"
                pattern={NON_EMPTY_STRING_PATTERN}
                title="Please enter a non blank string . Terminal whitespaces are not allowed."
                maxLength="50"
              />
              {errors.clientName && errorRequiredField()}
            </fieldset>
          </div>

          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="AdSlotId">
                Operating System
              </label>
              <select
                name="os"
                className="fs-14"
                {...register('os',{required:true})}
                >
                 {PlatformList.map(os => 
                      <option key={os} value={os}>
                          {os}
                      </option>)}
              </select>
              {!!errors.os && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                Service
              </label>
              <select
                name="service"
                className="fs-14"
                {...register('service',{required:false})}
                >
                <option value="">Select a service</option>
                {Services.map((service) => (
                  <option value={service} key={service}>
                    {service}
                  </option>
                ))}
              </select>
              {errors.service && errorRequiredField()}
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="AdSlotId">
                Ads Enabled
              </label>
              <select
                name="enabled"
                className="fs-14"
                {...register('enabled',{required:false})}
                >
                <option value={String(false)}>False</option>
                <option value={String(true)}>True</option>
              </select>
              {errors.enabled && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
           <div className="col-md-6">
             <fieldset className="pl-20">
               <label className="fs-14" htmlFor="AdSlotId">
                 App Id
               </label>
               <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('appId',{required:false})}
                placeholder="Enter App Id"
                pattern="[0-9]{1,50}"
                title="Allowed values are numbers"
                maxLength="50"
              />
            </fieldset>
           </div>
           <div className="col-md-6">
             <fieldset className="pl-20">
               <label className="fs-14 required-field" htmlFor="AdSlotId">
                 External Partner
               </label>
               <select
                name="externalPartner"
                className="fs-14"
                {...register('externalPartner',{required:false})}
                >
                <option value={String(false)}>False</option>
                <option value={String(true)}>True</option>
              </select>
              {errors.externalPartner && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
          <fieldset className="pl-20">
            <label className="col-md-12 fs-16">
             Enter Params
            </label>
            <div className="row m-0">
              {fields.map((item, index) => {
                return (
                  <div className="row m-0 mt-30" key={item.id}>
                    <div className="col-md-5 m-0 mr-20">
                      <label className="fs-14 required-field" htmlFor="AdSlotId">
                        Param Key
                      </label>
                      <input
                        type="text"
                        className="fs-14"
                        defaultValue={item.key}
                        autoComplete="off"
                        maxLength="50"
                        control={control}
                        pattern={NON_EMPTY_STRING_PATTERN}
                        title="Please enter a non blank string. Terminal whitespaces are not allowed"
                        {...register(`params[${index}].key`,{required:true})}
                        placeholder="Enter Key"
                      />
                      {errors.params?.[index]?.key && errorRequiredField()}
                    </div>
                    <div className="col-md-5 m-0">
                      <label className="fs-14 required-field" htmlFor="AdSlotId">
                        Param Value
                      </label>
                      <input
                        type="text"
                        className="fs-14"
                        {...register(`params[${index}].value`,{required:true})}
                        control={control}
                        defaultValue={item.value}
                        autoComplete="off"
                        pattern={NON_EMPTY_STRING_PATTERN}
                        title="Please enter a non blank string. Terminal whitespaces are not allowed"
                        maxLength="50"
                        placeholder="Enter Value"
                      />
                      {errors.params?.[index]?.value && errorRequiredField()}
                    </div>
                    <input
                      type="button"
                      value="Remove"
                      className="btn btn-sm"
                      style={{ backgroundColor: "#976262" }}
                      onClick={() => remove(index)}
                    />
                  </div>
                );
              })}
            </div>
            <input
              type="button"
              value="Add More"
              className="btn btn-sm mt-20 ml-20"
              style={{ backgroundColor: "rgb(76 135 92)" }}
              onClick={() => append({ key:"", value:"" })}
            />     
          </fieldset>
        </div>
        <div className="row m-0 mt-30 mr-20 vertical-text-center">
          <div className="col-md-9 text-center">
            <div className={response.isError ? "text-danger" : "text-success"}>
              <b>
                <h4>{response.msg}</h4>
              </b>
            </div>
          </div>
          <div className="col-md-3">
            <input className="btn btn-success pull-right" type="submit" />
          </div>
        </div>
      </form>
    </>
  )
}

export default CreateAdClient;