import { ajax } from "../utils/ajax";
import { AD_PROPERTIES_ENDPOINT } from "../Common Components/service.urls";

export const getAdProperties = (adType,clientName,os,key='') => {
  key = key !== '' ? `key=${key}` : key;
  return ajax({
    method:"get",
    url:`${AD_PROPERTIES_ENDPOINT}/list?${adType}&${key}&${os}`
  })
}

export const saveAdProperties = (adProperties ,method) => {
  return ajax({
    method : method,
    data : adProperties ,
    url : `${AD_PROPERTIES_ENDPOINT}`
  })
}

export const getAdPropertiesById = (id) => {
  return ajax({
    method : "get",
    params: {
      id
    },
    url : `${AD_PROPERTIES_ENDPOINT}`
  })
}

export const deleteAdPropertiesById = (id) => {

  return ajax({
    method:"delete",
    url:`${AD_PROPERTIES_ENDPOINT}/${id}`
  })
}

export const getAdPropertiesAuditsById = (id) => {
  return ajax({
    method : "get",
    params :{
      adPropertyId: id
    },
    url : `${AD_PROPERTIES_ENDPOINT}/audits`

  })
}