import { useForm } from 'react-hook-form';
import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { saveAdUnitConfig, getAllAccount, getAdUnitConfigById } from '../NewSlotConfig/airtelHeaderBidding.service';
import CreatableForm from '../Common Components/CreatableForm';
import { PublisherType, IMP_STATE, AuctionTypeOptions } from '../constants/AdConstants'
import SourceConfig from './SourceConfig';

function CreateAdUnitConfig(props) {

  const id = useRef(props.match.params.id);
  const [source, setSource] = useState(false)
  const [ACCOUNT_LIST, setAccountList] = useState([]);
  const [selectedAccountId, setAccountId] = useState('');
  const [response, setResponse] = useState({ msg: "", isError: false });

  const errorRequiredField = (message = "This field is required") => {
    return (<small className="text-danger">{message}</small>)
  }

  const location = useLocation();
  const adUnit = location?.state?.adUnit;

  useEffect(() => {

    const callAccountsApi = async () => {
      let res = await getAllAccount();
      setAccountList(res.data);
    }

    callAccountsApi();
  }, []);


  const {
    register,
    handleSubmit,
    handleChange,
    formState: { errors },
    reset,
    control,
    watch
  } = useForm({ mode: "onChange" });

  function addSource() {
    setSource(true)
  }
  const renderDependentSourceFormFields = (source) => {

    const Form = SourceConfig;
    return (
      <Form
        control={control}
        register={register}
        errors={errors}
        errorRequiredField={errorRequiredField}
        handleChange={handleChange}
        watch={watch}
      />
    );
  };

  const resetObj = {
    publisherType: "",
    state: "",
    accountId: "",
    imp: [],
    at: "",
    tmax: "",
    cur: [],
    badv: [],
    bcat: [],
    fd: "",
    tid: "",
    sourceExt: {},
    adUnitExt: {}
  };

  const prepareOptionsListFromValues = (values) => {
    if (typeof values === "undefined") return [];
    return values.reduce((optionsList, value) => {
      optionsList = [...optionsList, { label: value, value: String(value) }];
      return optionsList;
    }, []);
  };

  const prepareNewEditForm = (data) => {
    data.publisherType = data.publisherType ? data.publisherType.toUpperCase() : null;
    data.state = data.state ? data.state.toUpperCase() : null;
    data.accountId = data.accountId ? data.accountId : null; 
    data.imp = prepareOptionsListFromValues(data.imp);
    data.at = data.at ? data.at == 1 ? "First Price" : "Second Price" : null;
    data.cur = prepareOptionsListFromValues(data.cur);
    data.badv = prepareOptionsListFromValues(data.badv);
    data.bcat = prepareOptionsListFromValues(data.bcat);
    data.adUnitExt = data.ext;

    setAccountId(data.accountId);

    let fd, transactionId, sourceExt;
    if (data.source) {
      addSource();
      fd = data.source.fd;
      transactionId = data.source.tid;
      sourceExt = data.source.ext;
      data = { ...data, fd, transactionId, sourceExt };
    }
    return data;
  };

  const prepareEditForm = async () => {
    if (typeof id.current !== "undefined" && id.current !== null) {
      try {
        const res = await getAdUnitConfigById(id.current);
        return reset(prepareNewEditForm(res.data));;
      } catch (err) {
        setResponse({
          msg:
            err.response && err.response.data
              ? err.response.data.message
              : "Unknown",
          isError: true,
        });
      }
    }
  };

  const prepareFormByData = (data) => {
    return reset(prepareNewEditForm(data));
  }


  // useEffect(() => {
  //   if (adUnit) {
  //     prepareFormByData(adUnit)
  //   }
  // }, [adUnit]);

  useEffect(() => {
    prepareEditForm();
  }, [id]);

  function onSubmit(data, ev) {
    setResponse({ msg: "", isError: false });
    ev.preventDefault();
    let method =
      typeof id.current !== "undefined" && id.current !== null ? "PUT" : "POST";
    data.id = id.current;


    const removeNullValues = (data) => {
      return Object.fromEntries(
        Object.entries(data).filter(([_, v]) => v != null)
      );
    };


    // payload
    let payload = {
      publisherType: data.publisherType.toLowerCase(),
      state: data.state.toLowerCase(),
      accountId: data.accountId,
      imp: data.imp
        ? data?.imp.map((item) => item.value)
        : null,
      at: data.at ? data.at == "First Price" ? Number(1) : Number(2) : null,
      tmax: data.tmax ? Number(data.tmax) : null,
      cur: data.cur
        ? data?.cur.map((item) => item.value)
        : null,
      badv: data.badv
        ? data?.badv.map((item) => item.value)
        : null,
      bcat: data.bcat
        ? data?.bcat.map((item) => item.value)
        : null,
      ext: data.adUnitExt ? data.adUnitExt : null
    };

    if (data.id) {
      payload = {
        ...payload,
        id: data.id,
      };
    }

    if (data.source) {
      payload = {
        ...payload,
        source: {
          fd: data.source.fd,
          tid: data.source.tid,
          ext: data.source.sourceExt
        }
      }
    }
    const filteredData = removeNullValues(payload);
    saveAdUnitConfig(filteredData, method)
      .then((res) => {
        setResponse({ msg: "Submitted Successfully", isError: false });
        reset({});
        reset(resetObj);
        props.history.replace("/editAdUnitConfig");
        id.current = null;
      })
      .catch((err) => {
        setResponse({ msg: "Error:" + err.response.data.error, isError: true });
      });
  }
  return (
    <>
      <div className="center-block" style={{ textAlign: 'center' }}>
        <h4>ADUNIT CONFIG</h4>
      </div>
      <form onSubmit={
        handleSubmit(onSubmit)
      }>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="publisherType">
                Publisher Type
              </label>
              <select
                name="publisherType"
                className="fs-14"
                {...register('publisherType', { required: true })}>
                <option value="">Select a Ad Type</option>
                {Object.keys(PublisherType).map((publisherType) => (
                  <option value={publisherType} key={publisherType}>
                    {publisherType}
                  </option>
                ))}
              </select>
              {errors.publisherType && errorRequiredField()}
            </fieldset>
          </div>

          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="state">
                State
              </label>
              <select
                name="state"
                className="fs-14"
                {...register('state', { required: true })}>
                <option value="">Select State</option>
                {IMP_STATE.map((state) => (
                  <option value={state} key={state}>
                    {state}
                  </option>
                ))}
              </select>
              {errors.state && errorRequiredField()}
            </fieldset>
          </div>
        </div>

        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="accountId">
                Account
              </label>
              <select
                name="accountId"
                className="fs-14"
                {...register('accountId', { required: true })}>
                <option value="">Select Account</option>
                {ACCOUNT_LIST.map((accountId) => (
                  <option selected={accountId === selectedAccountId} value={accountId} key={accountId}>
                    {accountId}
                  </option>
                ))}
              </select>
              {errors.accountId && errorRequiredField()}
            </fieldset>
          </div>

          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="imp">
                Add Impression Config
              </label>
              <CreatableForm
                control={control}
                register={register}
                name="imp"
                placeholder="Add impression config"
                required={true}
              />
              {errors.imp && errorRequiredField()}
            </fieldset>
          </div>
        </div>

        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="at">
                Auction Type
              </label>
              <select
                name="at"
                className="fs-14"
                {...register('at', { required: false })}>
                <option value="">Select a Auction Type</option>
                {Object.keys(AuctionTypeOptions).map((at) => (
                  <option value={at} key={at}>
                    {at}
                  </option>
                ))}
              </select>
              {errors.at && errorRequiredField()}
            </fieldset>
          </div>

          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="tmax">
                Tmax
              </label>
              <input
                className="fs-14"
                name="tmax"
                type="text"
                autoComplete="off"
                {...register('tmax', { required: false })}
                placeholder="Enter tmax"
              />
              {errors.tmax && errorRequiredField()}
            </fieldset>
          </div>
        </div>

        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 " htmlFor="cur">
                Currency
              </label>
              <CreatableForm
                control={control}
                register={register}
                name="cur"
                placeholder="Add Currency"
                required={false}
              />
              {errors.cur && errorRequiredField()}
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="badv">
                Add Block Advertisers
              </label>
              <CreatableForm
                control={control}
                register={register}
                name="badv"
                placeholder="Add block advertisers"
                required={false}
              />
              {errors.badv && errorRequiredField()}
            </fieldset>
          </div>
        </div>

        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 " htmlFor="bcat">
                Add Block Advertisers Category
              </label>
              <CreatableForm
                control={control}
                register={register}
                name="bcat"
                placeholder="Add block advertisers category"
                required={false}
              />
              {errors.bcat && errorRequiredField()}
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 " htmlFor="adUnitExt">
                ext
              </label>
              <input
                className="fs-14"
                name="adUnitExt"
                type="text"
                autoComplete="off"
                {...register('adUnitExt', { required: false })}
                placeholder="Enter ext"
              />
              {errors.adUnitExt && errorRequiredField()}
            </fieldset>
          </div>
        </div>

        <div className="row m-0 mt-30 mr-20">
        </div>
        <button name="source"  {...register("source")} className="bg-green btn btn-small btn-add  mt-20 ml-30" type="button" onClick={() => addSource()}>
          Add Source
        </button>
        {source && renderDependentSourceFormFields("source")}

        <div className="row m-0 mt-30 mr-20 vertical-text-center">
          <div className="col-md-9 text-center">
            <div className={response.isError ? "text-danger" : "text-success"}>
              <b>
                <h4>{response.msg}</h4>
              </b>
            </div>
          </div>
          <div className="col-md-3">
            <input className="btn btn-success btn-md pull-right" type="submit" />
          </div>
        </div>

      </form>
    </>
  );
}

export default CreateAdUnitConfig;