import React from 'react';
import CreatableForm from '../../Common Components/CreatableForm';


function InterStitialMetaForm(props){
  const {register, control, errorRequiredField, errors } = props;
  return (
    <>
      <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 required-field" htmlFor="adUnitIds">
              Ad Unit Ids
            </label>
            <CreatableForm 
              control={control} 
              register={register} 
              name="adUnitIds" 
              placeholder ="Ad Unit Ids" 
              required={true} 
            />
            {errors.adUnitIds && errorRequiredField()}
          </fieldset>
        </div>
        <div className="col-md-6">
          <fieldset className="pl-20">
          <label className="fs-14" htmlFor="AdSlotId">
              Ad Templates
            </label>
            <CreatableForm 
              control={control} 
              register={register} 
              name="adTemplates" 
              placeholder ="Ad Template Ids" 
              required={false} 
            />
            {!!errors.adTemplates && errorRequiredField()}
          </fieldset>
        </div>
      </div>
      <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6"> 
          <fieldset className="pl-20">
          <label className="fs-14 required-field" htmlFor="AdSlotId">
              Refresh Interval
            </label>
            <input
              name="refreshInterval"
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register('refreshInterval',{required:true})}
              placeholder="Enter Refresh Interval"
              pattern="[-]{0,1}[0-9]{1,50}"
              title="Allowed values are numbers"
              maxLength="50"
            />
            {errors.refreshInterval && errorRequiredField()}
            </fieldset>
        </div>
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14" htmlFor="AdSlotId">
              Ad Triggers
            </label>
            <CreatableForm 
              control={control} 
              register={register} 
              name="triggers" 
              placeholder ="Enter Ad Triggers" 
              required={false} 
            />
            {!!errors.triggers && errorRequiredField()}
          </fieldset>
        </div>
      </div>
      <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 required-field" htmlFor="AdSlotId">
              Frequency
            </label>
            <input
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register('frequency',{required:true})}
              placeholder="Enter Frequency"
              pattern="[0-9]{1,50}"
              title="Allowed values are numbers, alphabets, spaces, '.', '-', '_'"
              maxLength="50"
            />
            {!!errors.frequency && errorRequiredField()}
          </fieldset>
        </div>
        </div>
    </>
  )
}

export default InterStitialMetaForm;
