import { useForm } from 'react-hook-form';
import React, { useRef, useEffect, useState } from 'react';
import { getAdPropertiesById, saveAdProperties } from './adproperties.service';
import { AdTypes, PlatformList } from '../constants/AdConstants'
import { NON_EMPTY_STRING_PATTERN } from '../constants/RegularExpressions';
import { TENANTS } from '../constants/AdtechConstants';
import { storeClientNameInMemory } from '../utils/adtech.utils';


function CreateAdProperties(props) {
  const { register, handleSubmit, formState: { errors }, reset, control } = useForm({
    mode: "onChange"
  });
  const id = useRef(props.match.params.id);
  const [response, setResponse] = useState({ msg: "", isError: false });
  const [clientNameList, setClientNameList] = useState([])

  const resetObj = {
    type: "",
    clientName: "",
    os: "",
    key: "",
    value: ""
  };

  useEffect(() => {
    let tenants = localStorage.getItem(TENANTS)
    if (typeof tenants === "undefined" || tenants.length === 0) {
      setResponse({ msg: "Seems Like you don't have sufficient access", isError: true })
      return;
    }
    if (typeof tenants != String)
      tenants = tenants.split(',')
    setClientNameList(tenants)
    if (typeof id.current !== "undefined" && id.current !== null) {
      getAdPropertiesById(id.current)
        .then(res => {
          reset(res.data);
        })
        .catch(err => {
          setResponse({ msg: "Error fetching details", isError: true })
        })
    }
  }, [id]);

  function onSubmit(data, ev) {
    ev.preventDefault()
    let method = typeof id.current !== "undefined" && id.current !== null ? "PUT" : "POST"
    data.id = id.current
    storeClientNameInMemory(data.clientName)
    saveAdProperties(data, method)
      .then(res => {
        setResponse({ msg: "Submitted Successfully", isError: false })
        reset(resetObj)
        props.history.replace("/editproperties");
        id.current = null
      })
      .catch(err => {
        setResponse({ msg: "Error:" + err.response && err.response.data && err.response.data.error, isError: true })
      })
  }

  const errorRequiredField = (message = "This field is required") => {
    return (<small className="text-danger">{message}</small>)
  }

  return (
    <>
      <div className="center-block" style={{ textAlign: 'center' }}>
        <h4>Ad Properties Form</h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="ClientName">
                Client Name
              </label>
              <select
                name="clientName"
                className="fs-14"
                {...register('clientName', { required: true })}
              >
                <option value="">Select a Client</option>
                {clientNameList.map((clientName) => (
                  <option value={clientName.value} key={clientName.value}>
                    {clientName}
                  </option>
                ))}
              </select>
              {!!errors.clientName && errorRequiredField()}
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="OS">
                OS
              </label>
              <select
                name="os"
                className="fs-14"
                {...register('os', { required: true })}
              >
                <option value="">Select a OS</option>
                {PlatformList.map(os =>
                  <option key={os} value={os}>
                    {os}
                  </option>)}
              </select>
              {!!errors.os && errorRequiredField()}
            </fieldset>
          </div>
          
        </div>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="Key">
                Key
              </label>
              <input
                name="key"
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('key', { required: true })}
                placeholder="Enter Key"
                pattern={NON_EMPTY_STRING_PATTERN}
                title="Allowed values are numbers, alphabets, '.','_','-'"
                maxLength="500"
              />
              {errors.key && errorRequiredField()}
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="type">
                Ad Type
              </label>
              <select
                name="type"
                className="fs-14"
                {...register('type', { required: true })}>
                <option value="">Select a Ad Type</option>
                {Object.keys(AdTypes).map((adType) => (
                  <option value={adType} key={adType}>
                    {adType}
                  </option>
                ))}
              </select>
              {errors.type && errorRequiredField()}
            </fieldset>
          </div>
          
        </div>

     
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="Value">
                Value
              </label>
              <input
                name="value"
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('value', { required: true })}
                placeholder="Enter Value"
                pattern={NON_EMPTY_STRING_PATTERN}
                title="Allowed values are numbers, alphabets, '.','_','-'"
                maxLength="500"
              />
              {errors.value && errorRequiredField()}
            </fieldset>
          </div>
        </div>

        <div className="row m-0 mt-30 mr-20 vertical-text-center">
          <div className="col-md-9 text-center">
            <div className={response.isError ? "text-danger" : "text-success"}>
              <b>
                <h4>{response.msg}</h4>
              </b>
            </div>
          </div>
          <div className="col-md-3">
            <input className="btn btn-success btn-md pull-right" type="submit" />
          </div>
        </div>
      </form>
    </>
  );
}

export default CreateAdProperties;