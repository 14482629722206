import React from 'react';
import Creatable from 'react-select/creatable';
import {Controller} from 'react-hook-form';

function CreatableForm(props){
  const {control, register , name , placeholder, options, required} = props;
  return (
    <>
      <Controller
      control={control}
      refs={register}
      name={name}
      defaultValue={[]}
      options={[]}
      rules={{ required: required }}
      render ={({field})=>
        <Creatable {...field}
          placeholder={placeholder}
          options={options}
          isClearable
          closeMenuOnSelect
          isMulti
      />
      }
      />
  </>
  )
}

export default CreatableForm;