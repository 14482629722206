import { useForm, Controller } from "react-hook-form";
import React, { useRef, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import Select from "react-select";
import { AdTypes, AdStates, PlatformList, CreativeFormatList } from "../constants/AdConstants";
import VideoMetaForm from "./AdMetaForms/VideoMetaForm";
import AudioMetaForm from "./AdMetaForms/AudioMetaForm";
import BannerMetaForm from "./AdMetaForms/BannerMetaForm";
import InterStitialMetaForm from "./AdMetaForms/InterstitialMetaForm";
import { saveAdConfig, getAdConfigById } from "./adconfig.service";
import {
  AD_CONDITION_PATTERN,
  NEW_SLOT_CONFIG_CONDITION_PATTERN,
  NON_EMPTY_STRING_PATTERN,
} from "../constants/RegularExpressions";
import { TENANTS } from "../constants/AdtechConstants";
import { storeClientNameInMemory } from "../utils/adtech.utils";



function CreateAdConfig(props) {
  const {
    register,
    handleSubmit,
    handleChange,
    formState: { errors },
    reset,
    watch,
    control,
  } = useForm({ mode: "onChange" });
  const id = useRef(props.match.params.id);
  const [clientIdList, setClientIdList] = useState([]);
  const [response, setResponse] = useState({ msg: "", isError: false });

  const location = useLocation();
  const adconfig = location?.state?.adconfig;

  const AdMetaForm = {
    AUDIO_AD: AudioMetaForm,
    VIDEO_AD: VideoMetaForm,
    BANNER_AD: BannerMetaForm,
    INTERSTITIAL_AD: InterStitialMetaForm,
  };

  const resetObj = {
    adId: "",
    clientName: "",
    os: "",
    source: "",
    adType: "",
    adState: "",
    condition: "",
    creativeFormat: [],
    priority: "",
    adConfigSlotCondition:"",
  };

  const prepareOptionsListFromValues = (values) => {
    if (typeof values === "undefined") return [];

    return values.reduce((optionsList, value) => {
      optionsList = [...optionsList, { label: value, value: String(value) }];
      return optionsList;
    }, []);
  };

  const prepareAudioEditForm = (adconfig) => {
    const {
      companionBannerHeight,
      companionBannerWidth,
      targeted,
      adUnitId,
      freqInterval,
      creativeFormat,
      source,
      priority,
    } = adconfig.adMeta;
    let blackListedCps = prepareOptionsListFromValues(
      adconfig.adMeta.blackListedCps
    );
    let audioAdConfig = {
      ...adconfig,
      companionBannerHeight,
      companionBannerWidth,
      targeted,
      adUnitId,
      freqInterval,
      blackListedCps,
      creativeFormat: creativeFormat && CreativeFormatList.filter(item => creativeFormat.includes(item.value)),
      source,
      priority,
    };
    return audioAdConfig;
  };

  const prepareVideoEditForm = (adconfig) => {
    const {
      companionBannerHeight,
      companionBannerWidth,
      targeted,
      adUnitId,
      freqInterval,
      creativeFormat,
      source,
      priority,
    } = adconfig.adMeta;
    let blackListedCps = prepareOptionsListFromValues(
      adconfig.adMeta.blackListedCps
    );
    let videoAdConfig = {
      ...adconfig,
      companionBannerHeight,
      companionBannerWidth,
      targeted,
      adUnitId,
      freqInterval,
      blackListedCps,
      creativeFormat: creativeFormat && CreativeFormatList.filter(item => creativeFormat.includes(item.value)),
      source,
      priority,
    };
    return videoAdConfig;
  };

  const prepareBannerEditForm = (adconfig) => {
    const {
      refreshInterval,
      adSize,
      source,
      creativeFormat,
      imprDelay,
      slotAdType,
      maxPodDuration,
      maxPodAdTime,
      isSkipAllAd,
      minAdDuration,
      priority,
    } = adconfig.adMeta;
    let adUnitIds = prepareOptionsListFromValues(adconfig.adMeta.adUnitIds);
    let adTemplates = prepareOptionsListFromValues(adconfig.adMeta.adTemplates);

    let bannerAdConfig = {
      ...adconfig,
      adUnitIds,
      refreshInterval,
      adTemplates,
      adSize,
      source,
      creativeFormat: creativeFormat && CreativeFormatList.filter(item => creativeFormat.includes(item.value)),
      imprDelay,
      priority,
    };

    if (slotAdType !== "")
      bannerAdConfig = {
        ...bannerAdConfig,
        slotAdType,
        maxPodDuration,
        maxPodAdTime,
        isSkipAllAd,
        minAdDuration,
      };

    return bannerAdConfig;
  };
  const prepareFormByAdType = (adType, data) => {
    switch (adType || data.adType) {
      case "BANNER_AD":
        return reset(prepareBannerEditForm(data));
      case "AUDIO_AD":
        return reset(prepareAudioEditForm(data));
      case "VIDEO_AD":
        return reset(prepareVideoEditForm(data));
      case "INTERSTITIAL_AD":
        return reset(prepareInterStitialEditForm(data));
      default:
        return null; // Or any other default action you want to take.
    }
  }

  const prepareInterStitialEditForm = (adconfig) => {
    const { refreshInterval, source, creativeFormat, priority, frequency } = adconfig.adMeta;
    let adUnitIds = prepareOptionsListFromValues(adconfig.adMeta.adUnitIds);
    let adTemplates = prepareOptionsListFromValues(adconfig.adMeta.adTemplates);
    let triggers = prepareOptionsListFromValues(adconfig.adMeta.triggers);
    let interstitialAdConfig = {
      ...adconfig,
      refreshInterval,
      adUnitIds,
      adTemplates,
      triggers,
      creativeFormat: creativeFormat && CreativeFormatList.filter(item => creativeFormat.includes(item.value)),
      source,
      frequency,
      priority,
    };
    return interstitialAdConfig;
  };

  const prepareEditForm = async () => {
    if (typeof id.current !== "undefined" && id.current !== null) {
      try {
        const res = await getAdConfigById(id.current);
        switch (res.data.adType) {
          case "BANNER_AD":
            reset(prepareBannerEditForm(res.data));
            break;
          case "AUDIO_AD":
            reset(prepareAudioEditForm(res.data));
            break;
          case "VIDEO_AD":
            reset(prepareVideoEditForm(res.data));
            break;
          case "INTERSTITIAL_AD":
            reset(prepareInterStitialEditForm(res.data));
            break;
          default:
            break;
        }
      } catch (err) {
        setResponse({
          msg:
            err.response && err.response.data
              ? err.response.data.message
              : "Unknown",
          isError: true,
        });
      }
    }
  };

  useEffect(() => {
    let tenants = localStorage.getItem(TENANTS);
    if (typeof tenants === "undefined" || tenants.length === 0) {
      setResponse({
        msg: "Seems Like you don't have sufficient access",
        isError: true,
      });
      return;
    }
    if (typeof tenants != String) tenants = tenants.split(",");
    setClientIdList(tenants);
    prepareEditForm();
  }, [id]);

  useEffect(() => {
    if (adconfig) {
      let tenants = localStorage.getItem(TENANTS);
      if (typeof tenants != String) tenants = tenants.split(",");
      setClientIdList(tenants);
      prepareFormByAdType(adconfig.adType, adconfig)
    }
  }, [adconfig]);

  const reduceListOfObjecttoListOfValues = (objectList) => {
    let finalReducedList = objectList.reduce((finalList, newObj) => {
      finalList = [...finalList, newObj.value.trim()];
      return finalList;
    }, []);

    return finalReducedList;
  };

  function prepareAudioAdMeta(data) {
    const {
      adUnitId,
      companionBannerWidth,
      companionBannerHeight,
      targeted,
      freqInterval,
      source,
      creativeFormat,
      priority,
    } = data;
    let blackListedCps = reduceListOfObjecttoListOfValues(data.blackListedCps);
    data.adMeta = {
      adUnitId,
      companionBannerHeight,
      companionBannerWidth,
      blackListedCps,
      source,
      creativeFormat: creativeFormat && creativeFormat.map((item) => item.value),
      targeted,
      freqInterval,
      priority,
      type: "audioAdMeta",
    };
  }

  function prepareVideoAdMeta(data) {
    const {
      adUnitId,
      companionBannerWidth,
      companionBannerHeight,
      targeted,
      freqInterval,
      creativeFormat,
      source,
      priority,
    } = data;

    let blackListedCps = reduceListOfObjecttoListOfValues(data.blackListedCps);
    data.adMeta = {
      adUnitId,
      companionBannerHeight,
      companionBannerWidth,
      blackListedCps,
      creativeFormat: creativeFormat && creativeFormat.map((item) => item.value),
      source,
      targeted,
      freqInterval,
      priority,
      type: "videoAdMeta",
    };
  }

  function prepareBannerAdMeta(data) {
    const {
      adSize,
      refreshInterval,
      source,
      creativeFormat,
      imprDelay,
      slotAdType,
      maxPodDuration,
      maxPodAdTime,
      isSkipAllAd,
      minAdDuration,
      priority,
    } = data;
    let adUnitIds = reduceListOfObjecttoListOfValues(data.adUnitIds);
    let adTemplates = reduceListOfObjecttoListOfValues(data.adTemplates);

    data.adMeta = {
      adSize,
      adTemplates,
      adUnitIds,
      source,
      creativeFormat:creativeFormat && creativeFormat.map((item) => item.value),
      refreshInterval,
      imprDelay,
      priority,
      type: "bannerAdMeta",
    };

    if (slotAdType !== "")
      data.adMeta = {
        ...data.adMeta,
        slotAdType,
        maxPodDuration,
        maxPodAdTime,
        isSkipAllAd,
        minAdDuration,
      };
  }

  function prepareInterstitialAdMeta(data) {
    const { refreshInterval, source, creativeFormat, priority, frequency } = data;
    let adUnitIds = reduceListOfObjecttoListOfValues(data.adUnitIds);
    let adTemplates = reduceListOfObjecttoListOfValues(data.adTemplates);
    let triggers = reduceListOfObjecttoListOfValues(data.triggers);
    data.adMeta = {
      triggers,
      adTemplates,
      adUnitIds,
      source,
      creativeFormat: creativeFormat && creativeFormat.map((item) => item.value),
      frequency,
      refreshInterval,
      priority,
      type: "interstitialAdMeta",
    };
  }

  function onSubmit(data, ev) {
    setResponse({ msg: "", isError: false });
    ev.preventDefault();
    switch (data.adType) {
      case "BANNER_AD":
        prepareBannerAdMeta(data);
        break;
      case "AUDIO_AD":
        prepareAudioAdMeta(data);
        break;
      case "VIDEO_AD":
        prepareVideoAdMeta(data);
        break;
      case "INTERSTITIAL_AD":
        prepareInterstitialAdMeta(data);
        break;
      default:
        break;
    }
    let method =
      typeof id.current !== "undefined" && id.current !== null ? "PUT" : "POST";
    data.id = id.current;
    storeClientNameInMemory(data.clientName);
    data.creativeFormat = data.creativeFormat && data.creativeFormat.map((item) => item.value)
    if (data?.creativeFormat?.length === 0) {
      delete data.creativeFormat;
      delete data.adMeta.creativeFormat;
    }
    if(adconfig){
      delete data.clientId;
      delete data.version;
    }
    saveAdConfig(data, method)
      .then((res) => {
        setResponse({ msg: "Submitted Successfully", isError: false });
        reset({});
        reset(resetObj);
        props.history.replace("/editadconfig");
        id.current = null;
      })
      .catch((err) => {
        setResponse({ msg: "Error:" + err.response.data.error, isError: true });
      });
  }

  const renderAdMetaForm = (adType) => {
    const Form = AdMetaForm[adType];
    return (
      <Form
        control={control}
        register={register}
        errors={errors}
        errorRequiredField={errorRequiredField}
        handleChange={handleChange}
        watch={watch}
      />
    );
  };

  const errorRequiredField = (message = "This field is required") => {
    return <small className="text-danger">{message}</small>;
  };
  return (
    <div>
      <div className="center-block" style={{ textAlign: "center" }}>
        <h4>AdConfig Form</h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="ClientName">
                Client Name
              </label>
              <select
                name="clientName"
                className="fs-14"
                {...register("clientName", { required: true })}
              >
                <option value="">Select a Client</option>
                {clientIdList.map((clientName) => (
                  <option value={clientName.value} key={clientName.value}>
                    {clientName}
                  </option>
                ))}
              </select>
              {!!errors.client_id && errorRequiredField()}
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="OS">
                OS
              </label>
              <select
                name="os"
                className="fs-14"
                // disabled={typeof id.current !== "undefined" && id.current !== null}
                {...register("os", { required: true })}
              >
                <option value="">Select a OS</option>
                {PlatformList.map((os) => (
                  <option key={os} value={os}>
                    {os}
                  </option>
                ))}
              </select>
              {!!errors.os && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="AdSlotId">
                Slot Id
              </label>
              <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register("adId", { required: true })}
                placeholder="Enter Ad Slot Ids"
                // pattern="[a-zA-Z0-9\.\-_\s]{1,50}"
                pattern={NON_EMPTY_STRING_PATTERN}
                // title="Allowed values are numbers, alphabets, spaces, '.', '-', '_'"
                title="Please enter a non-blank string. Terminal whitespaces are not allowed."
                maxLength="50"
              />
              {errors.adId && errorRequiredField()}
            </fieldset>
          </div>

          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="AdSource">
                Ad Source
              </label>
              <input
                name="source"
                className="fs-14"
                type="text"
                autoComplete="off"
                defaultValue="DFP"
                {...register("source", { required: true })}
                placeholder="Enter Ad Source"
                // pattern="[a-zA-Z0-9\.\-_\s]{1,50}"
                pattern={NON_EMPTY_STRING_PATTERN}
                title="Please enter a non-blank string"
                maxLength="500"
              />
              {errors.source && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="AdType">
                Ad Type
              </label>
              <select
                name="adType"
                className="fs-14"
                // disabled={watch("clientId") !== "undefined" && watch("clientId") === ""}
                {...register("adType", { required: true })}
              >
                <option value="">Select a Ad Type</option>
                {Object.keys(AdTypes).map((adType) => (
                  <option value={adType} key={adType}>
                    {adType}
                  </option>
                ))}
              </select>
              {errors.adType && errorRequiredField()}
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="AdSubType">
                Ad Sub Type
              </label>
              <select
                name="adSubType"
                className="fs-14"
                disabled={
                  typeof watch("adType") !== "undefined" &&
                  watch("adType") === ""
                }
                {...register("adSubType", { required: true })}
              >
                <option value="">Select a Ad Sub Type</option>
                {typeof watch("adType") !== "undefined" &&
                  watch("adType") !== "" &&
                  AdTypes[watch("adType")].map((adType) => (
                    <option value={adType} key={adType}>
                      {adType}
                    </option>
                  ))}
              </select>
              {errors.adSubType && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="AdState">
                Ad State
              </label>
              <select
                name="adState"
                className="fs-14"
                {...register("adState", { required: true })}
              >
                <option value="">Select a Ad State</option>
                {AdStates.map((adState) => (
                  <option value={adState} key={adState}>
                    {adState}
                  </option>
                ))}
              </select>
              {errors.adState && errorRequiredField()}
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 required-field" htmlFor="AdCondition">
                Ad Condition
              </label>
              <input
                name="condition"
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register("condition", { required: true })}
                placeholder="Enter Ad Condition"
                // pattern="[a-zA-Z0-9\.\-_\s]{1,50}"
                pattern={AD_CONDITION_PATTERN}
                // title="Allowed values are numbers, alphabets, spaces, '.', '-', '_'"
                title="Please enter a valid condition eg. true, isUserPlanIn(1,2) , isUserPlanNotIn(1,2) etc"
                maxLength="500"
              />
              {errors.condition && errorRequiredField()}
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 " htmlFor="creativeFormat">
                Creative Format
              </label>
              <Controller
                control={control}
                name="creativeFormat"
                render={({
                  field: { onChange, value, name, ref },
                }) => (<Select
                  isMulti
                  name="creativeFormat"
                  {...register('creativeFormat')}
                  className="fs-14"
                  value = {value}
                  onChange={(val) => onChange(val)}
                  options={CreativeFormatList}
                />)}
              />
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="priority">
                Priority
              </label>
              <input
                className="fs-14"
                type="number"
                autoComplete="off"
                {...register("priority", { required: false })}
                placeholder="Enter Priority"
                pattern="[0-9]{1,50}"
                title="Allowed values are numbers"
                maxLength="7"
                min={1}
              />
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="adConfigSlotCondition">
                Slot Config Exclusion Condition
              </label>
              <input
                name="adConfigSlotCondition"
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register("adConfigSlotCondition", { required: false })}
                placeholder="Enter New Slot Config Condition"
                pattern={NEW_SLOT_CONFIG_CONDITION_PATTERN}
                title="Please enter a valid new slot config condition eg. [1,5],[6,6], [1,1] etc"
                maxLength="500"
              />
              {errors.condition}
            </fieldset>
          </div>
        </div>
        {watch("adType") && renderAdMetaForm(watch("adType"))}
        <div className="row m-0 mt-30 mr-20 vertical-text-center">
          <div className="col-md-9 text-center">
            <div className={response.isError ? "text-danger" : "text-success"}>
              <b>
                <h4>{response.msg}</h4>
              </b>
            </div>
          </div>
          <div className="col-md-3">
            <input className="btn btn-success pull-right" type="submit" />
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateAdConfig;
