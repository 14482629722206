import React , {useEffect, useState} from 'react';
import { NON_EMPTY_STRING_PATTERN } from '../constants/RegularExpressions';
import {useForm} from 'react-hook-form';

import {getTenantRolesList, createTenanatRoleAccessRequest} from '../Authorization/authorization.service'

function CreateAccessRequest(props){
  const { register, handleSubmit, formState:{errors}, reset, setValue, control, watch } = useForm({mode:"onChange"});

  const [roles, setRoles] = useState([]);
  const[tenantList, setTenantList] = useState([]);

  const [response ,setResponse] = useState({ msg: "", isError: false})

  const resetObj = {
    userId:"",
    tenantId:"",
    roleId:""
  };
  const handleTenantChange = (event) => {
    let tenant = '';
    if(event.target.value !== "")
     tenant = JSON.parse(event.target.value)

    if(typeof tenant !== "undefined" && tenant !==""){
      setRoles(tenant.roleIds)
      setValue('tenantId', tenant.id)
      setValue('roleId','')
    }
    else{
      setValue('tenantId', "")
      setValue('roleId', '')
      setRoles([])
    }
    
  }

  useEffect(()=>{
    // let res = getTenantRolesList()
    // setTenantList(res)
    getTenantRolesList()
    .then(res=>{
        setTenantList(res.data);
    })
    .catch(err=>{

    })
  },[])

  function onSubmit(data, ev){
    ev.preventDefault()
  
   
    createTenanatRoleAccessRequest(data)
    .then(res=>{
      setResponse({msg:"Submitted Successfully", isError:false})
      reset(resetObj)
      ev.target.reset()
     
    })
    .catch(err=>{
      setResponse({msg:"Error:" + err.response.data.error, isError:true})
    })
    
  }

  const errorRequiredField= (message="This field is required") => {
    return(<small className="text-danger">{message}</small>)
  } 

  return (
    <>
      <div className="center-block" style={{textAlign:'center'}}>
        <h4>Access Request Form</h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
               User Id
              </label>
              <input
                className="fs-14"
                type="text"
                autoComplete="off"
                {...register('userId',{required:true})}
                placeholder="Enter User Id"
                pattern={NON_EMPTY_STRING_PATTERN}
                title="Please enter a non blank string (whitespaces in the end or beginning are not allowed"
                maxLength="50"
                control={control}
              />
              {errors.userId && errorRequiredField()}
            </fieldset>
          </div>
          
        </div>
        <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                Tenant Id
              </label>
              <select
                name="tenantId"
                className="fs-14"
                refs={register}
                onChange={handleTenantChange}
                // {...register('tenantId',{required:true})}
                >
                <option value="">Select a Tenant</option>
                {tenantList.map((tenant) => (
                  <option value={JSON.stringify(tenant)} key={tenant.id}>
                    {tenant.id}
                  </option>
                ))}
              </select>
              {!!errors.tenantId && errorRequiredField()}
            </fieldset>
          </div>
         
        </div>
        <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14" htmlFor="AdSlotId">
                Role Name
              </label>
              <select
                name="roleId"
                disabled={typeof watch("tenantId") === "undefined" || watch("tenantId") === ""}
                className="fs-14"
               
                {...register('roleId',{required:true})}
                >
                <option value="">Select a Role</option>
                {roles.map((role) => (
                  <option value={role} key={role}>
                    {role}
                  </option>
                ))}
              </select>
              {!!errors.roleId && errorRequiredField()}
            </fieldset>
          </div>

        </div>
        
        <div className="row m-0 mt-30 mr-20 vertical-text-center">
          <div className="col-md-9 text-center">
            <div className={response.isError ? "text-danger" : "text-success"}>
              <b>
                <h4>{response.msg}</h4>
              </b>
            </div>
          </div>
          <div className="col-md-6">
            <input className="btn btn-success pull-right" type="submit" />
          </div>
        </div>
      </form>
    </>
  )
}

export default CreateAccessRequest;