import { ajax } from "../utils/ajax";
import { SLOT_PRIORITY_ENDPOINT } from "../Common Components/service.urls";

export const getSlotPrioritiesList = ({clientName, os , slotName}) => {
  clientName = typeof clientName !== 'undefined' ? clientName : ''
  os = typeof os !== 'undefined' ? os : ''
  slotName = typeof slotName !== 'undefined' && slotName !==''? `slotName=${slotName}` : ''
  return ajax({
    method:"get",
    url:`${SLOT_PRIORITY_ENDPOINT}/list?${os}&${slotName}`
  })
}

export const saveSlotPriorities = (slotPriority ,method) => {
  return ajax({
    method : method,
    data : slotPriority ,
    url : `${SLOT_PRIORITY_ENDPOINT}`
  })
}

export const getSlotPrioritiesById = (id) => {
  return ajax({
    method : "get",
    params: {
      id:id
    },
    url : `${SLOT_PRIORITY_ENDPOINT}`
  })
}

export const deleteSlotPriorityById = (slotName) => {

  return ajax({
    method:"delete",
    url:`${SLOT_PRIORITY_ENDPOINT}/${slotName}`
  })
}

export const getSLotPrioritiesAuditsById = (id) => {
  return ajax({
    method : "get",
    params :{
      slotSourcePriorityId: id
    },
    url : `${SLOT_PRIORITY_ENDPOINT}/audits`

  })
}