import { waitForDomChange } from "@testing-library/react";
import React from "react";
import CreatableForm from "../../Common Components/CreatableForm";
import { AD_CONDITION_PATTERN } from "../../constants/RegularExpressions";

function BannerMetaForm(props) {
  const { register, control, watch, errorRequiredField, errors } = props;

  const isPoddedAttributesEnabled = () => {
    return watch("slotAdType") === "AUDIO" || watch("slotAdType") === "VIDEO";
  };

  return (
    <>
      <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 required-field" htmlFor="adUnitIds">
              Ad Unit Ids
            </label>
            <CreatableForm
              control={control}
              register={register}
              name="adUnitIds"
              placeholder="Ad Unit Ids"
              required={true}
            />
            {errors.adUnitIds && errorRequiredField()}
          </fieldset>
        </div>
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14" htmlFor="AdSlotId">
              Ad Templates
            </label>
            <CreatableForm
              control={control}
              register={register}
              name="adTemplates"
              placeholder="Ad Template Ids"
              required={false}
            />
            {!!errors.adTemplates && errorRequiredField()}
          </fieldset>
        </div>
      </div>
      <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14 required-field" htmlFor="AdSlotId">
              Refresh Interval
            </label>
            <input
              className="fs-14"
              type="text"
              autoComplete="off"
              {...register("refreshInterval", { required: true })}
              placeholder="Enter Refresh Interval"
              pattern="[-]{0,1}[0-9]{1,50}"
              title="Allowed values are numbers"
              maxLength="50"
            />
            {errors.refreshInterval && errorRequiredField()}
          </fieldset>
        </div>
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14" htmlFor="AdSlotId">
              Ad Size
            </label>
            <select
              className="fs-14"
              {...register("adSize", { required: false })}
            >
              <option value="small">Small</option>
              <option value="large">Large</option>
            </select>
            {/* {!!errors.adState && errorRequiredField()} */}
          </fieldset>
        </div>
      </div>
      <div className="row m-0 mt-30 mr-20">
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14" htmlFor="imprDelay">
              Impression Delay
            </label>
            <input
              className="fs-14"
              type="number"
              autoComplete="off"
              {...register("imprDelay", { required: false })}
              placeholder="Enter Impression Delay"
              pattern="[0-9]{1,50}"
              title="Allowed values are numbers"
              maxLength="7"
              min={0}
            />
          </fieldset>
        </div>
        <div className="col-md-6">
          <fieldset className="pl-20">
            <label className="fs-14" htmlFor="slotAdType">
              Slot Ad Type
            </label>
            <select
              className="fs-14"
              {...register("slotAdType", {
                required: false,
                validate: (value) => value !== "Select a Slot Ad Type",
              })}
            >
              <option value="">Select a Slot Ad Type</option>
              <option value="BANNER">BANNER</option>
              <option value="INTERSTITIAL">INTERSTITIAL</option>
              <option value="AUDIO">AUDIO</option>
              <option value="VIDEO">VIDEO</option>
              <option value="APP_OPEN">APP_OPEN</option>
            </select>
            {/* {!!errors.slotAdType && errorRequiredField()} */}
          </fieldset>
        </div>
      </div>

      {isPoddedAttributesEnabled() && (
        <>
          <div className="row m-0 mt-30 mr-20">
            <div className="col-md-6">
              <fieldset className="pl-20">
                <label
                  className="fs-14 "
                  htmlFor="maxPodDuration"
                >
                  Maximum Pod Duration
                </label>
                <input
                  className="fs-14"
                  name="maxPodDuration"
                  type="text"
                  autoComplete="off"
                  {...register("maxPodDuration", { required: false })}
                  placeholder="Enter maximum pod duration (in seconds)"
                  pattern="[0-9]{1,9}"
                  title="Allowed values are numbers"
                  maxLength="9"
                  min={0}
                />
                {errors.maxPodDuration }
              </fieldset>
            </div>
            <div className="col-md-6">
              <fieldset className="pl-20">
                <label className="fs-14" htmlFor="maxPodAdTime">
                  Maximum Pod Ad Time
                </label>
                <input
                  className="fs-14"
                  name="maxPodAdTime"
                  type="text"
                  autoComplete="off"
                  {...register("maxPodAdTime", { required: false })}
                  placeholder="Enter maximum pod ad time (in seconds)"
                  pattern="[0-9]{1,9}"
                  title="Allowed values are numbers"
                  maxLength="9"
                  min={0}
                />
                {errors.maxPodAdTime}
              </fieldset>
            </div>
          </div>
          <div className="row m-0 mt-30 mr-20">
          <div className="col-md-6">
            <fieldset className="pl-20">
              <label className="fs-14 " htmlFor="isSkipAllAd">
                Skip All Ad
              </label>
              <select
                name="isSkipAllAd"
                className="fs-14"
                {...register('isSkipAllAd',{required:false})}
                >
                <option value="">Select Condition</option>
                <option value={String(false)}>False</option>
                <option value={String(true)}>True</option>
              </select>
              {errors.isSkipAllAd }
            </fieldset>
          </div>
            <div className="col-md-6">
              <fieldset className="pl-20">
                <label className="fs-14 " htmlFor="minAdDuration">
                  Min Ad Duration
                </label>
                <input
                  className="fs-14"
                  name="minAdDuration"
                  type="text"
                  defaultValue=""
                  autoComplete="off"
                  {...register("minAdDuration", { required: false })}
                  placeholder="Enter minimum ad duration time (in seconds)"
                  pattern="[0-9]{1,9}"
                  title="Allowed values are numbers"
                  maxLength="9"
                  min={0}
                />
                {errors.minAdDuration}
              </fieldset>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default BannerMetaForm;
