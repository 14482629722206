import React, { useEffect, useState } from 'react'
import { getAccountById, getAccountsByState,getAccounts, toggleAccountState, deleteAccountById, getAccountAuditsById } from '../NewSlotConfig/airtelHeaderBidding.service'; 
import Modal from '../Common Components/Modal';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom';
import AuditGroup from '../Audits/AuditGroup';


function ViewAccount() {

  const [publisherList, setPublisherList] = useState([]);
  const [pubIds, setPubIds] = useState([]);
  const [pubId, setPubId] = useState("");
  const [pubStatus, setPubStatus] = useState("")
  const [toggle, setToggle] = useState(true);
  const [response, setResponse] = useState({ msg: "", isError: false });

  const [modal, setModal] = useState({
    isOpen: false,
    content: null,
    title: '',
    didMount: null,
    flatten: false
  })

  useEffect(() => {

    getAccounts()
      .then(res => {
        setPublisherList(res.data);
        setResponse({ isError: false, msg: "" })
        const uniquePubIds = [];
        res.data.map(publisher => {
          if (uniquePubIds.indexOf(publisher.id) === -1) {
            uniquePubIds.push(publisher.id)
          }
          return 0
        })
        setPubIds(uniquePubIds);
      })
      .catch(err => {
        setPublisherList([])
        let msg = err && err.response && err.response.data ? err.response.data.error : "Error Fetching Publisher Details";
        setResponse({ isError: true, msg: msg })
      })


  }, [toggle]);

  function handleChange(func, value) {
    func(value);

    if(func==setPubId){

    setPubId(value);
    getAccountById(value)
    .then(res => {
      const pubList = [];
      pubList.push(res.data);
      setPublisherList(pubList);
      setResponse({ isError: false, msg: "" })
      const uniquepubList = [];
      uniquepubList.push(res.data.id);
      setPubIds(uniquepubList);
    })
    .catch(err => {
      setPublisherList([])
      let msg = err && err.response && err.response.data ? err.response.data.error : "Error Fetching Publisher Details";
      setResponse({ isError: true, msg: msg })
    })
  }

  if(func==setPubStatus){
    
    setPubStatus(value);

    getAccountsByState(value)
    .then(res => {
      setPublisherList(res.data);
      setResponse({ isError: false, msg: "" })
      const uniquePubIds = [];
      res.data.map(publisher => {
        if (uniquePubIds.indexOf(publisher.id) === -1) {
          uniquePubIds.push(publisher.id)
        }
        return 0
      })
      setPubIds(uniquePubIds);
    })
    .catch(err => {
      setPublisherList([])
      let msg = err && err.response && err.response.data ? err.response.data.error : "Error Fetching Publisher Details";
      setResponse({ isError: true, msg: msg })
    })
  }
  }

  const cellStyle = {
    maxWidth: "280px",
    lineBreak: "anywhere",
  };

  const nameStyle = {
    margin: "0",
    fontFamily: "Tondo, Helvetica Neue, Helvetica, Arial, sans-serif",
  };

  function toggleModal(title = '', content = '', callback, flatten = false) {
    setModal({
      isOpen: !modal.isOpen,
      title: title,
      content: content,
      flatten,
      didMount: callback
    })
  }

  function toggleState(id) {
    toggleAccountState(id)
      .then(res => {
        setToggle(!toggle)
      })
      .catch(err => {
        let msg = err && err.response && err.response.data ? err.response.data.error : "Unable to toggle status";
        setResponse({ msg: msg, isError: true })
      })

  }

  function initDeleteConfig(id) {
    const confirmation = window.confirm('Are you sure you want to delete?');

    if (confirmation) {
      deleteAccountById(id)
        .then(response => {
          let existingPublisher = publisherList.filter(publisher => (publisher.id !== id));
          setPublisherList(existingPublisher)
        })
        .catch(err => {
          let msg = err && err.response && err.response.data ? err.response.data.error : "Unable to Delete";
          setResponse({ msg: msg, isError: true })

        })
    }
  }


  function renderPublisherRow(publisher, index) {
    return (
      <div className={`divTableRow`} key={publisher.id + index}>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{publisher.id}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{publisher.status.toUpperCase()}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <span
            className="text-info underline cursor-pointer"
            onClick={() => toggleModal('Publisher Configuration', publisher, null, true)}>View</span>
          &emsp;
          <Link to={{ pathname: '/editAccount/' + publisher.id }} className="text-warning underline">Edit</Link>
          &emsp;
          <span
            className="text-info underline cursor-pointer"
            onClick={() => toggleState(publisher.id)}
          >Toggle</span>
          &emsp;
          <span
            className="text-warning underline cursor-pointer"
            onClick={() => toggleModal("Publisher Audits",
              <AuditGroup id={publisher.id} getAudits={getAccountAuditsById} />
            )}
          >Audits</span>
          &emsp;
          {/* <span
            className="text-danger underline cursor-pointer"
            onClick={() => initDeleteConfig(publisher.id)}
          >Delete</span> */}
          &emsp;
          <Link to={{ pathname: '/editAccount/', state: { publisher: publisher } }} className="text-info underline">
            Clone
          </Link>
          &emsp;
        </div>
      </div>
    );
  }

  return (
    <>
      {modal.isOpen &&
        <Modal
          flatten={modal.flatten}
          title={modal.title}
          btnHandler={toggleModal}
          closeTrigger={toggleModal}
          content={modal.content}
          didMount={modal.didMount}
        >
        </Modal>}
      <div>
        <div className="row m-0 mt-20 mr-20">
            <div className="col-md-6">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray">Search Publisher Id</label>
                <Typeahead
                  id="campaign-search"
                  onChange={e => handleChange(setPubId,e[0])}
                  options={pubIds}
                />
              </fieldset>
            </div>
            <div className="col-md-6">
              <fieldset className="pl-20">
                <label className="fs-14 color-dark-gray">Publisher Status</label>
                <form>
                  <select
                    className="fs-16"
                    onChange={ev => handleChange(setPubStatus, ev.target.value)}
                  >
                    <option value="">All</option>
                    <option value={`pubStatus=active`}>Active</option>
                    <option value={`pubStatus=inactive`}>InActive</option>
                  </select>
                </form>
              </fieldset>
            </div>
          </div>
          <div className="row  mt-20">
          </div>
        </div>
        <div className="m-0 mt-30 mr-20">
          <div className="row">
            <div className="col-sm-12">
            </div>
          </div>
        </div>
      <div className="row m-0 mt-0 mr-20">
        <div className="row m-0 mt-0 mb-20">
          <div className="col-md-12">
            <div className="divTable divTableHover mt-20">
              <div className="divTableHeading">
                <div className="divTableRow">
                  <div className="divTableHead">Publisher Id</div>
                  <div className="divTableHead">Publisher Status</div>
                  <div className="divTableHead">Actions</div>
                </div>
              </div>
              <div className="divTableBody">
                {publisherList && publisherList.map((publisher, index) => renderPublisherRow(publisher, index))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 mt-30 mr-20 vertical-text-center">
        <div className="col-md-9 text-center">
          <div className={response.isError ? "text-danger" : "text-success"}>
            <b>
              <h4>{response.msg}</h4>
            </b>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewAccount;
