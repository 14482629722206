import React, { useState, useEffect } from 'react'
import Modal from '../Common Components/Modal';

import { getFileStatusList, startFileProcessing } from './file.processing.service';
import { FileStatusList } from '../constants/AdConstants';
import { getFormattedDateFromEpoch } from '../utils/adtech.utils';

function ViewFileProcessingStatus() {
  const [fileList, setFileList] = useState([]);
  const [response, setResponse] = useState({ msg: "", isError: false });

  const [fileStatus, setFileStatus] = useState("")
  const [service, setService] = useState("Personalization")

  const statusDotStyleMap ={
    "TO_BE_PROCESSED": 'bg-gray',
    "IN_PROGRESS": 'bg-yellow',
    "ERROR": 'bg-red',
    "COMPLETED": 'bg-bright-green',

  }


  function handleChange(func, value) {
    func(value);
  }

  const [modal, setModal] = useState({
    isOpen: false,
    content: null,
    title: '',
    didMount: null,
    flatten: false
  })


  const cellStyle = {
    maxWidth: "280px",
    lineBreak: "anywhere",
  };

  const nameStyle = {
    margin: "0",
    fontFamily: "Tondo, Helvetica Neue, Helvetica, Arial, sans-serif",
  };

  useEffect(() => {
    
    // if(typeof fileStatus ==="undefined" || fileStatus === ""){
    //   setFileStatus(FileStatusList[0]);
    //   return;
    // }
    
      
      getFileStatusList(fileStatus, service)
      .then(res => {
        setResponse({ msg: "", isError: false })
        setFileList(res.data.files);
      })
      .catch(err => {
        let msg = err && err.response && err.response.data && err.response.data.error ? err.response.data.error.message : "Error getting File Status"
        
        setResponse({ msg: msg, isError: true })
        setFileList([])
      })
  
    return () => {

    }
  }, [fileStatus, service]);

  function toggleModal(title = '', content = '', callback, flatten = false) {
    setModal({
      isOpen: !modal.isOpen,
      title: title,
      content: content,
      flatten,
      didMount: callback
    })
  }


  function startProcessing(){
    startFileProcessing()
  }


  function renderFileProcessingStatusRow(content, index) {
    return (
      <div className={`divTableRow`} key={content.name + index}>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{content.name}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
        <span className={`dot ${statusDotStyleMap[content.status]}`}></span> <pre style={{nameStyle, display:"inline"}}>{content.status}</pre>
        </div>
        <div className="divTableCell" style={cellStyle}>
          <pre style={nameStyle}>{getFormattedDateFromEpoch(content.lastModifiedDate)}</pre>
        </div>
      </div>
    );
  }

  return (
    <>
      {modal.isOpen &&
        <Modal
          flatten={modal.flatten}
          title={modal.title}
          btnHandler={toggleModal}
          closeTrigger={toggleModal}
          content={modal.content}
          didMount={modal.didMount}
        >
        </Modal>}

      <div className="row m-0 mt-0 mr-20">
        <div className="row">
         
         
          <div className="col-sm-3">
            <fieldset className="pl-20">
              <label className="fs-14 color-dark-gray">File Status</label>
              <form>
                <select
                  className="fs-16"
                  onChange={ev => handleChange(setFileStatus, ev.target.value)}
                >
                   <option value={""} key={"All"}>All</option>
                  {FileStatusList.map((status) => (
                  <option value={status} key={status}>
                    {status}
                  </option>
                ))}
                </select>
              </form>
            </fieldset>
          </div>
          <div className="col-sm-3">
            <fieldset className="pl-20">
              <label className="fs-14 color-dark-gray">Service</label>
              <form>
                <select
                  className="fs-16"
                  onChange={ev => handleChange(setService, ev.target.value)}
                >
                   <option value={"Personalization"} key={"personalization"}>Personalization</option>
                  
                </select>
              </form>
            </fieldset>
          </div>
        </div>
        <div className="row m-0 mt-20">
          <div className="col-md-12">
            <h5 className="h5 text-center" style={{ "textAlign": "center" }}>File Processing Status</h5>
            <div className="divTable divTableHover mt-20">
              <div className="divTableHeading">
                <div className="divTableRow">
                  <div className="divTableHead">File Name</div>
                  <div className="divTableHead">Processing Status</div>
                  <div className="divTableHead">Last Modified Date</div>
                
                </div>
              </div>
              <div className="divTableBody">
                {fileList &&
                  fileList.map((file, index) => renderFileProcessingStatusRow(file, index))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
      {fileStatus==="TO_BE_PROCESSED" && typeof fileList !== "undefined" &&fileList.length>0 ? 
      <div className='row mt-30 ml-10'>
        <div className="col-md-3">
          <button onClick={()=> startProcessing()} className="btn btn-sm btn-success">Start Processing</button>
        </div>
      </div>
      :<></>
      }
      </>
      <div className="row m-0 mt-30 mr-20 vertical-text-center">
        <div className="col-md-9 text-center">
          <div className={response.isError ? "text-danger" : "text-success"}>
            <b>
              <h4>{response.msg}</h4>
            </b>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewFileProcessingStatus
